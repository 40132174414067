import { useAtom } from "jotai";
import { deviceTypeAtom } from "../../../atoms/screenSize";

export default function Header({ name }: { name: string }) {
  const [device] = useAtom(deviceTypeAtom);
  return (
    <div className="flex flex-1 m-[12px] rounded-[8px] bg-purple-50">
      <div
        style={{
          background:
            "linear-gradient(150deg, rgba(211, 195, 228, 0.0691875) 70%, rgba(83, 20, 149, 0.27) 100%)",
        }}
        className="rounded-[8px] flex flex-1"
      >
        <div className="flex min-h-full pt-[32px] pl-[32px] pb-[16px] rounded-[8px] flex-col justify-center">
          <p className="font-pn text-xl text-vera-black">Hello, {name}!</p>
          <div className="mb-[8px]" />
          <p className="font-pnb text-h3-d text-vera-black">
            Welcome to VeraPro!
          </p>
          {device === "mobile" && (
            <>
              <div className="mb-[16px]" />
              <div className="border-[1px] border-purple-500 mr-[32px] bg-white py-[10px] rounded-[8px] w-full">
                <p className="font-pnb text-m text-purple-500 text-center">
                  Play Music
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
