import React, { ReactElement, ReactNode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider, useAtom } from "jotai";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginAndRegister from "./pages/Login/loginAndRegister";
import AfterLogin from "./pages/Login/AfterLogin";
import LetsGetStart from "./pages/Onboarding/LetsGetStarted";
import MoreDetail from "./pages/Onboarding/MoreDetails";
import AlmostThere from "./pages/Onboarding/AlmostThere";
import HealthCareType from "./pages/Onboarding/HealthCareType";
import Genres from "./pages/Onboarding/Genres";
import Artists from "./pages/Onboarding/Artists";
import Dashboard from "./pages/Dashboard/Dashboard";
import { deviceTypeAtom, dimensionAtom } from "./atoms/screenSize";
import { getDeviceType, getWindowDimensions } from "./utils/dimension";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Success from "./pages/Onboarding/Success";
// import { IntercomProvider } from "react-use-intercom";
import { REACT_APP_INTERCOM_APP_ID } from "./env";
import Listener from "./pages/ListenerList/Listener";

interface RequireType {
  children: ReactNode | ReactElement;
}

const ScreenSizeWrapper = ({ children }: RequireType): ReactElement | null => {
  const [, setDeviceType] = useAtom(deviceTypeAtom);
  const [, setDimension] = useAtom(dimensionAtom);

  useEffect(() => {
    function handleResize() {
      setDeviceType(getDeviceType());
      setDimension(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <div>{children}</div>;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const intercomAppId = REACT_APP_INTERCOM_APP_ID;

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider>
        <ScreenSizeWrapper>
          {/* <IntercomProvider appId={`${intercomAppId}`}> */}
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<LoginAndRegister />}></Route>
                <Route path="/afterLogin" element={<AfterLogin />}></Route>
                <Route path="/LetsGetStart" element={<LetsGetStart />}></Route>
                <Route path="/MoreDetails" element={<MoreDetail />}></Route>
                <Route path="/AlmostThere" element={<AlmostThere />}></Route>
                <Route
                  path="/HealthCareType"
                  element={<HealthCareType />}
                ></Route>
                <Route path="/Genres" element={<Genres />}></Route>
                <Route path="/Artists" element={<Artists />}></Route>
                <Route path="/Dashboard/*" element={<Dashboard />}></Route>
                <Route path="/Success" element={<Success />}></Route>
                <Route path="/ListenerList" element={<Listener />}></Route>
              </Routes>
            </BrowserRouter>
          {/* </IntercomProvider> */}
        </ScreenSizeWrapper>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
