import Button from "../../../components/Button";
import CardList from "../../../components/CardList";
import InputForm from "../../../components/inputForm";
import useIsVeraPro from "../../../hooks/useIsVeraPro";
import DropDown from "../../../components/DropDown";
import GenresList from "../../../components/GenresList";
import { ReactComponent as CardPlus } from "../../../svgs/CardPlus.svg";
import { ReactComponent as Left } from "../../../svgs/Left.svg";
import { ReactComponent as Sort } from "../../../svgs/Sort.svg";
import { ReactComponent as Undo } from "../../../svgs/Undo.svg";
import react, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAtom } from "jotai";
import { deviceTypeAtom, dimensionAtom } from "../../../atoms/screenSize";
import { accessTokenAtom, userAtom } from "../../../atoms/auth";
import { get, post, _delete } from "../../../utils/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Suggestion from "../../../components/Suggestion";

type PreferenceSelectorType = "Songs" | "Artists" | "Genres";

const PreferenceSelector = ({
  type,
  selected,
  setSelected,
}: {
  type: PreferenceSelectorType;
  selected: boolean;
  setSelected: Dispatch<SetStateAction<PreferenceSelectorType>>;
}) => {
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";
  return (
    <>
      <button
        onClick={() => {
          setSelected(type);
        }}
      >
        {selected ? (
          <div className="h-full border-b-[2px] border-b-purple-500">
            <p
              className={`font-pnb text-purple-500 ${
                isPhone ? "text-p-m" : "text-cta-d"
              } `}
            >
              {type}
            </p>
          </div>
        ) : (
          <div className="h-full">
            <p className={`font-pn ${isPhone ? "text-p-m" : "text-cta-d"}`}>
              {type}
            </p>
          </div>
        )}
      </button>
    </>
  );
};

const ListElement = ({
  songName,
  artistName,
  id,
  favouriteOrRemoved,
}: {
  songName?: string;
  artistName: string;
  id: string;
  favouriteOrRemoved: FavouriteOrRemovedType;
}) => {
  const [user] = useAtom(userAtom);
  const queryClient = useQueryClient();

  const delteFn = async (
    body: BodyInit,
    trackOrArtist: "track" | "artist",
    likeORRemove: "like" | "remove"
  ) => {
    return await _delete(
      "/tasteprofile/" +
        user?.id +
        "/" +
        trackOrArtist +
        "/" +
        likeORRemove +
        "?" +
        trackOrArtist +
        "_id=" +
        id,
      new Headers(),
      body,
      false
    );
  };

  const postFn = async (
    body: BodyInit,
    trackOrArtist: "track" | "artist",
    likeORRemove: "like" | "remove"
  ) => {
    return await post(
      "/tasteprofile/" +
        user?.id +
        "/" +
        trackOrArtist +
        "/" +
        likeORRemove +
        "?" +
        trackOrArtist +
        "_id=" +
        id,
      new Headers(),
      body,
      false
    );
  };

  const deleteMutation = useMutation({
    mutationFn: (e: {
      body: BodyInit;
      trackOrArtist: "track" | "artist";
      likeOrRemove: "like" | "remove";
    }) => delteFn(e.body, e.trackOrArtist, e.likeOrRemove),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getSongs"] });
      queryClient.invalidateQueries({ queryKey: ["getArtists"] });
      queryClient.invalidateQueries({ queryKey: ["getStations"] });
    },
  });

  // const postMutation = useMutation({
  //   mutationFn: (e: {
  //     body: BodyInit;
  //     trackOrArtist: "track" | "artist";
  //     likeOrRemove: "like" | "remove";
  //   }) => postFn(e.body, e.trackOrArtist, e.likeOrRemove),
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({ queryKey: ["getSongs"] });
  //     queryClient.invalidateQueries({ queryKey: ["getArtists"] });
  //   },
  // });

  return (
    <>
      <div className="h-[60px] pb-[10px] mb-[10px] flex flex-row items-center justify-between border-b-[1px] border-b-neutral-400 w-full">
        <div className="flex flex-row items-center">
          <div className="w-[48px] h-[48px] rounded-[4px] bg-neutral-500" />
          <div className="mr-[10px]" />
          <div className="flex flex-col">
            <p className="font-pnb text-p-m">
              {songName ? songName : artistName}
            </p>
            {songName && <p className="font-pn text-p-xs">{artistName}</p>}
          </div>
        </div>
        <button
          className="flex flex-row items-center"
          onClick={() => {
            if (favouriteOrRemoved === "Favourite") {
              deleteMutation.mutate({
                body: {} as BodyInit,
                trackOrArtist: songName ? "track" : "artist",
                likeOrRemove: "like",
              });
            } else {
              deleteMutation.mutate({
                body: {} as BodyInit,
                trackOrArtist: songName ? "track" : "artist",
                likeOrRemove: "remove",
              });
            }
          }}
        >
          {favouriteOrRemoved === "Favourite" ? (
            <div className="flex flex-row items-center">
              <Undo />
              <div className="mr-[8px]" />
              <p className="font-pn text-p-m">Unfavourite</p>
            </div>
          ) : (
            <div className="flex flex-row items-center">
              <p className="font-pn text-p-l text-purple-500">+ Add Back</p>
            </div>
          )}
        </button>
      </div>
    </>
  );
};

type FavouriteOrRemovedType = "Favourite" | "Removed" | "Suggest";

const FavouriteOrRemovedSection = ({
  favouriteOrRemoved,
  setFavouriteOrRemoved,
  suggestions,
  searchText,
  setSearchText,
}: {
  favouriteOrRemoved: FavouriteOrRemovedType;
  setFavouriteOrRemoved: Dispatch<SetStateAction<FavouriteOrRemovedType>>;
  suggestions: boolean;
  searchText: string;
  setSearchText: (e: string) => void;
}) => {
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";
  const [accessToken] = useAtom(accessTokenAtom);

  const getArtist = async () => {
    const headers = new Headers();
    headers.append("Authorization", "Bearer " + accessToken);
    headers.append("Content-Type", "application/json");
    const response = await get(
      `/artist/suggest?tasteprofile_id=${2}&num_artists=30`,
      headers,
      false
    );
    return response;
  };

  const { data, isLoading, isError } = useQuery({
    queryFn: getArtist,
    queryKey: ["getSuggestedArtists"],
  });

  if (isLoading || isError) return <></>;
  return (
    <div className="flex flex-col">
      {isPhone ? (
        <DropDown
          content={[{ name: "Favourite" }, { name: "Removed" }]}
          placeHolder={""}
          setValue={setFavouriteOrRemoved}
        />
      ) : (
        <div className="flex flex-row items-center">
          <Button
            isReady={favouriteOrRemoved === "Favourite"}
            type={favouriteOrRemoved === "Favourite" ? "filled" : "outline"}
            dark
            text={"Favourites"}
            onPressFn={() => {
              setFavouriteOrRemoved("Favourite");
            }}
          />
          <div className="mr-[20px]" />
          <Button
            isReady={favouriteOrRemoved === "Removed"}
            type={favouriteOrRemoved === "Removed" ? "filled" : "outline"}
            text={"Removed"}
            dark
            onPressFn={() => {
              setFavouriteOrRemoved("Removed");
            }}
          />
          {suggestions && (
            <>
              <div className="mr-[20px]" />
              <Button
                isReady={favouriteOrRemoved === "Suggest"}
                type={favouriteOrRemoved === "Suggest" ? "filled" : "outline"}
                text={"Suggestions"}
                dark
                onPressFn={() => {
                  setFavouriteOrRemoved("Suggest");
                }}
              />
            </>
          )}
          <div className="mr-[40px]" />
          <div className="flex flex-1 h-full w-full">
            <div className="absolute w-full">
              <DropDown
                content={
                  favouriteOrRemoved === "Suggest"
                    ? data.filter((e: any) =>
                        e.name.toLowerCase().includes(searchText.toLowerCase())
                      )
                    : []
                }
                setValue={(e: string) => {
                  setSearchText(e);
                }}
                placeHolder={"Select by name"}
                isSearch={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

type TasteProfileType = {
  liked: { artist: string; id: string; title: string }[];
  removed: { artist: string; id: string; title: string }[];
};

export default function MusicPreference() {
  const [preferenceSelector, setPreferenceSelector] =
    useState<PreferenceSelectorType>("Songs");
  const [favouriteOrRemoved, setFavouriteOrRemoved] =
    useState<FavouriteOrRemovedType>("Favourite");
  const [searchText, setSearchText] = useState("");
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";
  const [dimension] = useAtom(dimensionAtom);
  const [user] = useAtom(userAtom);

  useEffect(() => {
    setFavouriteOrRemoved("Favourite");
  }, [preferenceSelector]);

  const getSongs = async () => {
    if (user) {
      const headers = new Headers();
      const response = await get(
        "/tasteprofile/" + "2" + "/tracks",
        headers,
        false
      );
      return response as TasteProfileType;
    }
    return [];
  };

  const getArtists = async () => {
    if (user) {
      const headers = new Headers();
      const response = await get(
        "/tasteprofile/" + "2" + "/artists",
        headers,
        false
      );
      return response as TasteProfileType;
    }
    return [];
  };

  const { data, isLoading } = useQuery({
    queryKey: ["getSongs"],
    queryFn: getSongs,
  });

  const artistsQuery = useQuery({
    queryKey: ["getArtists"],
    queryFn: getArtists,
  });

  console.log(artistsQuery.data);

  if (isLoading || artistsQuery.isLoading) return <></>;

  return (
    <>
      <div
        className={`flex flex-1 flex-col bg-white rounded-[8px] px-[24px] py-[44px] ${
          isPhone && "mx-[16px]"
        }`}
        style={{
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
        }}
      >
        {isPhone && (
          <div className="flex flex-row items-center mb-[20px]">
            <Left />
            <div className="mr-[10px]" />
            <p className="font-pnb text-h5-m">Music Preferences</p>
          </div>
        )}
        <div className="flex flex-row items-center h-[40px] border-b-[1px] border-b-neutral-500">
          <PreferenceSelector
            type="Songs"
            selected={preferenceSelector === "Songs"}
            setSelected={setPreferenceSelector}
          />
          <div className="mr-[20px]" />
          <PreferenceSelector
            type="Artists"
            selected={preferenceSelector === "Artists"}
            setSelected={setPreferenceSelector}
          />
          <div className="mr-[20px]" />
          <PreferenceSelector
            type="Genres"
            selected={preferenceSelector === "Genres"}
            setSelected={setPreferenceSelector}
          />
        </div>
        <div className="mb-[32px]" />
        {!isPhone && (
          <p className="font-pn text-h2-d">{`Manage ${preferenceSelector}`}</p>
        )}
        <div className={`${isPhone ? "" : "mb-[32px]"} `} />
        {(preferenceSelector === "Artists" ||
          preferenceSelector === "Songs") && (
          <>
            <FavouriteOrRemovedSection
              favouriteOrRemoved={favouriteOrRemoved}
              setFavouriteOrRemoved={setFavouriteOrRemoved}
              suggestions={preferenceSelector === "Artists"}
              searchText={searchText}
              setSearchText={setSearchText}
            />
            <div className={`${isPhone ? "mb-[16px]" : "mb-[32px]"} `} />
            <div className="w-full bg-purple-50 pl-[60px] pr-[10px] py-[8px] h-[50px] flex flex-row justify-between items-center">
              <div className="flex flex-row items-center">
                <p className="font-pn text-p-s text-vera-black">
                  {preferenceSelector}
                </p>
                <Sort />
              </div>
              {favouriteOrRemoved === "Favourite" &&
                preferenceSelector === "Artists" && (
                  <button
                    onClick={() => {
                      setFavouriteOrRemoved("Suggest");
                    }}
                    className="flex flex-row items-center bg-white border-[1px] border-neutral-500 px-[10px] rounded-[3px]"
                  >
                    <CardPlus />
                    <div className="mr-[8px]" />
                    <p className="font-pn text-p-m">{`Add ${preferenceSelector}`}</p>
                  </button>
                )}
            </div>
          </>
        )}
        {preferenceSelector === "Genres" && (
          <>
            <p className="font-pnb text-h6-d">Genres selected</p>
            <p className="font-pn text-p-m">
              Click to like a genre. Double-click to remove a genre. Click again
              to undo
            </p>
            <div className="mb-[30px]" />
            <GenresList />
          </>
        )}
        <div className="mb-[20px]" />
        <div
          className="overflow-y-auto"
          style={{
            height: dimension.height - 450,
          }}
        >
          {preferenceSelector === "Songs" && (
            <>
              {favouriteOrRemoved === "Favourite" ? (
                <>
                  {
                    // @ts-ignore
                    data.liked
                      // @ts-ignore
                      ?.filter((e) =>
                        e.title.toLowerCase().includes(searchText.toLowerCase())
                      )
                      // @ts-ignore
                      .map((item, key) => {
                        return (
                          <div key={key} className="w-full">
                            <ListElement
                              songName={
                                item.title?.length > 10
                                  ? item.title.substring(0, 10) + "..."
                                  : item.title
                              }
                              artistName={
                                item?.artists?.length > 10
                                  ? item.artists.substring(0, 10) + "..."
                                  : item.artists
                              }
                              id={item.id}
                              favouriteOrRemoved={favouriteOrRemoved}
                            />
                          </div>
                        );
                      })
                  }
                </>
              ) : (
                <>
                  {
                    // @ts-ignore
                    data?.removed
                      ?.filter((e: any) =>
                        e.title.toLowerCase().includes(searchText.toLowerCase())
                      )
                      // @ts-ignore
                      .map((item, key) => {
                        return (
                          <div key={key} className="w-full">
                            <ListElement
                              songName={
                                item.title?.length > 10
                                  ? item.title.substring(0, 10) + "..."
                                  : item.title
                              }
                              artistName={
                                item?.artists?.length > 10
                                  ? item.artists.substring(0, 10) + "..."
                                  : item.artists
                              }
                              id={item.id}
                              favouriteOrRemoved={favouriteOrRemoved}
                            />
                          </div>
                        );
                      })
                  }
                </>
              )}
            </>
          )}
          {preferenceSelector === "Artists" && (
            <>
              {favouriteOrRemoved === "Suggest" ? (
                <>
                  <Suggestion />
                </>
              ) : favouriteOrRemoved === "Favourite" ? (
                <>
                  {
                    // @ts-ignore
                    artistsQuery.data.liked
                      // @ts-ignore
                      .filter((e) =>
                        e.name.toLowerCase().includes(searchText.toLowerCase())
                      )
                      // @ts-ignore
                      .map((item, key) => {
                        return (
                          <div key={key} className="w-full">
                            <ListElement
                              artistName={
                                item?.name?.length > 10
                                  ? item.name.substring(0, 10) + "..."
                                  : item.name
                              }
                              id={item.id}
                              favouriteOrRemoved={favouriteOrRemoved}
                            />
                          </div>
                        );
                      })
                  }
                </>
              ) : (
                <>
                  {
                    // @ts-ignore
                    artistsQuery.data.liked
                      .filter((e: any) =>
                        e.name.toLowerCase().includes(searchText.toLowerCase())
                      )
                      // @ts-ignore
                      .map((item, key) => {
                        return (
                          <div key={key} className="w-full">
                            <ListElement
                              artistName={
                                item?.name?.length > 10
                                  ? item.name.substring(0, 10) + "..."
                                  : item.name
                              }
                              id={item.id}
                              favouriteOrRemoved={favouriteOrRemoved}
                            />
                          </div>
                        );
                      })
                  }
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
