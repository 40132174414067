import { useAtom } from "jotai";
import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { deviceTypeAtom, dimensionAtom } from "../atoms/screenSize";
import Button from "./Button";

interface RequireType {
  children: ReactNode | ReactElement;
  visible: boolean;
  setVisible: Dispatch<React.SetStateAction<boolean>>;
  disableClose?: boolean;
  disableCloseBottom?: boolean;
  selectListenerMode?: boolean;
  ref?: any;
}

const Modal = ({
  children,
  visible,
  ref,
}: {
  children: ReactNode | ReactElement;
  visible: boolean;
  ref?: any;
}) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [visible]);

  return (
    <div
      className=" bg-background-dark"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: visible ? "block" : "none",
      }}
    >
      <div className="">{children}</div>
    </div>
  );
};

export default function VeraModal({
  children,
  visible,
  setVisible,
  disableClose,
  disableCloseBottom,
  selectListenerMode,
  ref,
}: RequireType) {
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";
  return (
    <>
      <Modal visible={visible} ref={ref}>
        <div
          className="flex flex-1 flex-col items-center justify-center"
          style={{
            paddingLeft: selectListenerMode
              ? "5%"
              : device === "web"
              ? "20%"
              : device === "tablet"
              ? "15%"
              : "5%",
            paddingRight: selectListenerMode
              ? "5%"
              : device === "web"
              ? "20%"
              : device === "tablet"
              ? "15%"
              : "5%",
            paddingTop: selectListenerMode
              ? "5%"
              : device === "web"
              ? "5%"
              : device === "tablet"
              ? "15%"
              : "10%",
            paddingBottom: selectListenerMode
              ? "5%"
              : device === "web"
              ? "0"
              : device === "tablet"
              ? "15%"
              : "0%",
          }}
        >
          <div className="flex flex-col flex-1 bg-white w-full rounded-[12px]">
            {!disableClose ? (
              <button
                className="mr-[50px]"
                onClick={() => {
                  setVisible(false);
                }}
              >
                <div className="flex h-[50px] w-full flex-row items-center justify-end">
                  <span className="font-pn text-p-m ">X</span>
                  <div className="mr-[10px]" />
                  <span className="font-pn text-p-m ">Close</span>
                </div>
              </button>
            ) : null}
            {children}
            <div
              className={`my-[20px] flex flex-row ${isPhone && "self-center"}`}
              style={{ marginLeft: isPhone ? 0 : 42 }}
            >
              {disableClose ||
              disableCloseBottom ||
              selectListenerMode ? null : (
                <Button
                  type={"filled"}
                  isReady
                  text={"Close"}
                  onPressFn={() => {
                    setVisible(false);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
