import { useRef, useState } from "react";

export default function LongPressButton({
  onClick,
  onLongPress,
  children,
  className,
  style,
}: {
  onClick: () => void;
  onLongPress: () => void;
  children: any;
  className: string;
  style: Object;
}) {
  const [isPressing, setIsPressing] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const handlePressStart = () => {
    setIsPressing(true);
    timeoutRef.current = setTimeout(() => {
      onLongPress();
    }, 500);
  };

  const handlePressEnd = () => {
    setIsPressing(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return (
    <button
      onClick={onClick}
      onMouseDown={handlePressStart}
      onTouchStart={handlePressStart}
      onMouseUp={handlePressEnd}
      onMouseLeave={handlePressEnd}
      onTouchEnd={handlePressEnd}
      className={className}
      style={style}
    >
      {children}
    </button>
  );
}
