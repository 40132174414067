import { atom } from "jotai";
import { getDeviceType, getWindowDimensions } from "../utils/dimension";

type dimensionType = {
  height: number;
  width: number;
};

export const deviceTypeAtom = atom<"mobile" | "tablet" | "web">(
  getDeviceType()
);
export const dimensionAtom = atom<dimensionType>(getWindowDimensions());
