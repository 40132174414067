import React from "react";
import { ReactComponent as WatchVideo } from "../svgs/WatchVideo.svg";

const VideoScreen = () => {
  return (
    <>
      <div className="w-[217px] h-full">
        <div className="flex flex-row items-center justify-center w-full h-full">
          <WatchVideo />
          <div className="mr-[8px]" />
          <p className="font-pn text-white text-s">Watch Video</p>
        </div>
      </div>
    </>
  );
};

export default function VideoPill({ onPressFn }: { onPressFn: () => void }) {
  return (
    <>
      <div
        className="w-[217px] h-full flex flex-col mr-[16px] pb-[10px]"
        style={{
          boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="flex flex-[3_3_0%] bg-neutral-500 rounded-t-[8px]">
          <button
            className="h-full w-full flex flex-1 z-10"
            onClick={onPressFn}
          >
            <VideoScreen />
          </button>
        </div>
        <div className="flex flex-[2_2_0%] bg-white flex-col justify-center px-[16px] rounded-b-[8px] whitespace-pre-wrap">
          <p className="font-pnb text-l">How to use Vera</p>
          <p className="font-pn text-s text-neutral-500">
            Lorem ipsum dolor sit amet, consectetur
          </p>
        </div>
      </div>
    </>
  );
}
