import { emailAtom, userTypeAtom } from "../../../atoms/auth";
import { ReactComponent as Account } from "../../../svgs/Account.svg";
import { ReactComponent as Help } from "../../../svgs/Help.svg";
import { ReactComponent as Home } from "../../../svgs/Home.svg";
import { ReactComponent as LearnHub } from "../../../svgs/LearnHub.svg";
import { ReactComponent as MusicStation } from "../../../svgs/MusicStation.svg";
import { ReactComponent as Profile } from "../../../svgs/Profile.svg";
import { ReactComponent as Switch } from "../../../svgs/Switch.svg";
import { ReactComponent as Vera } from "../../../svgs/vera.svg";
import { useAtom } from "jotai";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dimensionAtom } from "../../../atoms/screenSize";
import useIsVeraPro from "../../../hooks/useIsVeraPro";
// import { useIntercom } from "react-use-intercom";

const getMusicStation = () => {
  return { stations: ["Relax", "Reminisce", "Energize"] };
};

const LinearGradientWrapper = ({
  children,
  selected,
  station,
}: {
  children: ReactElement | ReactNode;
  selected: boolean;
  station?: string;
}) => {
  return (
    <>
      {selected ? (
        <div
          className="border-r-[4px] "
          style={{
            borderRightColor:
              station === "relax"
                ? "#00A67C"
                : station === "energize"
                ? "#E9B44C"
                : "#7A23D3",
          }}
        >
          <div
            style={{
              background:
                station === "relax"
                  ? "linear-gradient(90deg, rgba(174, 238, 223, 0) 13.53%, rgba(11, 205, 159, 0.57) 77.93%)"
                  : station === "energize"
                  ? "linear-gradient(90deg, rgba(255, 228, 175, 0.06) 4.03%, rgba(253, 196, 83, 0.62) 80.26%)"
                  : "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(83, 20, 149, 0.27) 84.9%)",
            }}
          >
            {children}
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default function LeftPane({
  setShowListenerModal,
}: {
  setShowListenerModal: (b: boolean) => void;
}) {
  const isVeraPro = useIsVeraPro();
  const [userType, setUserType] = useAtom(userTypeAtom);
  const stations = getMusicStation();
  const navigate = useNavigate();
  const [dimension] = useAtom(dimensionAtom);
  // const intercomContext = useIntercom();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [userEmail] = useAtom(emailAtom);
  const location = useLocation();
  const currentPath = location.pathname;
  const segments = currentPath.split("/");
  const selected = segments[segments.length - 1];

  // useEffect(() => {
  //   if (isChatOpen) {
  //     intercomContext.boot({ email: userEmail });
  //     intercomContext.show();
  //   } else {
  //     intercomContext.hide();
  //   }
  // }, [intercomContext, isChatOpen, userEmail]);

  return (
    <div
      style={{
        height: dimension.height - 100,
        width: "100%",
      }}
      className="flex flex-col justify-between"
    >
      <div>
        <div className=" flex flex-row items-center justify-center">
          <Vera />
          <div className="mr-[5px]" />
          {isVeraPro && (
            <div className="bg-purple-550 rounded-[2px] px-[10px] py-[2px]">
              <p className="font-pn text-xl text-white">Pro</p>
            </div>
          )}
        </div>
        <div className="mb-[46px]" />
        <LinearGradientWrapper selected={selected === "Dashboard"}>
          <button
            onClick={() => {
              navigate("/Dashboard");
            }}
          >
            <div className="ml-[16px] flex flex-row items-center py-[8px]">
              <Home
                style={{
                  color:
                    selected === "Dashboard"
                      ? " text-purple-500"
                      : "text-vera-black",
                }}
              />
              <div className="mr-[10px]" />
              <p
                className={`font-pnb text-p-s ${
                  selected === "Dashboard"
                    ? " text-purple-500"
                    : "text-vera-black"
                } `}
              >
                DASHBOARD
              </p>
            </div>
          </button>
        </LinearGradientWrapper>
        <div className="mb-[16px]" />
        {userType !== "HomeAdmin" && (
          <>
            <div className="mr-[16px] flex flex-row items-center justify-between rounded-[8px] bg-purple-500 p-[13px]">
              <p className="font-pnb text-p-s text-white">
                {userType.toUpperCase()}
              </p>
              <button
                onClick={() => {
                  if (userType === "Caregiver") {
                    setShowListenerModal(true);
                  } else {
                    setUserType("Caregiver");
                  }
                  navigate("/Dashboard");
                }}
              >
                <div className="flex flex-row items-center">
                  <Switch />
                  <div className="mr-[10px]" />
                  <p className="font-pnb text-p-s text-white">SWITCH</p>
                </div>
              </button>
            </div>
            <div className="mb-[16px]" />
          </>
        )}
        {userType === "Listener" ? (
          <>
            <div className="ml-[16px] flex flex-col items-start">
              <div className="flex flex-row items-center justify-center">
                <div className="h-[20px]">
                  <MusicStation />
                </div>
                <div className="mr-[10px]" />
                <div className="pb-[8px]">
                  <p className="font-pnb text-p-s">MUSIC STATION</p>
                </div>
              </div>
              <div className="mr-[10px]" />
              <div className=" flex w-full flex-col ">
                {stations.stations.map((station, index) => {
                  return (
                    <button
                      className="w-full"
                      key={index}
                      onClick={() => navigate(station)}
                    >
                      <LinearGradientWrapper
                        selected={selected === station}
                        station={station.toLowerCase()}
                      >
                        <div className="py-[8px] ">
                          <p
                            className={`font-pnb text-p-s translate-x-[30px] text-start ${
                              selected === station
                                ? station.toLowerCase() === "relax"
                                  ? "text-vera-green"
                                  : station.toLowerCase() === "energize"
                                  ? "text-vera-black"
                                  : " text-purple-500"
                                : "text-vera-black"
                            } `}
                          >
                            {station}
                          </p>
                        </div>
                      </LinearGradientWrapper>
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="mb-[16px]" />
            <div className="ml-[16px] flex flex-col items-start">
              <div className="flex flex-row items-center ">
                <Profile />
                <div className="mr-[10px]" />
                <p className="font-pnb text-p-s">PROFILE</p>
              </div>
              <div className="flex w-full flex-col">
                <button
                  className="w-full"
                  onClick={() => navigate("/Dashboard/PersonalInformation")}
                >
                  <LinearGradientWrapper
                    selected={selected === "PersonalInformation"}
                  >
                    <div className="py-[8px] ">
                      <p
                        className={`font-pnb text-p-s translate-x-[30px] text-start ${
                          selected === "PersonalInformation"
                            ? " text-purple-500"
                            : "text-vera-black"
                        } `}
                      >
                        {"Personal Information"}
                      </p>
                    </div>
                  </LinearGradientWrapper>
                </button>
                <button
                  className="w-full"
                  onClick={() => navigate("/Dashboard/MusicPreference")}
                >
                  <LinearGradientWrapper
                    selected={selected === "MusicPreference"}
                  >
                    <div className="py-[8px] ">
                      <p
                        className={`font-pnb text-p-s translate-x-[30px] text-start ${
                          selected === "MusicPreference"
                            ? " text-purple-500"
                            : "text-vera-black"
                        } `}
                      >
                        {"Music Preference"}
                      </p>
                    </div>
                  </LinearGradientWrapper>
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mb-[16px]" />
            <div className="ml-[16px] flex flex-col items-start">
              <div className="flex flex-row items-center justify-center">
                <Profile />
                <div className="mr-[10px]" />
                <p className="font-pnb text-p-s">MANAGE</p>
              </div>
              <div className="mb-[8px]" />
              <div className="flex w-full flex-col ">
                {userType === "HomeAdmin" && (
                  <button
                    className="w-full"
                    onClick={() => navigate("/Dashboard/Caregivers")}
                  >
                    <LinearGradientWrapper selected={selected === "Caregivers"}>
                      <div className="py-[8px] ">
                        <p
                          className={`font-pnb text-p-s translate-x-[30px] text-start ${
                            selected === "Caregivers"
                              ? " text-purple-500"
                              : "text-vera-black"
                          } `}
                        >
                          {"Caregivers"}
                        </p>
                      </div>
                    </LinearGradientWrapper>
                  </button>
                )}
                <button
                  className="w-full"
                  onClick={() => navigate("/Dashboard/Listeners")}
                >
                  <LinearGradientWrapper selected={selected === "Listeners"}>
                    <div className="py-[8px] ">
                      <p
                        className={`font-pnb text-p-s translate-x-[30px] text-start ${
                          selected === "Listeners"
                            ? " text-purple-500"
                            : "text-vera-black"
                        } `}
                      >
                        {"Listeners"}
                      </p>
                    </div>
                  </LinearGradientWrapper>
                </button>
              </div>
            </div>
            <div className="mb-[16px]" />
            <button
              className="w-full"
              onClick={() => navigate("/Dashboard/LearnHub")}
            >
              <LinearGradientWrapper selected={selected === "LearnHub"}>
                <div className="ml-[16px] flex flex-row items-center">
                  <div className="flex flex-row items-center justify-center py-[8px]">
                    <LearnHub />
                    <div className="mr-[10px]" />
                    <p
                      className={`font-pnb text-p-s ${
                        selected === "LearnHub"
                          ? " text-purple-500"
                          : "text-vera-black"
                      } `}
                    >
                      Learn Hub
                    </p>
                  </div>
                </div>
              </LinearGradientWrapper>
            </button>
            <div className="mb-[16px]" />
          </>
        )}
        {!(userType === "Listener" && isVeraPro) && (
          <>
            <div className="mb-[16px]" />
            <div className="ml-[16px] flex flex-col items-start">
              <div className="flex flex-row items-center justify-center">
                <Account />
                <div className="mr-[10px]" />
                <p className="font-pnb text-p-s">Account</p>
              </div>
              <div className="mb-[8px]" />
              <div className="flex w-full flex-col">
                <button
                  className="w-full"
                  onClick={() => navigate("/settings")}
                >
                  <div className="py-[8px] ">
                    <p
                      className={`font-pnb text-p-s text-vera-black translate-x-[30px] text-start `}
                    >
                      Settings
                    </p>
                  </div>
                </button>
                <button
                  onClick={() => {
                    navigate("/AfterLogin");
                  }}
                >
                  <div className="py-[8px] ">
                    <p className="font-pnb text-p-s translate-x-[30px] text-start">
                      Logout
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <button
        className="mr-[16px] flex flex-row items-center justify-evenly rounded-[8px] border-[1px] border-neutral-400 bg-purple-50 p-[16px]"
        onClick={() => {
          setIsChatOpen((prevValue) => !prevValue);
        }}
      >
        <Help />
        <div className="flex flex-col">
          <p className="font-pnb text-m">Need a hand?</p>
          <p className="font-pn text-s">speak with us</p>
        </div>
      </button>
    </div>
  );
}
