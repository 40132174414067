import CardList from "../../components/CardList";
import DropDown from "../../components/DropDown";
import VeraModal from "../../components/Modal";
import { ReactComponent as Information } from "../../svgs/Information.svg";
import React, { Dispatch, useEffect, useState } from "react";
import OnboardWrapper from "./components/OnboardWrapper";
import { useAtom } from "jotai";
import { deviceTypeAtom } from "../../atoms/screenSize";
import { useNavigate } from "react-router-dom";

const Content = ({
  setCanProgress,
  setShowCognitoDecline,
}: {
  setCanProgress: Dispatch<React.SetStateAction<boolean>>;
  setShowCognitoDecline: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [gender, setGender] = useState<string>("");
  const [cognitoDecline, setCognitoDecline] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [device] = useAtom(deviceTypeAtom);

  useEffect(() => {
    setCanProgress(gender !== "" && cognitoDecline !== "" && year !== "");
  }, [year, gender, cognitoDecline, setCanProgress]);

  return (
    <>
      <p
        className={`${
          device === "mobile" ? "text-h1-m" : "text-h1-d"
        } font-pnb`}
      >
        Let’s add some more listener details
      </p>
      <div className="mb-[32px]" />
      <p
        className={`${
          device === "mobile" ? "text-h6-m" : "text-h6-d"
        } font-pnb`}
      >
        Gender
      </p>
      <CardList
        type="singleChoice"
        setValue={setGender}
        content={[
          { name: "Male" },
          { name: "Female" },
          { name: "Other" },
          { name: "Prefer not to say" },
        ]}
      />
      <div className="mb-[16px]" />
      <p
        className={`${
          device === "mobile" ? "text-h6-m" : "text-h6-d"
        } font-pnb`}
      >
        Year of birth
      </p>
      <DropDown
        placeHolder={"Select birth year"}
        setValue={setYear}
        content={[]}
        year
      />
      <div className="mb-[32px]" />
      <div className="flex flex-row items-center">
        <p
          className={`${
            device === "mobile" ? "text-h6-m" : "text-h6-d"
          } font-pnb`}
        >
          {"Cognito Decline Level (optional) "}
        </p>
        <div className="mr-[10px]" />
        <button
          onClick={() => {
            setShowCognitoDecline(true);
          }}
        >
          <Information />
        </button>
      </div>
      <CardList
        type="singleChoice"
        setValue={setCognitoDecline}
        content={[{ name: "Mild" }, { name: "Moderate" }, { name: "Advanced" }]}
      />
    </>
  );
};

const CognitoDeclineModal = ({
  showCognitoDecline,
  setShowCognitoDecline,
}: {
  showCognitoDecline: boolean;
  setShowCognitoDecline: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";

  return (
    <VeraModal visible={showCognitoDecline} setVisible={setShowCognitoDecline}>
      <>
        <div className="flex flex-col p-[42px]">
          <p
            className={`font-pnb ${
              isPhone ? "text-p-l text-center" : "text-h4-d"
            }`}
          >
            What is Cognitive Decline
          </p>
          <div className="mb-[12px]" />
          <p className="font-pn text-p-m">
            <p className="font-pnb">Mild</p> - Might still be able to live
            independently. However, they will probably have memory lapses, like
            forgetting familiar words or the location of everyday objects.
          </p>
          <div className="mb-[36px]" />
          <p className="font-pn text-p-m">
            <p className="font-pnb">Moderate</p> - The person will need an
            increasing level of care. In this stage, you might notice that they
            get words mixed up, are often frustrated or angry, or act in
            unexpected ways, like refusing to bathe.
          </p>
          <div className="mb-[36px]" />
          <p className="font-pn text-p-m">
            <p className="font-pnb">Advanced</p> - people progressively lose the
            ability to engage in the world, to hold conversations, and to
            control their muscles.
          </p>
          <div className="mb-[24px]" />
        </div>
      </>
    </VeraModal>
  );
};

export default function MoreDetail() {
  const [canProgress, setCanProgress] = useState(false);
  const [showCognitoDecline, setShowCognitoDecline] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {showCognitoDecline && (
        <CognitoDeclineModal
          setShowCognitoDecline={setShowCognitoDecline}
          showCognitoDecline={showCognitoDecline}
        />
      )}
      <OnboardWrapper
        canProgress={canProgress}
        currentProcess={1}
        onNext={() => {
          navigate("/AlmostThere");
        }}
      >
        <Content
          setCanProgress={setCanProgress}
          setShowCognitoDecline={setShowCognitoDecline}
        />
      </OnboardWrapper>
    </>
  );
}
