import React, { CSSProperties, ReactNode } from 'react';

interface TouchableOpacityProps {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  onPress?: () => void;
  [key: string]: any;
}

const TouchableOpacity: React.FC<TouchableOpacityProps> = ({
  children,
  className = '',
  style,
  onPress,
  ...restProps
}) => {
  // Default styles for the react-native TouchableOpacity component
  const defaultStyles = {
    outline: 'outline-none',
    focusOutline: 'focus:outline-none',
    activeOpacity: 'active:opacity-70',
  };

  // Check if clasName has classes that should overwrite default styles
  const userClasses = className.split(' ');
  const hasUserDefinedOutline = userClasses.some((cls) => cls === 'outline-none' || cls === 'outline');
  const hasUserDefinedFocusOutline = userClasses.some((cls) => cls.startsWith('focus:outline'));
  const hasUserDefinedActiveOpacity = userClasses.some((cls) => cls.startsWith('active:opacity'));

  // Include the default styles if not in className
  const outlineStyle = hasUserDefinedOutline ? '' : defaultStyles.outline;
  const focusOutlineStyle = hasUserDefinedFocusOutline ? '' : defaultStyles.focusOutline;
  const activeOpacityStyle = hasUserDefinedActiveOpacity ? '' : defaultStyles.activeOpacity;

  // Combine default and user-provided styles
  const combinedClassName = `${outlineStyle} ${focusOutlineStyle} ${activeOpacityStyle} ${className}`;

  return (
    <button className={combinedClassName.trim()} style={style} onClick={onPress} {...restProps}>
      {children}
    </button>
  );
};

export default TouchableOpacity;
