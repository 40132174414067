import DropDown from "../../components/DropDown";
import VeraModal from "../../components/Modal";
import { ReactComponent as CardPlus } from "../../svgs/CardPlus.svg";
import { ReactComponent as Clear } from "../../svgs/Clear.svg";
import { ReactComponent as DropDownClose } from "../../svgs/DropDownClose.svg";
import { ReactComponent as Refresh } from "../../svgs/Refresh.svg";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import OnboardWrapper from "./components/OnboardWrapper";
import { useAtom } from "jotai";
import { deviceTypeAtom } from "../../atoms/screenSize";
import { useNavigate } from "react-router-dom";

const CARD_WIDTH = 198;
const CARD_MARGIN = 8;
const SUGGESTED_ARTISTS_WIDTH = 775;

const Pill = ({ name }: { name: string }) => {
  return (
    <div className="mx-[6px] my-[8px] flex flex-row items-center rounded-[100px] bg-purple-50 p-[12px]">
      <p className="font-pn text-p-l mr-[10px] text-purple-500">{name}</p>
      <Clear />
    </div>
  );
};
const Card = ({ name, isLoading }: { name: string; isLoading: boolean }) => {
  const [device] = useAtom(deviceTypeAtom);
  return (
    <div
      className="flex h-[48px] flex-row items-center justify-center rounded border border-neutral-400 bg-white"
      style={{
        width: device === "mobile" ? "95%" : CARD_WIDTH,
        margin: CARD_MARGIN,
      }}
    >
      <p className="text-vera-black font-pn text-p-l m-[10px]">{name}</p>
      <div className="flex flex-1" />
      <CardPlus />
      <div className="w-[10px]" />
    </div>
  );
};
const SuggestedArtists = () => {
  const [device] = useAtom(deviceTypeAtom);
  return (
    <div
      style={{ width: device === "mobile" ? "100%" : SUGGESTED_ARTISTS_WIDTH }}
    >
      <div className="mt-[32px] w-full rounded bg-purple-50 p-[24px]">
        <div className="flex flex-row items-center ">
          <p
            className={`text-vera-black font-pn ${
              device === "mobile" ? "text-p-m" : "text-p-l"
            }`}
          >
            Suggested Artists
          </p>
          <div className="flex flex-1"></div>
          <Refresh />
          <p className="font-pn text-p-l ml-[5px] text-purple-500">Refresh</p>
        </div>
        <div className="my-[8px] flex flex-row flex-wrap">
          <Card name="Dean Martin" isLoading={false} />
          <Card name="Judy Garland" isLoading={false} />
          <Card name="Cab Calloway" isLoading={false} />
          <Card name="Dean Martin" isLoading={false} />
          <Card name="Judy Garland" isLoading={false} />
          <Card name="Cab Calloway" isLoading={false} />
          <Card name="Dean Martin" isLoading={false} />
          <Card name="Judy Garland" isLoading={false} />
          <Card name="Cab Calloway" isLoading={false} />
          <div
            className="h-[36px] w-full flex flex-row items-center justify-center rounded bg-purple-100 "
            style={{
              width: device === "mobile" ? "95%" : 198 * 3 + CARD_MARGIN * 4,
              margin: CARD_MARGIN,
            }}
          >
            <p className="text-vera-black font-pn text-p-l mr-[8px]">
              Show More
            </p>
            <DropDownClose />
          </div>
        </div>
      </div>
      <div className="mt-[34px] w-full flex flex-row flex-wrap">
        <Pill name="Aria Tesolin" />
        <Pill name="Cab Calloway" />
        <Pill name="Bobby McFerrin" />
        <Pill name="Jade H." />
        <Pill name="Aria Tesolin" />
        <Pill name="Cab Calloway" />
        <Pill name="Bobby McFerrin" />
        <Pill name="Jade H." />
      </div>
    </div>
  );
};
const Content = () => {
  const [device] = useAtom(deviceTypeAtom);
  return (
    <>
      <p
        className={`text-vera-black font-pnb ${
          device === "mobile" ? "text-h6-m" : "text-h6-d"
        }`}
      >
        Do they have any favourite artists?
      </p>
      <DropDown
        content={[{ name: "1" }, { name: "2" }]}
        placeHolder={"Select country"}
        setValue={() => null}
        isSearch={true}
      />
      <SuggestedArtists />
    </>
  );
};
const MAX_PROGRESS_WIDTH = 252;
const delay = (delayInms: number) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};
const ThinkingModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  const generateProgress = useCallback(async () => {
    for (let i = 0; i < MAX_PROGRESS_WIDTH; i++) {
      await delay(10);
      setWidth(i);
      i += 1;
    }
    navigate("/Success");
  }, [navigate]);
  useEffect(() => {
    if (showModal) {
      generateProgress();
    }
  }, [showModal, generateProgress]);

  const [device] = useAtom(deviceTypeAtom);
  return (
    <>
      {showModal ? (
        <VeraModal
          visible={showModal}
          setVisible={setShowModal}
          disableClose={true}
          ref={ref}
        >
          <div>
            <div className="mx-[20px] flex flex-1 flex-col items-center justify-center h-[400px]">
              <p className="text-h5-d font-pnb text-purple-500">
                {" "}
                Thinking... 75%
              </p>
              <div
                className="my-[20px] h-[10px] rounded-[10px] bg-[#D9D9D9]"
                style={{ width: MAX_PROGRESS_WIDTH }}
              >
                <div
                  className="relative -translate-y-[10px] my-[10px] h-[10px] w-[173px] rounded-[10px] bg-purple-500"
                  style={{ width: width }}
                />
              </div>
              <p
                className={`${
                  device === "mobile" ? "text-h5-m" : "text-h5-d"
                } font-pn text-vera-black`}
              >
                Vera is creating the soundtrack of their life
              </p>
            </div>
          </div>
        </VeraModal>
      ) : null}
    </>
  );
};
export default function Artists() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ThinkingModal showModal={showModal} setShowModal={setShowModal} />
      <OnboardWrapper
        canProgress={true}
        currentProcess={4}
        onNext={() => setShowModal(true)}
      >
        <Content />
      </OnboardWrapper>
    </>
  );
}
