import { useCallback, useState, ChangeEvent } from 'react';
//import { MdCreate, GoFileZip, FaSyncAlt, AiOutlinePlusCircle, } from 'react-icons/all';
import { FaSearch, FaPlayCircle, FaPlusCircle, FaPencilAlt, FaExchangeAlt } from 'react-icons/fa';
import { FiArchive } from 'react-icons/fi';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { IoCloseOutline } from 'react-icons/io5';
import { userAtom, accessTokenAtom } from "../../atoms/auth";
import { get, patch } from '../../utils/auth';
// import LeftPane from "@/pages/Dashboard/components/LeftPane";


//import { get, patch } from "../../utils/api/fetch";
import { useQuery, useQueryClient } from "@tanstack/react-query";
//import { userAtom, accessTokenAtom, userTypeAtom } from "@/atoms/auth";
import useIsVeraPro from "../../hooks/useIsVeraPro";
import { useAtom } from "jotai";
import { deviceTypeAtom, dimensionAtom } from "../../atoms/screenSize";
import Button from "../../components/Button"
import View from "./components/View"
import {Modal} from "./components/Modal"
import TouchableOpacity from "./components/TouchableOpacity" 
import Text from "./components/Text"

const archiveText:string = 'You are about to archive a listener profile. That means you will not be able to play music for this person anymore. You can activate this listener profile again by going to "archived profiles" in your listener tab in the side bar.'
const restoreText:string = 'You are about to restore a listener profile. Please note that by adding this listener profile back in will count as an active profile and will be updated in your next billing cycle.'


const fakeData = [
    {
      "archived": false,
      "birth_year": 1000,
      "favourite_station": "",
      "gender": "test",
      "home_id": 4,
      "id": 16,
      "last_action": {
        "station_name": null,
        "timestamp": null,
      },
      "location": "test",
      "name": "Jake test 5",
      "taste_profile_id": 3,
      "vera_user_id": null,
    },
    {
      "archived": false,
      "birth_year": 1000,
      "favourite_station": "",
      "gender": "test",
      "home_id": 4,
      "id": 19,
      "last_action": {
        "station_name": null,
        "timestamp": null,
      },
      "location": "test",
      "name": "Jake Test 6",
      "taste_profile_id": 3,
      "vera_user_id": null,
    },
    {
      "archived": false,
      "birth_year": 1000,
      "favourite_station": "",
      "gender": "test",
      "home_id": 4,
      "id": 20,
      "last_action": {
        "station_name": null,
        "timestamp": null,
      },
      "location": "test",
      "name": "Jake Test 6",
      "taste_profile_id": 3,
      "vera_user_id": null,
    },
    {
      "archived": false,
      "birth_year": 1000,
      "favourite_station": "",
      "gender": "test",
      "home_id": 4,
      "id": 21,
      "last_action": {
        "station_name": null,
        "timestamp": null,
      },
      "location": "test",
      "name": "Jake test 7",
      "taste_profile_id": 3,
      "vera_user_id": null,
    },
    {
      "archived": false,
      "birth_year": 1000,
      "favourite_station": "",
      "gender": "test",
      "home_id": 4,
      "id": 24,
      "last_action": {
        "station_name": null,
        "timestamp": null,
      },
      "location": "test",
      "name": "Jake test",
      "taste_profile_id": 3,
      "vera_user_id": null,
    },
    {
      "archived": false,
      "birth_year": 1000,
      "favourite_station": "",
      "gender": "test",
      "home_id": 4,
      "id": 26,
      "last_action": {
        "station_name": null,
        "timestamp": null,
      },
      "location": "test",
      "name": "DA",
      "taste_profile_id": 3,
      "vera_user_id": null,
    },
    {
      "archived": false,
      "birth_year": 1993,
      "favourite_station": "",
      "gender": "gender",
      "home_id": 4,
      "id": 3,
      "last_action": {
        "station_name": null,
        "timestamp": null,
      },
      "location": "with another fake station",
      "name": "jake test 1",
      "taste_profile_id": 3,
      "vera_user_id": null,
    },
    {
      "archived": false,
      "birth_year": 1000,
      "favourite_station": "",
      "gender": "test",
      "home_id": 4,
      "id": 27,
      "last_action": {
        "station_name": null,
        "timestamp": null,
      },
      "location": "test",
      "name": "K",
      "taste_profile_id": 3,
      "vera_user_id": null,
    },
    {
      "archived": false,
      "birth_year": 1993,
      "favourite_station": "",
      "gender": "gender",
      "home_id": 4,
      "id": 7,
      "last_action": {
        "station_name": null,
        "timestamp": null,
      },
      "location": "with another fake station",
      "name": "jake test 2",
      "taste_profile_id": 3,
      "vera_user_id": null,
    },
    {
      "archived": true,
      "birth_year": 1993,
      "favourite_station": "",
      "gender": "gender",
      "home_id": 4,
      "id": 12,
      "last_action": {
        "station_name": null,
        "timestamp": null,
      },
      "location": "with another fake station",
      "name": "jake test 3",
      "taste_profile_id": 3,
      "vera_user_id": null,
    },
    {
      "archived": true,
      "birth_year": 1993,
      "favourite_station": "",
      "gender": "gender",
      "home_id": 4,
      "id": 13,
      "last_action": {
        "station_name": null,
        "timestamp": null,
      },
      "location": "with another fake station",
      "name": "jake test 4",
      "taste_profile_id": 3,
      "vera_user_id": null,
    },
  ];

interface HeaderProps {
    onSearchTextChange: (text: string) => void;
  }

export function ListenerHeader({ onSearchTextChange }:HeaderProps) {
    
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        onSearchTextChange(event.target.value);
      }

  return (
    <div
            style={{ boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)" }}
            className="rounded-[8px] flex flex-[4_4_0%]"
        >
        <div className="flex flex-1 m-[12px] rounded-[8px] bg-purple-50">
            <div style={{
                width: '100%',
                height: '100%',
                backgroundImage: 'linear-gradient(to bottom right, rgba(255, 255, 255, 0) 0%, rgba(83, 20, 149, 0.27) 95%)',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
                padding: '12px'
            }}>
            <div className="flex h-full pt-[23px] pl-[16px] pr-[16px] pb-[23px] rounded-[8px] flex-col justify-between ">
                <p className="font-pn text-xxl text-vera-black items-start">
                    {"Listeners"}
                </p>
                <div className="flex flex-row justify-end items-end ">
                    <div className="flex w-[224px] h-[39px] bg-white rounded-[20px] border-gray-400 border flex-row items-center">
                        <div className="ml-[12px] mr-[11px]">
                            <FaSearch color="gray" className="w-[16px] h-[16px] " />
                        </div>
                        <input
                            className="font-pn w-[180px] h-auto text-vera-black bg-white text-[16px] rounded-[1px] leading-[19px] outline-none"
                            type="text"
                            onChange={handleInputChange}
                            placeholder="Search"
                        />
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  );
}

interface BodyProps {
    searchString: string;
  }

export function ListenerBody({ searchString}: BodyProps) {

    const [accessToken] = useAtom(accessTokenAtom)
    const isVeraPro = useIsVeraPro()
    const [user] = useAtom(userAtom)
    const [nameSortAlphabetical, setNameSortAlphabetical] = useState(true); // true if listeners sorted in alphabetical order, false for reverse alphabetical order
    const [isActiveTab, setIsActiveTab] = useState(true);  // true if active users shown, false if archived users shown
//     const [forceRender, setForceRender] = useState(false); // used to force a render on listnerbody when data is updated, such as an archived user
    const [archiveModalVisible, setArchiveModalVisible] = useState(false);
    const [activity, setActivity] = useState<ListenerActivity>();    

  const getLatestActivity = async () => {
        console.log('getLatestActivity user', user)
        if (user && isVeraPro) {
        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + accessToken);
        const response = await get('/pro/home/listeners?home_id=' + user.home_id, headers, true)
        return response as ListenerActivity[]      
        }
        return []
    }
    const queryClient = useQueryClient();
    const {data, isLoading, isError} = useQuery({queryKey: ['getActivities'], queryFn: getLatestActivity})
    //const data = fakeData as unknown as ListenerActivity[];
    console.log('data', data)
    
    if (data === undefined || !isVeraPro) return null

    const sortAlphabetical = () => {
        if(nameSortAlphabetical){
            data.sort((a, b) => a.name.localeCompare(b.name));
        } else{
            data.sort((a, b) => b.name.localeCompare(a.name));
        }
        setNameSortAlphabetical(!nameSortAlphabetical);
    }

    const archiveListener = (listener:ListenerActivity) =>{
        console.log('archiveListener ', listener)
        const f = async () =>{
            if (user && isVeraPro) {
                const headers = new Headers();
                headers.append('Authorization', 'Bearer ' + accessToken);
                const url = '/pro/listener/'+ listener.id + '/archive?archived=' + (isActiveTab ? 'true' : 'false');
                const response = await patch(url, headers, true);
                console.log('archiveListener temp', response)  
                queryClient.invalidateQueries({queryKey: ['getActivities']}); // invalidate the getActivities query to update data variable
            }
        }
        //archive user on frontend for quickness
        if (data!==undefined){
            data.map(item => {
                if (item.id === listener.id) {
                    console.log('listener.archived', !listener.archived)
                item.archived = !listener.archived;
                }
                return item;
            });
        }
        //setForceRender(!forceRender); // force the render to update frontend
        console.log('archivedListener', data)
        f();  // make the backend call
        
    };

    const editListener = (listener: ListenerActivity) =>{
        console.log('editListener ', listener)
    };

    return (
        <div className="flex-1 w-full ">
             <div className="w-full h-[56px] bg-white flex flex-row items-center justify-between border-b-[1px] border-b-neutral-200 mb-[16px]">
                 <div className=" flex flex-row w-[137px] h-full justify-between">
                     <button onClick={()=>{setIsActiveTab(true)}} className={`justify-center ${isActiveTab && 'border-b border-b-purple-500 flex-row' }`}>
                         <p className={`font-pn text-m ${isActiveTab ? 'font-weight-700 text-purple-500': 'font-weight-400 text-vera-black'}`}>Active</p>
                     </button>
                     <button onClick={()=>{setIsActiveTab(false)}} className={`justify-center ${!isActiveTab && 'border-b border-b-purple-500' }`}>
                         <p className={`font-pn text-m ${!isActiveTab ? 'font-weight-700 text-purple-500': 'font-weight-400 text-vera-black'}`}>Archived</p>
                     </button>
                 </div>
                 <button onClick={()=>{window.location.href = '/Onboarding/LetsGetStarted'}} className="flex flex-row border p-[12px] rounded-[3px] w-[80px] h-auto justify-center items-center border-neutral-200">
                     <FaPlusCircle color="#3E3B39" size={16} />
                     <p className="font-pn text-m font-weight-400 text-vera-black">Add</p>
                 </button>
             </div>
             <div className="w-full h-[40px] bg-gray-200 flex flex-row items-center border-b-[1px] border-b-neutral-200">
                 <div className="flex flex-row w-1/2">
                     <button onClick={()=>{sortAlphabetical()}}className="flex flex-1 flex-row items-center justify-start pl-[56px]">
                             <p className="font-pn text-m text-neutral-600 text-center pr-[4px]">
                             NAME
                             </p>
                             <FaExchangeAlt style={{ transform: 'rotate(90deg)' }} />
                     </button>
                     <button className="flex flex-1 flex-row items-center justify-center">
                         <p className="font-pn text-s text-neutral-600 pr-[4px]">
                         LAST LISTENED
                         </p>
                         <FaExchangeAlt style={{ transform: 'rotate(90deg)' }} />
                     </button>
                 </div>
             </div>
            <div>
             <>
            {isActiveTab && data?.filter((e) =>
            e.name.toLowerCase().includes(searchString.toLowerCase())
             ).map((activity, index) => {
                 return (
                    !activity.archived &&
                    <div key={index} className="w-full h-[70px] flex flex-row items-center border-b-[1px] border-b-neutral-200">
                        <div className="flex flex-row w-1/2">
                            <div className="flex flex-1 flex-col items-start justify-center pl-[56px]">
                                <div className="flex flex-1 flex-col items-start justify-center">
                                    <p className="flex font-pn text-[16px] font-weight-600 text-vera-black text-center">
                                    {activity.name}
                                    </p>
                                    <p className="flex font-pn text-[12px] text-vera-black">
                                    ID: {activity.id}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-1 flex-col items-center justify-center">
                                <p className="font-pn text-s text-vera-black">
                                {/* {activity.lastPlayed} */}
                                Today
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row w-1/2 justify-end">
                            <button onClick={()=>{setActivity(activity);setArchiveModalVisible(true)}} className="h-[40px] w-[100px] flex flex-col justify-center items-center">
                                <div className="flex flex-1 flex-row items-center justify-center">
                                    <div className="ml-[12px] mr-[11px]">
                                        <FiArchive color="gray" className="w-[16px] h-[16px] " />
                                    </div>
                                    <p className="font-pnb text-p-m text-gray-400 text-center">
                                        Archive
                                    </p>
                                </div>
                            </button>
                            <button onClick={()=>{editListener(activity)}}  className="h-[40px] w-[100px] flex flex-col justify-center items-center">
                                <div className="flex flex-1 flex-row items-center justify-center">
                                    <div className="ml-[12px] mr-[11px]">
                                        <FaPencilAlt color="gray" className="w-[16px] h-[16px]  " />
                                    </div>
                                    <p className="font-pnb text-p-m text-gray-400 text-center">
                                        Edit
                                    </p>
                                </div>
                            </button>
                            <button className="h-[40px] w-[100px] flex flex-col justify-center items-center">
                                <div className="flex flex-1 flex-row items-center justify-center">
                                    <div className="ml-[12px] mr-[11px]">
                                        <AiOutlinePlayCircle color="purple" className="[16px] h-[16px]" />
                                    </div>
                                    <p className="font-pnb text-p-m text-purple-500 text-center">
                                        Play
                                    </p>
                                </div>
                            </button>
                        </div>
                    </div>
                )
                }
            )}
                </>
                <>
                {!isActiveTab && data?.filter((e) =>
                    e.name.toLowerCase().includes(searchString.toLowerCase())
                    ).map((activity, index) => {
                    return (
                     activity.archived &&
                    <div key={index} className="w-full h-[70px] flex flex-row items-center border-b-[1px] border-b-neutral-200">
                        <div className="flex flex-row w-1/2">
                            <div className="flex flex-1 flex-col items-start justify-center pl-[56px]">
                                <div className="flex flex-1 flex-col items-start justify-center">
                                    <p className="flex font-pn text-[16px] font-weight-600 text-vera-black text-center">
                                    {activity.name}
                                    </p>
                                    <p className="flex font-pn text-[12px] text-vera-black">
                                    ID: {activity.id}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-1 flex-row items-center justify-center">
                                <p className="font-pn text-s text-vera-black">
                                {/* {activity.lastPlayed} */}
                                Today
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row w-1/2 justify-end">
                            <button onClick={()=>{setActivity(activity);setArchiveModalVisible(true)}} className="h-[40px] w-[100px] flex flex-col justify-center items-center">
                                <div className="flex flex-1 flex-row items-center justify-center">
                                    <div className="ml-[12px] mr-[11px]">
                                        <FiArchive color="gray" className="w-[16px] h-[16px] " />
                                    </div>
                                    <p className="font-pnb text-p-m text-gray-400 text-center">
                                        Restore
                                    </p>
                                </div>
                            </button>
                        </div>
                    </div>
                    )
                }
            )}
            </>
             </div> 




             <Modal 
            transparent={true}
            visible={archiveModalVisible}
            >
            <View className="flex flex-1 justify-center items-center" style={{backgroundColor: 'rgba(128, 128, 128, 0.5)'}}>
                <View className="flex flex-col w-[72%] h-auto bg-white rounded-[8px]">
                    <View className="flex items-end justify-end mt-[16px] mr-[16px]">
                      <TouchableOpacity onPress={()=>{setArchiveModalVisible(false)}} className="h-[22px] w-[100px] flex flex-col justify-center items-center">
                          <View className="flex flex-1 flex-row items-center justify-center">
                              <View className="mr-[4px]">
                                  <IoCloseOutline color="gray" size={20} />
                              </View>
                              <Text className="font-pn text-p-m text-vera-black text-center">
                                  Close
                              </Text>
                          </View>
                      </TouchableOpacity>
                    </View>
                    <View className="ml-[42px] mr-[42px] mb-[42px]">
                        <Text className="font-pnb text-xxl text-vera-black text-start">
                            {activity?.archived ? "Would you like to restore " + activity.name : "Archive Listener Profile"}
                        </Text>
                        <View className="mt-[10px]"/>
                        <Text className="font-pn text-p-m text-vera-black text-start">
                            {activity?.archived ? restoreText : archiveText}
                        </Text>
                        <Text className="font-pn text-p-m text-vera-black text-start mt-[12px]">
                            {"If you've landed here by mistake please, simply click \"cancel\" "}
                        </Text>
                        <View className="mt-[60px]"/>
                        <View className="flex flex-row">
                            <Button style={{paddingLeft:5, paddingRight:5, flexGrow:.05}} isReady={true} type="filled" text={activity?.archived ? "Restore Profile":"Archive Profile"} 
                                onPressFn={()=>{
                                    if(activity!==undefined){
                                        archiveListener(activity)
                                    }
                                    setArchiveModalVisible(false)
                                }} />
                            <View className="ml-[8px]"/>
                            <Button type="outline" text="Cancel" onPressFn={()=>{setArchiveModalVisible(false)}} />
                        </View>
                    </View>
                </View>
                </View>
            </Modal>







        </div>
    );
  }


export default function Listener() {
    const [dimension] = useAtom(dimensionAtom);
    const [userType, setUserType] = useState('CareGiver')
    const [searchString, setSearchString] = useState<string>('');


    const onSearchStringChange = useCallback((text: string) => {
        setSearchString(text);
      }, []);


  return (
    <div className="m-[16px] flex flex-1 flex-row">
        <div
          className="flex rounded-[8px] flex-[5_5_0%] bg-white pt-[32px] pl-[16px]"
          style={{
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
          }}
        >
          {/* <LeftPane
            selected={"Listeners"}
            setSelected={()=>{}}
            setShowListenerModal={()=>{}}
          /> */}
        </div>

        <div className="mr-[16px]" />

        <div
          className="flex flex-col flex-[24_24_0%] rounded-[8px]"
          style={{
            width: (dimension.width / 29) * 24 - 48,
          }}
        >
            <ListenerHeader onSearchTextChange={onSearchStringChange} />
            <div  className="flex mb-[16px]" />
                <div 
                    className="flex flex-[24_24_0%]  rounded-[8px] border"
                    style={{
                        width: (dimension.width / 29) * 24 - 48,
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
                        padding:12,
                        backgroundColor:"#FFF"
                      }}
                >
                    

                <div  className="flex flex-1 justify-center items-center">
                
                    <ListenerBody searchString={searchString} />
                </div >
            </div >
        </div >
      </div >
  );
}
