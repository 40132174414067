import { useAtom } from "jotai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  emailAtom,
  passwordAtom,
  stayLoggedInAtom,
  idTokenAtom,
  accessTokenAtom,
} from "../../../atoms/auth";
import {
  authenticate,
  forgotPassword,
  signup,
  socialMediaLogin,
} from "../../../utils/auth";
import { ReactComponent as Error } from "../../../svgs/error.svg";
import { ReactComponent as Google } from "../../../svgs/google.svg";
import { ReactComponent as Apple } from "../../../svgs/apple.svg";
import { ReactComponent as Vera } from "../../../svgs/vera.svg";
import InputForm from "../../../components/inputForm";
import ChangePassword from "./ChangePassword";

export default function LoginRegisterSelect(props: {
  device: DeviceType;
  type: LoginState;
  setType: (t: LoginState) => void;
  isVeraPro: boolean;
  dimension: DimensionType;
}) {
  const [stayLoggedIn, setStayLoggedIn] = useAtom(stayLoggedInAtom);
  const [password, setPassword] = useAtom(passwordAtom);
  const [email, setEmail] = useAtom(emailAtom);
  const [name, setName] = useState("");
  const [type, setType] = useState<LoginState>(props.type);
  const [errorMessage, setErrorMessage] = useState("");
  const [idToken, setIdToken] = useAtom(idTokenAtom);
  const [, setAccessToken] = useAtom(accessTokenAtom);
  const navigate = useNavigate();
  console.log(type);

  const RIGHTPANELHEADERHEIGHT = props.dimension.height / 5;
  return (
    <>
      <div className="flex flex-[7_7_0%] flex-col">
        {type !== "changePassword" && props.device !== "mobile" && (
          <div
            className="flex flex-row items-center"
            style={{
              height: RIGHTPANELHEADERHEIGHT,
            }}
          >
            <div className="flex flex-1"></div>
            <p className="text-m text-vera-black font-pn">
              {type === "login" ? "Not a member yet?" : "Already a member?"}
            </p>
            <button
              onClick={() => {
                if (type === "login") {
                  setType("register");
                } else {
                  setType("login");
                }
              }}
            >
              <div className="flex ml-[10px] h-[40px] w-[96px] items-center justify-center rounded-lg border-2 border-neutral-400">
                <p className="text-s text-vera-black font-pnb">
                  {type === "login" ? "Sign Up" : "Login"}
                </p>
              </div>
            </button>
          </div>
        )}
        {type === "changePassword" ? (
          <>
            <div className="flex flex-col items-center">
              <div>
                <ChangePassword
                  setType={setType}
                  email={email}
                  setEmail={setEmail}
                  isVeraPro={props.isVeraPro}
                  device={props.device}
                />
              </div>
            </div>
          </>
        ) : (
          <div>
            {props.device !== "mobile" ? (
              <TabletHeading type={type} isVeraPro={props.isVeraPro} />
            ) : (
              <MobileHeading
                type={type}
                setType={setType}
                isVeraPro={props.isVeraPro}
              />
            )}
            {(type === "register" ||
              (type === "select" && props.device !== "mobile")) && (
              <InputForm type="name" value={name} setValue={setName} />
            )}
            <InputForm type="email" value={email} setValue={setEmail} />
            <InputForm
              type="password"
              value={password}
              setValue={setPassword}
            />
            {errorMessage !== "" && (
              <div className="mt-[10px] flex flex-row items-center">
                <Error />
                <div className="ml-[10px]" />
                <p className="font-pn text-s text-error-500">{errorMessage}</p>
              </div>
            )}
            {type === "login" && (
              <div className="flex mt-[14px] flex-row items-center">
                <input
                  type={"checkbox"}
                  checked={stayLoggedIn}
                  onChange={(e) => {
                    setStayLoggedIn(!stayLoggedIn);
                  }}
                  className="border-vera-black mr-[8px] rounded border-[1px]"
                />
                <p className="text-m text-vera-black font-pn">
                  Keep me logged in
                </p>
                <div className="flex flex-1" />
                <button
                  onClick={() => {
                    props.setType("changePassword");
                  }}
                >
                  <p className="text-m font-pn text-purple-500">
                    Forgot password?
                  </p>
                </button>
              </div>
            )}
            <div className="flex flex-row items-center justify-center w-full ">
              <button
                className="mt-[16px] h-[56px] pt-[10px] rounded-lg border-2 border-neutral-400 w-full"
                onClick={
                  type === "login"
                    ? () =>
                        authenticate(
                          props.isVeraPro,
                          email,
                          password,
                          navigate,
                          setIdToken,
                          setAccessToken,
                          props.setType,
                          setErrorMessage
                        )
                    : () =>
                        signup(
                          props.isVeraPro,
                          email,
                          password,
                          () => props.setType("verifyEmail"),
                          setErrorMessage
                        )
                }
              >
                <p className="text-vera-black font-pnb text-xl">
                  {type === "login" ? "LOGIN" : "SIGN UP"}
                </p>
              </button>
            </div>
            <div className="mt-[24px]" />
            <div className="flex mt-[5px] flex-row items-center">
              <div className="h-[1px] flex-1 bg-neutral-400"></div>
              <p className="text-m text-vera-black font-pn mx-[14px]">OR</p>
              <div className="h-[1px] flex-1 bg-neutral-400"></div>
            </div>
            <div className="mt-[24px]" />
            <div className="flex flex-row items-center justify-center">
              <button
                onClick={() => {
                  socialMediaLogin(props.isVeraPro, "Google");
                }}
                className="flex mx-[6px] h-[56px] w-[105px] items-center justify-center rounded-lg border-2 border-neutral-400"
              >
                <Google />
              </button>
              <button
                onClick={() => {
                  socialMediaLogin(props.isVeraPro, "Apple");
                }}
                className="flex mx-[6px] h-[56px] w-[105px] items-center justify-center rounded-lg border-2 border-neutral-400"
              >
                <Apple />
              </button>
            </div>
          </div>
        )}
        <div className="flex-1" />
      </div>
    </>
  );
}

const TabletHeading = ({
  type,
  isVeraPro,
}: {
  type: LoginState;
  isVeraPro: boolean;
}) => {
  return (
    <>
      <div className="flex flex-row items-center">
        <p className="text-xxl text-vera-black font-pnb">
          {type === "login" ? "Login to " : "Sign up to "}
        </p>
        <div className="mr-[5px]" />
        <Vera />
        <div className="mr-[5px]" />
        {isVeraPro && (
          <div className="bg-purple-550 rounded-[2px] px-[5px]">
            <p className="text-s font-pnb text-white ">PRO</p>
          </div>
        )}
      </div>
      <p className="text-m text-vera-black font-pn">
        Enter your details below.
      </p>
    </>
  );
};

const MobileHeading = (props: {
  isVeraPro: boolean;
  type: LoginState;
  setType: (s: LoginState) => void;
}) => {
  return (
    <>
      <div className="flex flex-row">
        <Vera />
        {props.isVeraPro && (
          <div className="bg-purple-550 ml-[5px] rounded-[2px] px-[5px]">
            <p className="text-s font-pnb text-white ">PRO</p>
          </div>
        )}
      </div>
      <div className="mb-[24px]" />
      <p className="font-pnb text-xxl text-vera-black">
        {props.type === "login" ? "Login" : "Sign Up"}
      </p>
      <div className="mb-[8px]" />
      <p className="font-pn text-s text-vera-black">
        {props.type === "login" ? "Not a member yet? " : "Already a member? "}
        <button
          onClick={() => {
            if (props.type === "login") {
              props.setType("register");
            } else {
              props.setType("login");
            }
          }}
        >
          <p className=" text-purple-500">
            {props.type === "login" ? "Join Now" : "Login"}
          </p>
        </button>
      </p>
    </>
  );
};
