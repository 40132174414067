import useIsVeraPro from "../../../hooks/useIsVeraPro";
import { ReactComponent as Vera } from "../../../svgs/vera.svg";
import { useAtom } from "jotai";
import { deviceTypeAtom } from "../../../atoms/screenSize";

const ProgressTab = ({ isActive }: { isActive: boolean }) => {
  const [device] = useAtom(deviceTypeAtom);
  const color = isActive ? "bg-purple-500" : "bg-purple-200";
  return (
    <div
      className={`${color} mr-[8px] h-[5px] w-[36px] rounded`}
      style={{
        height: device !== "mobile" ? 10 : 6,
        width: device !== "mobile" ? 78 : 35,
      }}
    />
  );
};

type HeaderProps = { total: number; current: number };
const ProgressBar = ({ total, current }: HeaderProps) => {
  return (
    <>
      {[...Array(total)].map((e, i) => (
        <ProgressTab isActive={i <= current} key={i} />
      ))}
    </>
  );
};
export default function Header({ total, current }: HeaderProps) {
  // param `total` is the total number of progress tabs
  // param `current` is the current 0-indexed position
  const isVeraPro = useIsVeraPro();
  return (
    <>
      {/* <SafeAreaView className="flex-0" /> */}
      <div className="mx-[20px] my-[40px] flex flex-row items-center">
        <Vera />
        {isVeraPro && (
          <div className="bg-purple-550 rounded-[2px] px-[10px] py-[2px]">
            <p className="font-pn text-h4-d text-white">Pro</p>
          </div>
        )}
        <div className="h-[10px] flex-1" />
        <ProgressBar total={total} current={current} />
      </div>
    </>
  );
}
