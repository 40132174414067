import YearPicker from "../pages/Onboarding/components/YearPicker";
import { ReactComponent as DropDownClose } from "../svgs/DropDownClose.svg";
import { ReactComponent as DropDownOpen } from "../svgs/DropDownOpen.svg";
import React, { Dispatch, useState } from "react";
import { ReactComponent as Search } from "../svgs/Search.svg";
import { ReactComponent as CardPlus } from "../svgs/CardPlus.svg";

type DropDownContent = {
  content: { name: string }[];
  placeHolder: string;
  setValue: any;
  isSearch?: boolean;
  year?: boolean;
};

const CONTAINER_STYLE =
  "flex h-[55px] w-full max-w-[444px] flex-row items-center justify-between rounded-[8px] border-[1px] border-neutral-400";
const SearchBar = ({
  setShowContent,
  setValue,
}: {
  setShowContent: (b: boolean) => void;
  setValue: (b: string) => void;
}) => {
  return (
    <div className={CONTAINER_STYLE} style={{ paddingLeft: 10 }}>
      <Search />
      <input
        type={"text"}
        onChange={(t) => {
          setShowContent(t.target.value !== "");
          setValue(t.target.value);
        }}
        className="text-p-l text-vera-black font-pn ml-[10px] flex-1 focus:outline-none"
        style={{ lineHeight: 0 }}
      />
    </div>
  );
};

export default function DropDown(props: DropDownContent) {
  const [showContent, setShowContent] = useState(false);
  const [selectContent, setSelectContent] = useState(props.placeHolder);
  const Selector = () => {
    return (
      <button
        onClick={() => {
          setShowContent(!showContent);
        }}
      >
        <div className="flex h-[55px] w-[444px] flex-row items-center justify-between rounded-[8px] border-[1px] border-neutral-400 p-[16px]">
          <p
            className={`font-pn text-s ${
              selectContent === props.placeHolder
                ? "text-neutral-400"
                : "text-neutral-600"
            }`}
          >
            {selectContent}
          </p>
          {showContent ? <DropDownOpen /> : <DropDownClose />}
        </div>
      </button>
    );
  };

  const Content = () => {
    return (
      <>
        <div className="flex w-[444px] flex-col rounded-[8px] border-[1px] border-neutral-400 bg-white">
          {props.content.map((content, index) => {
            return (
              <div key={index}>
                <button
                  onClick={() => {
                    props.setValue(content.name);
                    setSelectContent(content.name);
                    setShowContent(false);
                  }}
                >
                  <div
                    className="flex flex-row w-[444px] items-center justify-start border-neutral-400 py-[16px]"
                    style={{
                      borderBottomWidth:
                        index === props.content.length - 1 ? 0 : 1,
                    }}
                  >
                    <p
                      className={`font-pn pl-[16px] text-p-m ${
                        selectContent === content.name && " text-purple-550"
                      }`}
                    >
                      {content.name}
                    </p>
                    {props.isSearch ? (
                      <>
                        <div className="ml-[10px] rounded-[100px] bg-purple-50 p-[5px]">
                          <p className="font-pn text-p-s text-purple-500">
                            OPERA
                          </p>
                        </div>
                        <div className="flex-1" />
                        <CardPlus />
                        <p className="font=pn text-b-m ml-[5px] text-purple-500">
                          ADD
                        </p>
                      </>
                    ) : null}
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col">
      {props.isSearch ? (
        <SearchBar setShowContent={setShowContent} setValue={props.setValue} />
      ) : (
        <Selector />
      )}
      <div className="mt-[4px]" />
      {showContent && !props.year && <Content />}
      {showContent && props.year && (
        <YearPicker
          setValue={props.setValue}
          setSelectContent={setSelectContent}
          selectContent={selectContent}
        />
      )}
    </div>
  );
}
