import { useEffect, useRef, useState } from "react";
import 'shaka-player/dist/controls.css';
import ShakaPlayer from 'shaka-player-react'


const licenseToken = 'eyJkcm1fdHlwZSI6IldpZGV2aW5lIiwic2l0ZV9pZCI6IkdFTVYiLCJ1c2VyX2lkIjoiMSIsImNpZCI6InVtZzp0cmFjazo4ZjIyNjkwOTUyY2M0YWVhOTI3N2IzNDJhYWZlMDY2Ny0wIiwicG9saWN5IjoiKzcwOHVFYzVLMFdTc3RYZEpWZDBkQ29EVWZubUpHNnlUWUhzcHlMNmI2b0FMKytMeGdmSmtpeTVoejVhR0dHTU1CUVROcDhnamEvQnF1c0tNekNmZDBDZENsdlpEY2pmdVIwTzJEZ2h1b1ZhNmwwRXVWZ1cyeHVORVIySlVPRG4iLCJ0aW1lc3RhbXAiOiIyMDIzLTAzLTA5VDA0OjMwOjQ5WiIsImhhc2giOiJBMmZJQ2RnR0VSVmd3cVJLaXdDcUxLdDZxbG9laTNLMmFKUlRqNkRBNjRJPSJ9'
export default function Player() {
    const controllerRef = useRef<any>(null);
    useEffect(() => {
        const {
          /** @type {shaka.Player} */ player,
          /** @type {shaka.ui.Overlay} */ ui,
          /** @type {HTMLVideoElement} */ videoElement
        } = controllerRef.current;
        console.log({player})
    
        async function loadAsset() {
          // Load an asset.
          if (player) {
            console.log("Hello")
            const config = await player.configure({
              drm: {
                servers: {
                  'com.widevine.alpha': 'https://license.pallycon.com/ri/licenseManager.do',
                  'com.microsoft.playready': 'https://license.pallycon.com/ri/licenseManager.do'
                }
              }
            });
            console.log({config})
            const header = await player
            .getNetworkingEngine()
            .registerRequestFilter(function (type:any, request:any) {
            // Only add headers to license requests:
            //if (type == player.net.NetworkingEngine.RequestType.LICENSE) {
            // This is the specific header name and value the server wants:
            console.log("Hello")
            request.headers["pallycon-customdata-v2"] = licenseToken;
            //}
            })
            console.log({header})
            const url = 'https://musichealth-drm-tracks.s3.us-west-2.amazonaws.com/tracks/umg:track:8f22690952cc4aea9277b342aafe0667-0/dash/stream.mpd'
            //const url = "https://dev-vera-public-assets.s3.ap-southeast-2.amazonaws.com/sample-mp4-file.mp4"
            const response = await player.load(url);
            console.log({response})
    
            // Trigger play.
            videoElement.play();
          }

        }
    
        loadAsset();
      }, [controllerRef]);
    
    return <ShakaPlayer autoPlay ref={controllerRef}/>
}