import { useState } from "react";
import { capitalize } from "../utils/string";
import { ReactComponent as Search } from "../svgs/Search.svg";
export default function InputForm({
  type,
  value,
  setValue,
}: {
  type:
    | "email"
    | "password"
    | "name"
    | "code"
    | "New password"
    | "codeLong"
    | "searchListener";
  value: string;
  setValue: (v: string) => void;
}) {
  const [security, setSecurity] = useState(
    type === "password" || type === "New password"
  );
  const [focus, setFocus] = useState(false);
  let title: string = type;
  if (type === "email") title += " address";
  if (type === "codeLong") title = "Enter the 6-digit code";
  console.log(focus);
  return (
    <>
      {type !== "searchListener" && (
        <p className="text-l text-vera-black font-pnb mt-[24px]">
          {capitalize(title)}
        </p>
      )}
      <div className="flex flex-row items-center justify-end w-full">
        <input
          type={type === "email" ? "email" : security ? "password" : "text"}
          style={{
            borderColor: focus ? "#7A23D3" : "#C4C3C6",
            maxWidth: type === "searchListener" ? 504 : "auto",
          }}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          value={value}
          onChange={(c) => {
            type === "email"
              ? setValue(c.target.value.toLowerCase())
              : setValue(c.target.value);
          }}
          className="text-m text-vera-black font-pn mt-[6px] h-[50px] w-full rounded-lg border-2 px-[10px] focus:outline-none"
          placeholder={
            type === "searchListener"
              ? "Search Listener"
              : "Enter your " + type !== "codeLong"
              ? type
              : "code"
          }
        />
        {type === "searchListener" && (
          <button
            onClick={() => {
              return;
            }}
            className="absolute translate-y-[6px] -translate-x-[10px] flex-row items-center"
          >
            <Search />
          </button>
        )}
        {type === "password" && (
          <button
            onClick={() => {
              setSecurity(!security);
            }}
            className="flex absolute translate-y-[6px] -translate-x-[10px] flex-row items-center"
          >
            <div className="h-[30px] w-[1px] bg-neutral-500" />
            <div className="mr-[10px]" />
            <p className="font-pn text-s text-neutral-500 ">
              {security ? "SHOW" : "HIDE"}
            </p>
          </button>
        )}
      </div>
    </>
  );
}
