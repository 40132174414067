import { ReactComponent as Clear } from "../../../svgs/Clear.svg";
import { useState } from "react";

export default function TextInputForm({
  value,
  setValue,
  placeHolder,
}: {
  value: string;
  setValue: (v: string) => void;
  placeHolder: string;
}) {
  const [focus, setFocus] = useState(false);
  return (
    <>
      <div
        className="flex h-[55px] w-full max-w-[444px] flex-row items-center justify-between rounded-[8px] border-[1px] p-[16px]"
        style={{ borderColor: focus ? "#7A23D3" : "#C4C3C6" }}
      >
        <input
          type={"text"}
          className="font-pn text-s h-[45px] w-11/12  text-neutral-600 focus:outline-none caret-purple-500"
          // selectionColor={'#7A23D3'}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          placeholder={placeHolder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        ></input>
        <button
          onClick={() => {
            setValue("");
          }}
        >
          <Clear />
        </button>
      </div>
    </>
  );
}
