import {
  accessTokenAtom,
  emailAtom,
  idTokenAtom,
  isVeraProAtom,
  passwordAtom,
} from "../../../atoms/auth";
import { ReactComponent as Vera } from "../../../svgs/vera.svg";
import { checkNewPasswordValid } from "../../../utils/regex";
import { confirmRegistration } from "../../../utils/auth";
import { useAtom } from "jotai";
import { useState } from "react";
import InputForm from "../../../components/inputForm";
import { useNavigate } from "react-router-dom";

export default function ChangePassword(props: {
  setType: (t: LoginState) => void;
  email: string;
  setEmail: (v: string) => void;
  isVeraPro: boolean;
  device: "mobile" | "tablet" | "web";
}) {
  const [email, setEmail] = useAtom(emailAtom);
  const [code, setCode] = useState("");
  const [password, setPassword] = useAtom(passwordAtom);
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
  const [, setAccessToken] = useAtom(accessTokenAtom);
  const [idToken, setIdToken] = useAtom(idTokenAtom);
  const [isVeraPro] = useAtom(isVeraProAtom);
  const isCodeValid = code.length === 6;
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col justify-center"
      style={{
        height: "100vh",
        justifyContent: props.device !== "mobile" ? "center" : "normal",
      }}
    >
      <p className="font-pnb text-xxl">Verify E-mail</p>
      <div className="mb-[6px]" />
      <p className="font-pn text-s text-vera-black">
        Enter your email below and we will send you a reset code with
        instructions
      </p>
      <div className="mb-[32px]" />
      <InputForm type={"codeLong"} value={code} setValue={setCode} />
      <div className="mb-[16px]" />
      <div>
        <button
          disabled={!isCodeValid}
          onClick={() => {
            props.setEmail(email);
            confirmRegistration(
              isVeraPro,
              code,
              email,
              password,
              navigate,
              setIdToken,
              setAccessToken,
              props.setType
            );
            setIsEmailConfirmed(true);
          }}
          style={{
            backgroundColor: checkNewPasswordValid(password)
              ? "#7A23D3"
              : "#DFDEE0",
          }}
          className="flex mt-[6px] h-[50px] w-full flex-col items-center justify-center rounded-lg border-[1px] border-neutral-200 bg-neutral-200"
        >
          <p
            style={{
              color: checkNewPasswordValid(password) ? "#FFF" : "#000",
            }}
            className="text-vera-black font-pnb text-xl"
          >
            CONFIRM
          </p>
        </button>
      </div>
      <div className="mb-[16px]" />
      <div>
        <button
          onClick={() => {
            props.setEmail(email);
            props.setType("login");
          }}
          className="flex mt-[6px] h-[50px] w-full flex-col items-center justify-center rounded-lg border-[1px] border-neutral-200"
        >
          <p className="text-vera-black font-pnb text-xl">CANCEL</p>
        </button>
      </div>
    </div>
  );
}
