import CardList from "../../components/CardList";
import { deviceTypeAtom } from "../../atoms/screenSize";
import React, { Dispatch, useEffect, useState } from "react";
import OnboardWrapper from "./components/OnboardWrapper";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";

const Content = ({
  setCanProgress,
}: {
  setCanProgress: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";
  const [type, setType] = useState("");

  useEffect(() => {
    if (type !== "") {
      setCanProgress(true);
    }
  }, [setCanProgress, type]);

  return (
    <>
      <div className="flex flex-1 flex-row">
        <div className="flex flex-[6_6_0%] flex-col">
          <p className={`${isPhone ? "text-h1-m" : "text-h1-d"} font-pnb`}>
            What type of Healthcare professional are you?
          </p>
          <div className="mb-[32px]" />
          <CardList
            type="singleChoice"
            content={[
              { name: "Caregiver" },
              { name: "Music Therapist" },
              { name: "Lifestyle Manager" },
              { name: "Nurse" },
              { name: "Other" },
            ]}
            setValue={setType}
          />
        </div>
        {!isPhone && <div className="flex flex-[1_1_0%]" />}
      </div>
    </>
  );
};

export default function LetsGetStart() {
  const [canProgress, setCanProgress] = useState(false);
  const navigate = useNavigate();
  return (
    <OnboardWrapper
      canProgress={canProgress}
      currentProcess={1}
      onNext={() => {
        navigate("/Success");
      }}
    >
      <Content setCanProgress={setCanProgress} />
    </OnboardWrapper>
  );
}
