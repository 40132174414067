export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const REACT_APP_VERADOMAIN = process.env.REACT_APP_VERADOMAIN;
export const REACT_APP_RECOMMENDERDOMAIN =
  process.env.REACT_APP_RECOMMENDERDOMAIN;
export const REACT_APP_VERA_PRO_USER_POOL_ID =
  process.env.REACT_APP_VERA_PRO_USER_POOL_ID;
export const REACT_APP_VERA_PRO_CLIENT_ID =
  process.env.REACT_APP_VERA_PRO_CLIENT_ID;
export const REACT_APP_VERA_USER_POOL_ID =
  process.env.REACT_APP_VERA_USER_POOL_ID;
export const REACT_APP_VERA_CLIENT_ID = process.env.REACT_APP_VERA_CLIENT_ID;
export const REACT_APP_INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const VERADOMAIN = process.env.VERADOMAIN;
export const RECOMMENDERDOMAIN = process.env.RECOMMENDERDOMAIN;
