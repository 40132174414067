import React from "react";
import VeraModal from "./Modal";

const VideoScreen = () => {
  const video = React.useRef(null);

  return (
    <div className="mx-[10px] h-full flex-1 justify-center">
      <video ref={video} style={{ flex: 1 }} controls width="100%" height="360">
        <source src="https://dev-vera-public-assets.s3.ap-southeast-2.amazonaws.com/sample-mp4-file.mp4" />
      </video>
    </div>
  );
};

export default function VideoModal({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: (e: boolean) => void;
}) {
  return (
    <VeraModal
      visible={showModal}
      setVisible={() => setShowModal(!showModal)}
      disableCloseBottom
    >
      <VideoScreen />
    </VeraModal>
  );
}
