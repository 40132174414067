import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { deviceTypeAtom, dimensionAtom } from "../../atoms/screenSize";
import CardList from "../../components/CardList";
import Footer from "./components/Footer";
import Header from "./components/Header";

export default function LetsGetStart() {
  const [device] = useAtom(deviceTypeAtom);
  const [dimension] = useAtom(dimensionAtom);
  const navigate = useNavigate();
  let leftMargin: number | string = 15;
  if (device === "tablet") {
    leftMargin = "15%";
  }
  if (device === "web") {
    leftMargin = "20%";
  }
  const genres = [
    "Blues",
    "Classical",
    "Country",
    "Jazz",
    "Musical",
    "Pop",
    "Reggae",
    "Rock",
    "World",
    "Soul/R&B",
  ];
  return (
    <div
      style={{ height: dimension.height }}
      className="flex flex-1 flex-col justify-between bg-white"
    >
      <Header total={4} current={3} />
      <div style={{ marginLeft: leftMargin, flex: 1 }}>
        <p
          className={`text-vera-black font-pnb ${
            device === "mobile" ? "text-h1-m" : "text-h1-d"
          }`}
        >
          Music Taste
        </p>
        <p className="text-vera-black font-pn text-p-l mb-[30px]">
          What are their favourite music genres?
        </p>
        <p
          className={`text-vera-black font-pnb ${
            device === "mobile" ? "text-h6-m" : "text-h6-d"
          }`}
        >
          Select at least one genre
        </p>
        <CardList
          content={genres.map((g) => {
            return { name: g };
          })}
          type="cardPlus"
          setValue={() => null}
        />
        <div className="flex flex-1" />
      </div>
      <Footer canProgress={true} onNext={() => navigate("/Artists")} />
    </div>
  );
}
