import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useNavigate, useNavigation } from "react-router-dom";
import { accessTokenAtom, userAtom, userTypeAtom } from "../../../atoms/auth";
import { activeListenerAtom } from "../../../atoms/listener";
import useIsVeraPro from "../../../hooks/useIsVeraPro";
import { get } from "../../../utils/api";
import Header from "./Header";

type ListenerActivity = {
  name: string;
  lastActive: string; // Should be date string
  lastPlayed: string;
  favouriteStation: string;
};

export default function LatestActivity() {
  const [accessToken] = useAtom(accessTokenAtom);
  const isVeraPro = useIsVeraPro();
  const [user] = useAtom(userAtom);

  console.log({ accessToken });
  console.log({ user });

  const getLatestActivity = async () => {
    if (user && isVeraPro) {
      const headers = new Headers();
      headers.append("Authorization", "Bearer " + accessToken);
      const response = await get(
        "/pro/home/listeners?home_id=" + user.home_id,
        headers,
        true
      );
      return response as ListenerActivity[];
    }
    return [];
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ["getActivities"],
    queryFn: getLatestActivity,
  });

  const [, setUserType] = useAtom(userTypeAtom);
  const [, setActiveListener] = useAtom(activeListenerAtom);
  console.log({ data });
  const navigate = useNavigate()
  const onPress = (name: string) => {
    setUserType("Listener");
    setActiveListener({ name: name });
    navigate("Relax")

  }
  return (
    <div className="flex flex-1 flex-col">
      <div
        style={{ boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)" }}
        className="rounded-[8px] flex flex-[4_4_0%]"
      >
        <Header name={"Jake"} />
      </div>
      <div className="mb-[16px]" />
      <div
        className="p-[30px] flex flex-[16_16_0%] flex-col rounded-[8px]"
        style={{
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
        }}
      >
        <div className="flex flex-1 flex-col">
          <p className="font-pnb text-m text-vera-black">Latest Activity</p>
          <div className="mb-[16px]" />
          <div className="w-full h-[40px] bg-background-100 rounded-[4px] flex flex-row items-center">
            <div className="flex-1 flex-row items-center justify-center">
              <p className="font-pn text-s text-neutral-600 text-center">
                NAME
              </p>
            </div>
            <div className="flex-1 flex-row items-center justify-center">
              <p className="font-pn text-s text-neutral-600 text-center">
                LAST ACTIVE
              </p>
            </div>
            <div className="flex-1 flex-row items-center justify-center">
              <p className="font-pn text-s text-neutral-600 text-center">
                LAST PLAY
              </p>
            </div>
            <div className="flex-1 flex-row items-center justify-center">
              <p className="font-pn text-s text-neutral-600 text-center">
                FAVOURITE STATION
              </p>
            </div>
            <div className="flex flex-1" />
          </div>
        </div>
        <div className="flex flex-[10_10_0%] flex-col ">
          {data?.map((activity, index) => {
            return (
              <div key={index}>
                <div className="w-full h-[70px] flex flex-row items-center border-b-[1px] border-b-neutral-200">
                  <div className="flex-1 flex-row items-center justify-center">
                    <p className="font-pn text-m text-purple-500 text-center">
                      {activity.name}
                    </p>
                  </div>
                  <div className="flex-1 flex-row items-center justify-center">
                    <p className="font-pn text-s text-neutral-600 text-center">
                      {/* {activity.lastActive} */}-
                    </p>
                  </div>
                  <div className="flex-1 flex-row items-center justify-center">
                    <p className="font-pn text-s text-neutral-600 text-center">
                      {/* {activity.lastPlayed} */}-
                    </p>
                  </div>
                  <div className="flex-1 flex-row items-center justify-center">
                    <p className="font-pn text-s text-neutral-600 text-center">
                      {/* {activity.favouriteStation} */}-
                    </p>
                  </div>
                  <div className="flex flex-1 flex-row items-center justify-center">
                    <button className="bg-purple-500 rounded-[8px] h-[40px] w-[100px] flex flex-col justify-center items-center" onClick={() => onPress(activity.name)}>
                      <p className="font-pnb text-p-m text-white text-center">
                        Play Music
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
