export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function getDeviceType(): "web" | "tablet" | "mobile" {
  const { width } = getWindowDimensions();
  if (width > 1440) {
    return "web";
  } else if (width > 1024) {
    return "tablet";
  } else {
    return "mobile";
  }
}
