import React, { CSSProperties, ReactNode } from 'react';

interface TextProps {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  [key: string]: any;
}

const Text: React.FC<TextProps> = ({ children, className = '', style, ...restProps }) => {
  // Default styles for the react-native Text component
  const defaultStyles = 'font-sans';

  // Check if the calssName prop has a font-* class
  const hasUserDefinedFont = className.split(' ').some((cls) => cls.startsWith('font-'));

  // If className doesn't have a font-* class, use the default font-sans
  const font = hasUserDefinedFont ? '' : defaultStyles;

  // Combine default and user-provided styles
  const combinedClassName = `${font} ${className}`;

  return (
    <p className={combinedClassName} style={style} {...restProps}>
      {children}
    </p>
  );
};

export default Text;
