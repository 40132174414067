//import { accessTokenAtom } from '../../../atoms/auth';
import { ReactComponent as Left} from '../../../svgs/Left.svg';
import { ReactComponent as DoubleDownArrow} from '../../../svgs/Player/DoubleDownArrow.svg';
import { ReactComponent as DoubleUpArrow} from '../../../svgs/Player/DoubleUpArrow.svg';
import { ReactComponent as Heart} from '../../../svgs/Player/Heart.svg';
import { ReactComponent as HeartGreyOutline} from '../../../svgs/Player/HeartGreyOutline.svg';
import { ReactComponent as HeartRed} from '../../../svgs/Player/HeartRed.svg';
import { ReactComponent as Next} from '../../../svgs/Player/Next.svg';
import { ReactComponent as Pause} from '../../../svgs/Player/Pause.svg';
import { ReactComponent as Play} from '../../../svgs/Player/Play.svg';
import { ReactComponent as Plus} from '../../../svgs/Player/Plus.svg';
import { ReactComponent as Prev} from '../../../svgs/Player/Prev.svg';
import { ReactComponent as Remove} from '../../../svgs/Player/Remove.svg';
import { ReactComponent as RemoveGreyOutline} from '../../../svgs/Player/RemoveGreyOutline.svg';
import { ReactComponent as Repeat} from '../../../svgs/Player/Repeat.svg';
import { ReactComponent as Shuffle} from '../../../svgs/Player/Shuffle.svg';
import { ReactComponent as Refresh} from '../../../svgs/Refresh.svg';
import { _delete, get, post, put } from '../../../utils/api';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useRef, useState,  } from 'react';

import 'shaka-player/dist/controls.css';
import ShakaPlayer from 'shaka-player-react'



function addTimes(times: (string|undefined)[]) {

  const z = (n: number) => (n < 10 ? '0' : '') + n;

  let hour = 0
  let minute = 0
  let second = 0
  for (const time of times) {
    if (!time) return undefined
      const splited = time.split(':');
      if (splited.length !== 3) {
        return undefined
      }
      hour += parseInt(splited[0] as string);
      minute += parseInt(splited[1] as string)
      second += parseInt(splited[2] as string)
  }
  //const seconds = second % 60
  const minutes = parseInt((minute % 60).toString()) + parseInt((second / 60).toString())
  const hours = hour + parseInt((minute / 60).toString())

  return {hours, minutes}
}

const updateTrackPreference = async ({id, isLike}: {id: string, isLike: boolean}) => {

  let response
  console.log("test", {isLike})
  if (isLike) {
    response = await post(`/tasteprofile/2/track/like?track_id=${id}`, new Headers(), undefined, false)
  } else {
    response = await _delete(`/tasteprofile/2/track/like?track_id=${id}`, new Headers(), undefined, false)
  }

  
  return response
}

const refreshTracks = async () => {
  let response = await put(
    `/station?tasteprofile_id=2&station_type=RELAX`,
    new Headers(),
    undefined,
    false)
    console.log({response})
    return response;
}

const TrackCard = ({track, selected, onPress}: {track: Track, selected: boolean, onPress: () => void}) => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: updateTrackPreference,
    onSuccess: () => {
      // Invalidate and refetch
      console.log("Success")
      queryClient.invalidateQueries({ queryKey: ['getStations'] })
    },
  })
  console.log(track.id)
  const removed = track.id === 'mh:track:0005e6984cc849d88e729c73045ee4e9-0'
  return <button className="flex flex-row items-center justify-center p-[8px] rounded-[4px]" style={selected ? {backgroundColor: '#F8F4FD'} : {borderBottomWidth: 1, borderColor: '#DFDEE0'}} onClick={onPress}>
    <div className="flex-1">
    <div className={`text-p-s ${ removed ? 'text-neutral-600' : 'text-vera-black'} font-pnsb`}>{track.title}</div>
    <div className={`text-p-xs ${ removed ? 'text-neutral-600' : 'text-vera-black'} font-pn`}>{track.artists[0]?.name}</div>
    </div>
    {removed ? <><Plus/><div className="text-p-s font-pn text-purple-500 ml-[8px]">Add Back</div></> : 
    <>
    <div className="w-[40px]">


    <Icon text={track.liked ? "Unlike" : "Like"} isSmall={true} isTextBlack={true} onPress={() => mutation.mutate({id: track.id, isLike: !track.liked})}/>
    </div>
    <div className="w-[16px]"></div>
    <Icon text="Remove" isSmall={true} isTextBlack={true}/>
    </>
    }
    </button>
}
const ProgressBar = ({percentage}: {percentage: string}) => {
    console.log({percentage})
  return <div className="w-full h-[4px] bg-white my-[8px] rounded-[4px]">
                  <div
                className="relative h-[4px] rounded-[4px] bg-purple-500"
                style={{ width: percentage === 'NaN%' ? 0 : percentage }}
              />
    </div>
}

const icons = {
  'Shuffle' : Shuffle,
  'Pause' : Pause,
  'Play' : Play,
  'Repeat': Repeat,
  'Like' : Heart,
  'Unlike' : HeartRed,
  'Remove': Remove,
  'Prev' : Prev,
  'Next' : Next,
}
const Icon = ({text, onPress, highlight, isSmall, isTextBlack, isHorizontal}: {text: keyof typeof icons, onPress?: () => void, highlight?:boolean, isSmall: boolean, isTextBlack?: boolean, isHorizontal?: boolean}) => {
  let SVG = icons[text]
  if (isTextBlack && text === 'Like') {
    SVG = HeartGreyOutline
  }

  if (isTextBlack && text === 'Remove') {
    SVG = RemoveGreyOutline
  }
  return <button onClick={onPress}  style={isHorizontal ? {display: 'flex', flexDirection: 'row', alignItems: 'flex-start'} : {display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    <div style={{backgroundColor: highlight ? '#7A23D3' : undefined, borderRadius: 4, marginRight: isHorizontal ? 4 : 0}}>
      <SVG style={isSmall ? {width: 36, height: 36, color: 'red'} : {}}/>
    </div>

    <div className={`${isSmall ? 'text-p-s' : 'text-p-l'} text-white font-pn text-center mt-[5px]`} style={isTextBlack ? {color: '#3E3B39'} : {}}>{text}</div>

  </button>
}

type Track =  {
  id: string;
  title?: string;
  duration?: string;
  dash_url?: string;
  hls_url?: string;
  genre_id?: string;
  liked?: boolean;
  artists: Artist[];
  release_year?: number;
  cover_image_low_res?: string;
  cover_image_high_res?: string;
};
type Artist = {
  id?: string;
  name?: string;
  main_genre_id?: string;
  artist_type?: string;
  liked?: boolean;
};
type Station = {
  id: string,
  name: string,
  tracks: Track[]
}

const Controls = ({isPause, setIsPause, fullscreen, setFullscreen, onPrev, onNext, liked, onPressLike}: {isPause: boolean, setIsPause: (b: boolean) => void, fullscreen: boolean, setFullscreen: (b: boolean) => void, onPrev: () => void, onNext: () => void, liked: boolean, onPressLike: () => void}) => {
  const [shuffle, setShuffle] = useState(false)
  const [repeat, setRepeat] = useState(false)
  return     (<><div className="flex flex-row items-center justify-between mt-[24px]">
  <Icon text="Shuffle" highlight={shuffle} onPress={() => {setShuffle(!shuffle); if (repeat) setRepeat(false)}} isSmall={!fullscreen}/>
  {fullscreen ? null : <Icon text="Prev" onPress={onPrev} isSmall={!fullscreen}/>}
  <div style={fullscreen ? {} : {width: 40}}><Icon text={isPause ? "Play" : 'Pause'} onPress={() => setIsPause(!isPause)} isSmall={!fullscreen}/></div>
  {fullscreen ? null : <Icon text="Next" onPress={onNext} isSmall={!fullscreen}/>}
  <Icon text="Repeat" highlight={repeat} onPress={() => {setRepeat(!repeat); if (shuffle) setShuffle(false)}} isSmall={!fullscreen}/>
</div>
{fullscreen ? <><div className="flex-1"/>
<div className="flex flex-row justify-evenly">
<Icon text={liked ? "Unlike" : "Like"} onPress={onPressLike} isSmall={!fullscreen}/>
<Icon text="Remove" isSmall={!fullscreen}/>
</div></> : null}
  <button className="flex bg-neutral-700 rounded-[8px] items-center justify-center flex-row mt-[8px]" onClick={() => setFullscreen(!fullscreen)}>
    {fullscreen ? <DoubleUpArrow/> : <DoubleDownArrow/>}
    <div className="text-p-s text-white font-pn text-center p-[6px]">{fullscreen ? 'Show songs' : 'Hide songs'}</div>
  </button>
</>)
}
export default function Player() {
  const [isPause, setIsPause] = useState(true)
  const [currTime, setCurrTime] = useState(0)
  const [totalTime, setTotalTime] = useState(0)

  //const [accessToken] = useAtom(accessTokenAtom)

  const [currTrack, setCurrTrack] = useState({index: -1, drmToken: ''})
  const getStations = async () => {

    const headers = new Headers();
    //headers.append('Authorization', 'Bearer ' + accessToken);
    const response = await get('/station?tasteprofile_id=2&station_type=RELAX', headers, false)
    return response as Station     
}

  const {data, isLoading, isError} = useQuery({ queryKey: ['getStations'], queryFn: getStations, 
  onSuccess: () => {if (currTrack.index === -1) startTrack(0) }
});
  console.log({data})
  const startTrack = async (index: number) => {

    let realIndex = 0
    const length = data?.tracks?.length
    if (length) {
      realIndex = index >= length || index <= 0 ? 0 : index
    }
    
    const id = data?.tracks[realIndex]?.id
    if (id) {
      const token = await get(`/track/license_token?drm_type=Widevine&tasteprofile_id=1&track_id=${id}`, new Headers(), false)
      setCurrTrack({index: realIndex, drmToken: token})
    }

  }


  const controllerRef = useRef<any>(null);

  useEffect(() => {
    if (!controllerRef.current) return
    const {
        /** @type {shaka.Player} */ player,
        /** @type {shaka.ui.Overlay} */ ui,
        /** @type {HTMLVideoElement} */ videoElement
      } = controllerRef.current;
    if (currTrack.index === -1 || !player || !data) return


  
      async function loadAsset() {
        // Load an asset.
        if (player) {
          console.log("Hello")
          const config = await player.configure({
            drm: {
              servers: {
                'com.widevine.alpha': 'https://license.pallycon.com/ri/licenseManager.do',
                'com.microsoft.playready': 'https://license.pallycon.com/ri/licenseManager.do'
              }
            }
          });
          console.log({config})
          const header = await player
          .getNetworkingEngine()
          .registerRequestFilter(function (type:any, request:any) {
          // Only add headers to license requests:
          //if (type == player.net.NetworkingEngine.RequestType.LICENSE) {
          // This is the specific header name and value the server wants:
          request.headers["pallycon-customdata-v2"] = currTrack.drmToken;
          //}
          })
          console.log({header})
          const url = data?.tracks[currTrack.index]?.dash_url
          //const url = "https://dev-vera-public-assets.s3.ap-southeast-2.amazonaws.com/sample-mp4-file.mp4"
          const response = await player.load(url);
          console.log({response})
  
          // Trigger play.
          videoElement.play();
          setIsPause(false)
        }

      }
  
      loadAsset();
  }, [currTrack, controllerRef])

  useEffect(() => {
    if (!controllerRef?.current?.videoElement) return
    const videoElement = controllerRef.current.videoElement;
    if(!videoElement) return
    if (isPause) {
        videoElement.pause()
    } else {
        videoElement.play()
    }
  }, [isPause])

  useEffect(() => {
    const interval = setInterval(() => {
        const videoElement = controllerRef.current.videoElement;
        console.log("Hello")
        if(!videoElement) return
        setCurrTime(videoElement.currentTime)
        setTotalTime(videoElement.duration)
    }, 2000);
    return () => clearInterval(interval);
  }, []);


  const getDuration = useMemo(() => {
    if (!data?.tracks) return 0
    let count = 0
    const times = data.tracks.map(t => t.duration)
    const result = addTimes(times)
    if (!result) return undefined
    let output = ''
    if (result.hours) {
      output += result.hours + ' hour'
      if (output !== '1') output += 's'
      output += ' '
    }
    output += result.minutes + ' minutes'
    return output
  }, [data])




  const [fullscreen, setFullscreen] = useState(false)
  const REACT_APP_PALLYCON_SITE_ID = 'GEMV'


    
    console.log({currTrack})
    const queryClient = useQueryClient()
    const mutation = useMutation({
      mutationFn: updateTrackPreference,
      onSuccess: () => {
        // Invalidate and refetch
        console.log("Success")
        queryClient.invalidateQueries({ queryKey: ['getStations'] })
      },
    })

    const refreshMutation = useMutation({
      mutationFn: refreshTracks,
      onSuccess: () => {
        console.log("Success");
        queryClient.invalidateQueries({ queryKey: ["getStations"] });
      }
    })  

    
    if (isLoading || data === undefined) return <div>Waiting</div>

    const handleFullscreenLike = () => mutation.mutate({id: data.tracks[currTrack.index]?.id || '', isLike: !data.tracks[currTrack.index]?.liked})
    const imageUri = 'https://musichealth-marketing-assets.s3.us-west-2.amazonaws.com/' + data.tracks[currTrack.index]?.cover_image_low_res
    console.log({imageUri})
    console.log({data})
    return <div className="flex flex-col w-full">
    <ShakaPlayer autoPlay ref={controllerRef} style={{width: 0, height: 0}}/>
    <div className="flex flex-col bg-vera-black p-[12px] m-[16px] rounded-[8px]" style={{flexGrow: fullscreen ? 1 : undefined}}>
    <div className="flex flex-row">
      <img className="w-[77px] h-[77px] mr-[12px]" src={imageUri}/>
      <div>

          <div className="flex bg-vera-green rounded-[12px] px-[12px] self-start mb-[12px]">
            <div className="flex text-p-xs font-pn text-white flex grow-0">{data.name}</div>
          </div>

          <div className="flex text-cta-d font-pn text-white">{data.tracks[currTrack.index]?.title}</div>
          <div className="flex text-p-s font-pn text-white">by {data.tracks[currTrack.index]?.artists[0]?.name}</div>

      </div>
      <div className="flex-1"/>
      {fullscreen ? null : <Icon text={!!data.tracks[currTrack.index]?.liked ? "Unlike" : "Like"} isSmall={true} onPress={handleFullscreenLike} isHorizontal={true}/>}
    </div>
    <ProgressBar percentage={Math.floor(100*currTime/totalTime) + '%'}/>
    <Controls liked={!!data.tracks[currTrack.index]?.liked} onPressLike={handleFullscreenLike} isPause={isPause} setIsPause={setIsPause} fullscreen={fullscreen} setFullscreen={setFullscreen} onPrev={() => startTrack(currTrack.index - 1)} onNext={() => startTrack(currTrack.index + 1)}/>




  
  </div>
  {fullscreen ? null : 
  <div className="flex flex-col flex-1 m-[16px] rounded-[8px] p-[8px]">
    <div className="flex flex-row">
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-center">
        <Left/>
        <div className="text-h5-d text-vera-black font-pnsb">{data.name}</div>
       </div>
        <div className="flex flex-row items-center">
          <div className="text-p-s text-vera-black font-pn">{data?.tracks.length} songs</div>
          <div className="w-[8px] h-[8px] mx-[8px] bg-vera-black"/>
          <div className="text-p-s text-vera-black font-pn">{getDuration}</div>
        </div>

      </div>
      <div className="flex flex-row items-center">
        <button className="flex flex-row items-center" onClick={()=>{refreshMutation.mutate()}}>
          <Refresh/>
          <div className="text-p-l text-purple-500 font-pnsb ml-[8px]">Refresh</div>
        </button>
      </div>

    </div>
        <div className="flex flex-col">
        {data?.tracks.map((t, i) => 
          <TrackCard track={t} key={t.id} selected={i === currTrack.index} onPress={() => startTrack(i)}/>
        )}
        </div>
        </div>
}
        
        </div>

}