import VeraModal from "../../components/Modal";
// import usedevice from '../../hooks/usedevice';
import { ReactComponent as Vera } from "../../svgs/vera.svg";

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { deviceTypeAtom } from "../../atoms/screenSize";
import { useAtom } from "jotai";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {REACT_APP_ENV} from '../../env'

const VideoScreen = () => {
  return (
    <div className="flex flex-1 mx-[10px] w-[90%] h-full justify-center items-center flex-col">
      <div className="h-[50px]"></div>
      <video controls width="100%">
        <source
          src={`https://vera-public-assets-${REACT_APP_ENV}.s3.ap-southeast-2.amazonaws.com/sample-mp4-file.mp4`}
          type="video/mp4"
        ></source>
      </video>
    </div>
  );
};

const Header = () => {
  return (
    <>
      <div className="mx-[20px] my-[40px] flex-row items-center">
        <Vera />
      </div>
    </>
  );
};
export default function LetsGetStart() {
  const navigate = useNavigate();
  //   const { width } = useWindowDimensions();
  const { width } = useWindowDimensions();

  //const device = usedevice();
  const [device] = useAtom(deviceTypeAtom);
  console.log({ device });
  const imageWidth = device === "mobile" ? width - 24 : width / 3;

  const [showModal, setShowModal] = React.useState(false);
  return (
    <div className=" flex w-full h-screen bg-red-200 items-center justify-center">
      <VeraModal
        visible={showModal}
        setVisible={() => setShowModal(!showModal)}
      >
        <VideoScreen />
      </VeraModal>
      {/* <Header /> */}
      <div
        className="flex-1 items-center justify-center"
        style={{
          marginLeft: device === "mobile" ? 8 : "7.5%",
          marginRight: device === "mobile" ? 8 : "7.5%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: device === "mobile" ? "column-reverse" : "row",
            alignItems: "center",
          }}
        >
          <div
            className="flex flex-1"
            style={{
              marginLeft: device === "mobile" ? "7.5%" : 0,
              marginRight: device === "mobile" ? "7.5%" : 0,
              justifyContent: device === "mobile" ? "center" : "center",
              alignItems: device === "mobile" ? "center" : "flex-start",
              flexDirection: "column",
            }}
          >
            <div
              className={`${
                device === "mobile" ? "div-h1-m" : "div-h1-d"
              } vera-black font-pnb my-[6px] text-center`}
            >
              Woohoo! Success
            </div>
            <div
              className={`${
                device === "mobile" ? "div-p-m" : "div-p-l"
              } vera-black font-pn my-[6px] div-center`}
            >
              {"Click the video if you'd like to div a quick demo of Vera"}
            </div>
            <button
              className="my-[12px] rounded-[8px] bg-purple-500 p-[14px]"
              style={{
                alignSelf: device === "mobile" ? "center" : "flex-start",
              }}
              onClick={() => navigate("/afterLogin")}
            >
              <div
                className={`${
                  device === "mobile" ? "div-b-m" : "div-cta-d"
                } font-pnb div-white`}
              >
                Start Exploring
              </div>
            </button>
          </div>
          <button
            style={{
              width: imageWidth,
              height: imageWidth / 2,
              backgroundColor: "gray",
              borderRadius: 8,
              maxWidth: device === "mobile" ? 300 : undefined,
              maxHeight: device === "mobile" ? 150 : undefined,
            }}
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>
    </div>
  );
}
