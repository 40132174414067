import { motion } from "framer-motion";
import LinearBackground from "./LinearBackground";
import { ReactComponent as YellowTick } from "../../../svgs/yellowtick.svg";

export default function LeftPane({
  isVeraPro,
  setIsVeraPro,
  dimension,
}: {
  isVeraPro: boolean;
  setIsVeraPro: (v: boolean) => void;
  dimension: { height: number; width: number };
}) {
  const HALFSCREENHEIGHT = dimension.height / 2;
  return (
    <>
      <div
        style={{
          height: "100vh",
        }}
        className="flex bg-background-100 flex-[5_5_0%] flex-col "
      >
        <LinearBackground type={isVeraPro} />
        <div
          className="pl-[48px] pr-[24px]"
          style={{
            marginTop: HALFSCREENHEIGHT - 150,
          }}
        >
          <p className="text-m text-vera-black font-pn ">
            Select your product:
          </p>
          <div className="flex mt-[8px] h-[53px] w-[337px] flex-row items-center justify-between rounded-lg border-[2px] border-neutral-400">
            <motion.div
              className="absolute h-[45px] w-[168px] justify-center rounded-lg bg-purple-500 pointer-events-none z-0"
              animate={{
                x: isVeraPro ? 160 : 5,
              }}
              transition={{
                type: "tween",
                duration: 0.2,
              }}
              style={{
                boxShadow: isVeraPro
                  ? "-3px 0px 4px rgba(0,0,0,0.25)"
                  : "3px 0px 4px rgba(0,0,0,0.25)",
              }}
            />
            <button
              onClick={() => setIsVeraPro(!isVeraPro)}
              className="h-[45px] w-[160px] justify-center rounded-lg z-10"
            >
              <p
                style={{ color: !isVeraPro ? "#FFF" : "#66646A" }}
                className="text-m font-pnb text-center text-neutral-700"
              >
                VERA
              </p>
            </button>
            <button
              onClick={() => setIsVeraPro(!isVeraPro)}
              className="h-[45px] w-[160px] justify-center rounded-lg z-10"
            >
              <p
                style={{ color: isVeraPro ? "#FFF" : "#66646A" }}
                className="text-m font-pnb text-center text-white"
              >
                VERA PRO
              </p>
            </button>
          </div>
          <p className="text-m text-vera-black font-pn mt-[24px] mr-[24px]">
            {isVeraPro
              ? "VeraPro is an evidence based music intervention tool that assists dementia caregivers in delivering more personalized and efficient support to all people in their care"
              : "Your trusted dementia caregiver tool that uses music to assist you with the activities of daily living that can help the person in your care with"}
          </p>
          <div className="flex mt-[12px] flex-row items-center">
            <YellowTick />
            <p className="font-pn text-m text-vera-black ml-[8px]">
              {isVeraPro ? "Easy to use" : "mood"}
            </p>
          </div>
          <div className=" flex mt-[8px] flex-row items-center">
            <YellowTick />
            <p className="font-pn text-m text-vera-black ml-[8px]">
              {isVeraPro
                ? "Manage multiple listener profiles"
                : "long term memory"}
            </p>
          </div>
          <div className="flex mt-[8px] flex-row items-center">
            <YellowTick />
            <p className="font-pn text-m text-vera-black ml-[8px]">
              {isVeraPro
                ? "Track wellbeing of people in your care"
                : "communication"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
