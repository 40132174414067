import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  ICognitoUserSessionData,
} from "amazon-cognito-identity-js";
import {
  REACT_APP_ENV,
  REACT_APP_VERA_PRO_USER_POOL_ID,
  REACT_APP_VERA_PRO_CLIENT_ID,
  REACT_APP_VERA_USER_POOL_ID,
  REACT_APP_VERA_CLIENT_ID,
  REACT_APP_VERADOMAIN,
  REACT_APP_RECOMMENDERDOMAIN,
} from "../env";

type LoginState =
  | "login"
  | "register"
  | "select"
  | "changePassword"
  | "verifyEmail";

//dev
const VERA_PRO_USER_POOL_ID = REACT_APP_VERA_PRO_USER_POOL_ID as string;
const VERA_PRO_CLIENT_ID = REACT_APP_VERA_PRO_CLIENT_ID as string;
const VERA_USER_POOL_ID = REACT_APP_VERA_USER_POOL_ID as string;
const VERA_CLIENT_ID = REACT_APP_VERA_CLIENT_ID as string;

// demo
// const VERA_PRO_USER_POOL_ID = "ap-southeast-2_Z5sCFNoxD";
// const VERA_PRO_CLIENT_ID = "2sehes8h5t7hs7a0d81tah6l3m";
// const VERA_USER_POOL_ID = "ap-southeast-2_hQEBCtQcn";
// const VERA_CLIENT_ID = "57ou8jvl5be5pl3uoc71nh3f8c";

const veraProUserPool = new CognitoUserPool({
  UserPoolId: VERA_PRO_USER_POOL_ID,
  ClientId: VERA_PRO_CLIENT_ID,
});

const veraUserPool = new CognitoUserPool({
  UserPoolId: VERA_USER_POOL_ID,
  ClientId: VERA_CLIENT_ID,
});

export const checkEmailValidation = (text: string) => {
  const reg =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return reg.test(text);
};
// Must contain at least one number, capital letter and have mOre than 8 characters
export const checkNewPasswordValid = (text: string) => {
  const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
  return reg.test(text);
};

const getCognitoUser = (isVeraPro: boolean, email: string) => {
  const userPool = isVeraPro ? veraProUserPool : veraUserPool;
  const userData = {
    Username: email,
    Pool: userPool,
  };
  return new CognitoUser(userData);
};
const SIGNUP_ERRORS = {
  "An account with the given email already exists.": "Account already exists",
};

export const signup = (
  isVeraPro: boolean,
  email: string,
  password: string,
  onSuccess: () => void,
  setErrorMessage: Function
) => {
  console.log("starting submit");

  if (!checkEmailValidation(email)) {
    setErrorMessage("Must be a valid email");
    return;
  }

  if (password.length < 8) {
    setErrorMessage("Password must be atleast 8 characters");
    return;
  }
  const userPool = isVeraPro ? veraProUserPool : veraUserPool;
  userPool.signUp(email, password, [], [], (err, res) => {
    if (err) {
      console.log();
      const message = err.message;
      if (err.message in SIGNUP_ERRORS) {
        // @ts-ignore
        setErrorMessage(SIGNUP_ERRORS[err.message]);
        return;
      }
      alert(err.message || JSON.stringify(err));
      return;
    } else {
      onSuccess();
    }
  });
};

const AUTHENTICATE_ERRORS = {
  "Incorrect username or password.": "Incorrect username or password",
};
export const authenticate = (
  isVeraPro: boolean,
  email: string,
  password: string,
  navigate: any,
  setIdToken: (s: string) => void,
  setAccessToken: (s: string) => void,
  setType: (t: LoginState) => void,
  setErrorMessage: Function
) => {
  console.log("authenticating");
  if (setErrorMessage && !checkEmailValidation(email)) {
    setErrorMessage("Must be a valid email");
    return;
  }

  if (setErrorMessage && password.length < 8) {
    setErrorMessage("Password must be atleast 8 characters");
    return;
  }

  const authenticationData = {
    Username: email,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const cognitoUser = getCognitoUser(isVeraPro, email);
  cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: async function (result) {
      const data = {
        IdToken: result.getIdToken(),
        AccessToken: result.getAccessToken(),
        RefreshToken: result.getRefreshToken(),
      };
      await setIdToken(result.getIdToken().getJwtToken());
      await setAccessToken(result.getAccessToken().getJwtToken());
      navigate("AfterLogin");
    },

    onFailure: function (err) {
      if (err.message === "User is not confirmed.") {
        setType("verifyEmail");
        return;
      }
      if (err.message in AUTHENTICATE_ERRORS) {
        // @ts-ignore
        setErrorMessage(AUTHENTICATE_ERRORS[err.message]);
        return;
      }
      alert(err.message || JSON.stringify(err));

      console.log({ err });
    },
  });
};

export const forgotPassword = (isVeraPro: boolean, email: string) => {
  console.log("forgotPassword", email);
  const cognitoUser = getCognitoUser(isVeraPro, email);
  cognitoUser.forgotPassword({
    onSuccess: function (data) {
      // successfully initiated reset password request
      console.log(
        "CodeDeliveryData from forgotPassword: " + JSON.stringify(data)
      );
    },
    onFailure: function (err) {
      alert(err.message || JSON.stringify(err));
    },
    //Optional automatic callback
  });
};

export const forgotPasswordNew = (
  isVeraPro: boolean,
  email: string,
  verificationCode: string,
  newPassword: string,
  setType: (t: LoginState) => void
) => {
  const cognitoUser = getCognitoUser(isVeraPro, email);
  cognitoUser.confirmPassword(verificationCode, newPassword, {
    onSuccess() {
      console.log("Password confirmed!");
      setType("login");
    },
    onFailure(err) {
      console.log("Password not confirmed!");
      alert(err.message || JSON.stringify(err));
    },
  });
};

export const socialMediaLogin = async (
  isVeraPro: boolean,
  type: "Google" | "Apple"
) => {
  let provider = "";
  if (type === "Apple") {
    provider = "SignInWithApple";
  } else {
    provider = type;
  }
  const currentUrl = window.location.href;
  let callbackUrl = currentUrl;
  callbackUrl = encodeURIComponent(callbackUrl);
  console.log(callbackUrl);

  const cognitoClientId = isVeraPro ? VERA_PRO_CLIENT_ID : VERA_CLIENT_ID;
  const link =
    `https://vera${
      isVeraPro ? "-pro" : ""
    }-user-pool-${REACT_APP_ENV}.auth.ap-southeast-2.amazoncognito.com/` +
    `oauth2/authorize?identity_provider=${provider}&client_id=${cognitoClientId}` +
    "&response_type=token&scope=email+openid&redirect_uri=" +
    callbackUrl;
  console.log(link);
  // eslint-disable-next-line no-restricted-globals
  location.replace(link);
};

export const confirmRegistration = async (
  isVeraPro: boolean,
  code: string,
  email: string,
  password: string,
  navigate: any,
  setIdToken: (s: string) => void,
  setAccessToken: (s: string) => void,
  setType: (t: LoginState) => void
) => {
  const cognitoUser = getCognitoUser(isVeraPro, email);
  await cognitoUser.confirmRegistration(code, true, function (err, result) {
    if (err) {
      alert(err.message || JSON.stringify(err));
      return;
    }
    console.log("call result: " + result);
    authenticate(
      isVeraPro,
      email,
      password,
      navigate,
      setIdToken,
      setAccessToken,
      setType,
      // @ts-ignore
      undefined
    );
    // authenticate(
    //   isVeraPro,
    //   email,
    //   password,
    //   navigate,
    //   setIdToken,
    //   setType,
    //   // @ts-ignore
    //   undefined
    // );
  });
};

// api functions copied from frontend/packages/api/fetch/index.tsx

const veraDomain = REACT_APP_VERADOMAIN;
const recommenderDomain = REACT_APP_RECOMMENDERDOMAIN;

export const post = async (
  path: string,
  headers: HeadersInit,
  body: BodyInit = "",
  isVeraDomain: boolean
) => {
  const url = (isVeraDomain ? veraDomain : recommenderDomain) + path;

  console.log({ url, body, headers });
  try {
    const response = await fetch(url, {
      method: "POST",
      body: body,
      headers: headers,
      redirect: "follow",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return { error };
  }
};

export const _delete = async (
  path: string,
  headers: HeadersInit,
  body: BodyInit = "",
  isVeraDomain: boolean
) => {
  const url = (isVeraDomain ? veraDomain : recommenderDomain) + path;

  console.log({ url, body, headers });
  try {
    const response = await fetch(url, {
      method: "DELETE",
      body: body,
      headers: headers,
      redirect: "follow",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return { error };
  }
};

export const put = async (
  path: string,
  headers: HeadersInit,
  body: BodyInit = "",
  isVeraDomain: boolean
) => {
  const url = (isVeraDomain ? veraDomain : recommenderDomain) + path;

  try {
    const response = await fetch(url, {
      method: "PUT",
      body: body,
      headers: headers,
      redirect: "follow",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return { error };
  }
};

export const get = async (
  path: string,
  headers: Headers,
  isVeraDomain: boolean
) => {
  const url = (isVeraDomain ? veraDomain : recommenderDomain) + path;
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  console.log({ url });
  try {
    const response = await fetch(url, requestOptions);

    const data = await response.json();
    return data;
  } catch (error) {
    return { error };
  }
};

export const patch = async (
  path: string,
  headers: HeadersInit,
  isVeraDomain: boolean,
  body: BodyInit = ""
) => {
  const url = (isVeraDomain ? veraDomain : recommenderDomain) + path;
  console.log("patchpatch");
  console.log({ url, body, headers });
  try {
    const response = await fetch(url, {
      method: "PATCH",
      body: body,
      headers: headers,
      redirect: "follow",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return { error };
  }
};
