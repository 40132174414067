import { useAtom } from "jotai";
import CardList from "./CardList";
import { Dispatch, SetStateAction, useState } from "react";
import useIsVeraPro from "../hooks/useIsVeraPro";
import { get } from "../utils/api";
import { useQuery } from "@tanstack/react-query";
import { accessTokenAtom, userAtom } from "../atoms/auth";

export default function GenresList() {
  const genres = [
    "Blues",
    "Classical",
    "Country",
    "Jazz",
    "Musical",
    "Pop",
    "Reggae",
    "Rock",
    "World",
    "Soul/R&B",
  ];

  const [user] = useAtom(userAtom);
  const isVeraPro = useIsVeraPro();

  const getTasteProfile = async () => {
    if (user && isVeraPro) {
      const headers = new Headers();
      const response = await get(
        "/tasteprofile/" + user.id + "/genres",
        headers,
        false
      );
      return response;
    }
    return [];
  };

  const { data, isLoading } = useQuery({
    queryKey: ["getGenres"],
    queryFn: getTasteProfile,
  });

  console.log(data);

  if (isLoading) return <div></div>;

  return (
    <CardList
      content={genres.map((g) => {
        return { name: g };
      })}
      type="cardPlus"
      setValue={() => null}
      liked={data.liked}
      removed={data.removed}
    />
  );
}
