import React, { CSSProperties, ReactNode } from 'react';

interface ModalProps {
  visible?: boolean;
  transparent?: boolean;
  children?: ReactNode;
  style?: CSSProperties;
  onRequestClose?: () => void;
}

export const Modal = ({ visible, transparent, children, style, onRequestClose }: ModalProps) => {
  if (!visible) {
    return null;
  }

  return (
    <div style={{ ...styles.modal, ...(transparent ? styles.transparent : {}), ...style }}>
        {children}
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  transparent: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  content: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
  },
};


