import { atom } from "jotai";

export const listenerAtom = atom({
  name: "",
  location: "test",
  gender: "test",
  birth_year: 1000,
  home_id: 0,
  taste_profile_id: 3,
});

export const activeListenerAtom = atom<{ name: string } | undefined>(undefined);
