import { idTokenAtom } from "../atoms/auth";
import { useAtom } from "jotai";
import jwt_decode from "jwt-decode";
import { useMemo } from "react";

export default function useTokenId() {
  const [tokenId, setTokenId] = useAtom(idTokenAtom);
  console.log({ tokenIdHook: tokenId });
  const idToken = useMemo(
    () => (tokenId ? jwt_decode(tokenId) : {}),
    [tokenId]
  );
  return idToken as { email: string };
}
