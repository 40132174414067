export default function LinearBackground(props: { type: boolean }) {
  return (
    <>
      {props.type ? (
        <div
          style={{
            width: `${(100 * 5) / 18}%`,
            background:
              "linear-gradient(90deg, rgba(174, 238, 223, 0.19) 0%, rgba(11, 205, 159, 0.57) 73.44%)",
          }}
          className="absolute h-full opacity-50 blur-[150px] z-10"
        />
      ) : (
        <div
          style={{
            width: `${(100 * 5) / 18}%`,
            background:
              "linear-gradient(90deg, rgba(211, 195, 228, 0.0691875) 0%, rgba(211, 195, 228, 0.0691875) 0.01%, rgba(83, 20, 149, 0.27) 72.92%)",
          }}
          className="absolute h-full opacity-50 blur-[150px] z-10"
        />
      )}
    </>
  );
}
