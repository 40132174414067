import { ReactComponent as StartListening } from "../../../svgs/StartListening.svg";
import { ReactComponent as WatchVideo } from "../../../svgs/WatchVideo.svg";
import React from "react";
import VideoPill from "../../../components/VideoPill";
import VideoModal from "../../../components/VideoModal";
import { useAtom } from "jotai";
import { dimensionAtom } from "../../../atoms/screenSize";
import Header from "./Header";

const MusicStation = ({
  name,
  color,
  backgroundColor,
  subTitle,
  onPressFn,
}: {
  name: string;
  color: string;
  subTitle: string;
  backgroundColor: string;
  onPressFn: () => void;
}) => {
  return (
    <div
      className="flex flex-1 flex-col bg-white rounded-[8px] h-full"
      style={{
        boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
        background: backgroundColor,
      }}
    >
      <div className="flex flex-[3_3_0%] flex-row items-center justify-center">
        <StartListening style={{ color: color }} />
        <div className="mr-[16px]" />
        <p className="font-pnb text-h4-d" style={{ color: color }}>
          {"Start\nListening"}
        </p>
      </div>
      <div className="flex flex-[2_2_0%] flex-col px-[32px]">
        <p className="font-pnb text-h3-d">{name}</p>
        <p className="font-pn text-l text-vera-black">{subTitle}</p>
      </div>
    </div>
  );
};

export default function MusicStations() {
  const [showModal, setShowModal] = React.useState(false);
  const [dimension] = useAtom(dimensionAtom);
  return (
    <div className="flex flex-1 flex-col">
      <div
        style={{ boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)" }}
        className="rounded-[8px] flex flex-[4_4_0%]"
      >
        <Header name={"Jake"} />
      </div>
      <div className="mb-[16px]" />
      <div
        className="fixed"
        style={{
          width: (dimension.width / 29) * 24 - 48,
          height: dimension.height,
        }}
      >
        <VideoModal showModal={showModal} setShowModal={setShowModal} />
      </div>
      <div
        className="flex flex-col flex-[16_16_0%] rounded-[8px]"
        style={{
          width: (dimension.width / 29) * 24 - 48,
        }}
      >
        <p className="font-pnb text-h6-d text-neutral-600">Music Stations</p>
        <div className="mb-[16px]" />
        <div className="flex flex-1 flex-row items-center justify-start">
          <MusicStation
            name="Relax"
            backgroundColor="linear-gradient(70deg, rgba(174, 238, 223, 0.1) 70%, rgba(11, 205, 159, 0.2) 100%)"
            color="#0BCD9F"
            subTitle="For someone feeling agitated, stressed or in an adverse mood"
            onPressFn={() => {}}
          />
          <div className="mr-[10px]" />
          <MusicStation
            name="Reminisce"
            backgroundColor="linear-gradient(70deg, rgba(211, 195, 228, 0.0691875) 70%, rgba(83, 20, 149, 0.2) 100%)"
            color="#7A23D3"
            subTitle="For someone feeling confused or in a brain fog"
            onPressFn={() => {}}
          />
          <div className="mr-[10px]" />
          <MusicStation
            name="Energize"
            color="#FDC453"
            backgroundColor="linear-gradient(70deg, rgba(254, 229, 180, 0.1) 70%, rgba(253, 196, 83, 0.3) 100%)"
            subTitle="For someone feeling low on energy or lethargic"
            onPressFn={() => {}}
          />
        </div>
        <div className="mb-[16px]" />
        <div className="flex flex-1">
          <div
            className="flex flex-1 flex-col bg-white rounded-[8px] p-[16px]"
            style={{
              boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
              width: (dimension.width / 29) * 24 - 48,
            }}
          >
            <p className="font-pnb text-h6-m">How to use Vera</p>
            <div className="mb-[16px]" />
            {!showModal && (
              <div className="flex flex-1 overflow-x-auto whitespace-nowrap">
                <VideoPill
                  onPressFn={() => {
                    setShowModal(true);
                  }}
                />
                <VideoPill
                  onPressFn={() => {
                    setShowModal(true);
                  }}
                />
                <VideoPill
                  onPressFn={() => {
                    setShowModal(true);
                  }}
                />
                <VideoPill
                  onPressFn={() => {
                    setShowModal(true);
                  }}
                />
                <VideoPill
                  onPressFn={() => {
                    setShowModal(true);
                  }}
                />
                <VideoPill
                  onPressFn={() => {
                    setShowModal(true);
                  }}
                />
                <VideoPill
                  onPressFn={() => {
                    setShowModal(true);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
