import React, { useRef, useState } from "react";

type ButtonType = {
  type: "outline" | "filled";
  text: string;
  isReady?: boolean;
  onPressFn: () => void;
  dark?: boolean;
  style?: React.CSSProperties;
  onLongPress?: () => void;
};

export default function Button(props: ButtonType) {
  if (props.type === "outline") {
    return (
      <>
        <button
          onClick={props.onPressFn}
          className="flex h-[56px] w-[165px] flex-row items-center justify-center rounded-[8px] border-[1px] border-purple-500"
          style={{
            borderColor: props.dark ? "#3E3B39" : "#7A23D3",
          }}
        >
          <p
            className="font-pnb text-xl text-purple-500"
            style={{
              borderColor: props.dark ? "#3E3B39" : "#7A23D3",
            }}
          >
            {props.text}
          </p>
        </button>
      </>
    );
  }
  return (
    <>
      <button
        disabled={!props.isReady}
        onClick={props.onPressFn}
        className="flex h-[56px] w-[165px] flex-row items-center justify-center rounded-[8px]"
        style={{
          backgroundColor: props.isReady ? "#7A23D3" : "#EDECED",
          ...props.style,
        }}
      >
        <p
          className="font-pnb text-xl"
          style={{ color: props.isReady ? "#FFF" : "#66646A" }}
        >
          {props.text}
        </p>
      </button>
    </>
  );
}
