import { useAtom } from "jotai";
import React, { ReactElement, ReactNode } from "react";
import { deviceTypeAtom, dimensionAtom } from "../../../atoms/screenSize";
import { userTypeAtom } from "../../../atoms/auth";
import Footer from "./Footer";
import Header from "./Header";

interface RequireType {
  children: ReactNode | ReactElement;
  canProgress: boolean;
  currentProcess: number;
  onNext: () => void;
}

export default function OnboardWrapper({
  children,
  canProgress,
  currentProcess,
  onNext,
}: RequireType) {
  const [device] = useAtom(deviceTypeAtom);
  const [dimension] = useAtom(dimensionAtom);
  const [userType] = useAtom(userTypeAtom);

  return (
    <>
      <div
        className="flex flex-1 flex-col justify-between bg-white"
        style={{
          height: dimension.height,
        }}
      >
        <div
          style={{
            paddingLeft: device !== "mobile" ? 62 : 0,
            paddingRight: device !== "mobile" ? 62 : 0,
          }}
        >
          <Header
            total={userType === "HomeAdmin" || userType === "Caregiver" ? 2 : 4}
            current={currentProcess}
          />
        </div>
        <div style={{ marginBottom: device !== "mobile" ? 32 : 0 }} />
        <div className={`flex flex-1 flex-row`}>
          <div
            className={`${device === "tablet" && "flex-[3_3_0%]"} ${
              device === "web" && "flex-[6_6_0%]"
            }`}
          />
          <div
            style={{
              flex: device === "tablet" ? 13 : device === "web" ? 12 : 1,
              marginLeft: device !== "mobile" ? 0 : 20,
              marginRight: device !== "mobile" ? 0 : 20,
            }}
          >
            {children}
          </div>
          <div
            style={{
              flex: device === "tablet" ? 5 : device === "web" ? 12 : 0,
            }}
          />
        </div>
        <Footer canProgress={canProgress} onNext={onNext} />
      </div>
    </>
  );
}
