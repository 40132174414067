import DropDown from "../../components/DropDown";
import TextInputForm from "./components/TextInputForm";
import React, { Dispatch, useEffect, useState } from "react";
import OnboardWrapper from "./components/OnboardWrapper";
import { getCountry } from "../../utils/api";
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { deviceTypeAtom } from "../../atoms/screenSize";
import VeraModal from "../../components/Modal";
import { userTypeAtom } from "../../atoms/auth";
import { useNavigate } from "react-router-dom";

type Country = {
  name: string;
};

const HomeAdminContent = ({
  setContentFilled,
  setShowModal,
}: {
  setContentFilled: Dispatch<React.SetStateAction<boolean>>;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [text, setText] = useState("");
  const [country, setCountry] = useState("");
  const { data, isLoading, isError } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountry,
  });

  useEffect(() => {
    if (text !== "" && country !== "") {
      setContentFilled(true);
    } else {
      setContentFilled(false);
    }
  }, [text, country, setContentFilled]);
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";

  if (isLoading) {
    return <></>;
  }

  if (isError) {
    alert(
      "Something went wrong on our end. Please try again later or contact support"
    );
  }

  return (
    <>
      <p className={`${isPhone ? "text-h6-m" : "text-h6-d"} font-pnb`}>
        What is your name?*
      </p>
      <div className="mb-[10px]" />
      <TextInputForm
        value={text}
        setValue={setText}
        placeHolder={"E.g. John Doe"}
      />
      <div className="mb-[32px]" />
      <p className={`${isPhone ? "text-h6-m" : "text-h6-d"} font-pnb`}>
        Where Are you Located
      </p>
      <div className="mb-[10px]" />
      <DropDown
        content={isError ? [] : data}
        placeHolder={"Select country"}
        setValue={setCountry}
      />
      <div className="mb-[10px]" />
      <p className={`${isPhone ? "text-p-m" : "text-p-l"} text-vera-black`}>
        {
          "Vera is currently available in AU, NZ, UK, US. If you are in another country\n we'd be happy to notify you when we launch there. "
        }
        <button onClick={() => setShowModal(true)}>
          <p className="text-purple-500">Notify me</p>
        </button>
      </p>
    </>
  );
};

const CaregiverContent = ({
  setContentFilled,
}: {
  setContentFilled: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [text, setText] = useState("");

  useEffect(() => {
    if (text !== "") {
      setContentFilled(true);
    } else {
      setContentFilled(false);
    }
  }, [text, setContentFilled]);
  const [device] = useAtom(deviceTypeAtom);

  return (
    <>
      <p
        className={`${
          device === "mobile" ? "text-h6-m" : "text-h6-d"
        } font-pnb`}
      >
        What is your name?*
      </p>
      <div className="mb-[10px]" />
      <TextInputForm
        value={text}
        setValue={setText}
        placeHolder={"E.g. John Doe"}
      />
    </>
  );
};

const ListenerContent = ({
  setContentFilled,
  setShowModal,
}: {
  setContentFilled: Dispatch<React.SetStateAction<boolean>>;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [text, setText] = useState("");
  const [country, setCountry] = useState("");
  const { data, isLoading, isError } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountry,
  });

  useEffect(() => {
    if (text !== "" && country !== "") {
      setContentFilled(true);
    } else {
      setContentFilled(false);
    }
  }, [text, country, setContentFilled]);
  const [device] = useAtom(deviceTypeAtom);

  if (isLoading) {
    return <></>;
  }

  if (isError) {
    alert(
      "Something went wrong on our end. Please try again later or contact support"
    );
  }

  return (
    <>
      <p
        className={`${
          device === "mobile" ? "text-h6-m" : "text-h6-d"
        } font-pnb`}
      >
        Where are you located?
      </p>
      <div className="mb-[10px]" />
      <DropDown
        content={data === undefined ? [{ name: "" }] : data}
        placeHolder={"Select country"}
        setValue={setCountry}
      />
      <div className="mb-[10px]" />
      <span
        className={`${
          device === "mobile" ? "text-p-m" : "text-p-l"
        } text-vera-black`}
      >
        {
          "Vera is currently available in AU, NZ, UK, US. If you are in another country\n we'd be happy to notify you when we launch there. "
        }
        <span className="text-purple-500">Notify me</span>
      </span>
      <div className="mb-[32px]" />
      <p
        className={`${
          device === "mobile" ? "text-h6-m" : "text-h6-d"
        } font-pnb`}
      >
        Name of Listener
      </p>
      <div className="mb-[10px]" />
      <TextInputForm
        value={text}
        setValue={setText}
        placeHolder={"Name of Listener"}
      />
      <div className="mb-[10px]" />
      <p
        className={`${
          device === "mobile" ? "text-p-m" : "text-p-l"
        } text-vera-black`}
      >
        {
          "Please insert the name of the person you are caring for and would like to\n use the music with"
        }
      </p>
    </>
  );
};

const Content = ({
  setContentFilled,
  setShowModal,
}: {
  setContentFilled: Dispatch<React.SetStateAction<boolean>>;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [userType] = useAtom(userTypeAtom);
  const [device] = useAtom(deviceTypeAtom);

  return (
    <>
      <p
        className={`${
          device === "mobile" ? "text-h1-m" : "text-h1-d"
        } font-pnb`}
      >
        Let's get started!
      </p>
      <div className="mb-[32px]" />
      {userType === "HomeAdmin" ? (
        <HomeAdminContent
          setContentFilled={setContentFilled}
          setShowModal={setShowModal}
        />
      ) : userType === "Caregiver" ? (
        <CaregiverContent setContentFilled={setContentFilled} />
      ) : (
        <ListenerContent
          setContentFilled={setContentFilled}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

const NotifyMeModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";

  return (
    <VeraModal visible={showModal} setVisible={setShowModal}>
      <>
        <div className="mt-[20px]" />
        <div className="flex flex-row">
          {!isPhone && <div className="flex flex-1" />}
          <div className="flex flex-[2_2_0%] flex-col items-center justify-center p-[42px]">
            <p
              className={`font-pnb text-center ${
                isPhone ? "text-p-l text-center" : "text-h4-d"
              }`}
            >
              We will be in touch as soon as VeraPro is available in your
              country
            </p>
            <div className="mb-[32px]" />
            <div className="mb-[12px]" />
            <p
              className={`font-pn text-center ${
                isPhone ? "text-p-l" : "text-h4-d"
              }`}
            >
              Thank you,
            </p>
            <p
              className={`font-pn text-center ${
                isPhone ? "text-p-l" : "text-h4-d"
              }`}
            >
              The Vera Team
            </p>
          </div>
          {!isPhone && <div className="flex flex-1" />}
        </div>
      </>
    </VeraModal>
  );
};

export default function LetsGetStart() {
  const [contentFilled, setContentFilled] = useState(false);
  const [userType] = useAtom(userTypeAtom);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {showModal && (
        <NotifyMeModal showModal={showModal} setShowModal={setShowModal} />
      )}
      <OnboardWrapper
        canProgress={contentFilled}
        currentProcess={0}
        onNext={() => {
          navigate(
            `/${userType === "Listener" ? "MoreDetails" : "HealthCareType"}`
          );
        }}
      >
        <Content
          setContentFilled={setContentFilled}
          setShowModal={setShowModal}
        />
      </OnboardWrapper>
    </>
  );
}
