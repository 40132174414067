import { useState } from "react";
import { ReactComponent as Vera } from "../../../svgs/vera.svg";
import InputForm from "../../../components/inputForm";
import { forgotPassword, forgotPasswordNew } from "../../../utils/auth";
import {
  checkEmailValidation,
  checkNewPasswordValid,
} from "../../../utils/regex";

export default function ChangePassword(props: {
  setType: (s: LoginState) => void;
  email: string;
  setEmail: (v: string) => void;
  isVeraPro: boolean;
  device: "mobile" | "tablet" | "web";
}) {
  const [email, setEmail] = useState(props.email);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
  return (
    <div
      className="flex flex-[7_7_0%] flex-col pr-[48px] justify-center"
      style={{
        height: "100vh",
        justifyContent: props.device !== "mobile" ? "center" : "normal",
      }}
    >
      <p className="font-pnb text-xxl">Change Password</p>
      <div className="mb-[6px]" />
      <p className="font-pn text-s text-vera-black">
        {isEmailConfirmed
          ? "Insert the code we've sent to your email and then create a new password"
          : "Enter your email below and we will send you a reset code with instructions"}
      </p>
      {isEmailConfirmed ? (
        <>
          <div className="mb-[32px]" />
          <InputForm type={"codeLong"} value={code} setValue={setCode} />
          <InputForm
            type={"New password"}
            value={password}
            setValue={setPassword}
          />
          <div className="mb-[16px]" />
          <div>
            <button
              disabled={!checkNewPasswordValid(password)}
              onClick={() => {
                props.setEmail(email);
                forgotPasswordNew(
                  props.isVeraPro,
                  email,
                  code,
                  password,
                  props.setType
                );
                setIsEmailConfirmed(true);
              }}
              style={{
                backgroundColor: checkNewPasswordValid(password)
                  ? "#7A23D3"
                  : "#DFDEE0",
              }}
              className="flex mt-[6px] h-[50px] w-full flex-col items-center justify-center rounded-lg border-[1px] border-neutral-200 bg-neutral-200"
            >
              <p
                style={{
                  color: checkNewPasswordValid(password) ? "#FFF" : "#000",
                }}
                className="text-vera-black font-pnb text-xl"
              >
                CONFIRM
              </p>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="mb-[131px]" />
          <InputForm type={"email"} value={email} setValue={setEmail} />
          <div className="mb-[16px]" />
          <div>
            <button
              disabled={!checkEmailValidation(email)}
              onClick={() => {
                props.setEmail(email);
                setIsEmailConfirmed(true);
                forgotPassword(props.isVeraPro, email);
              }}
              style={{
                backgroundColor: checkEmailValidation(email)
                  ? "#7A23D3"
                  : "#DFDEE0",
              }}
              className="flex mt-[6px] h-[50px] w-full flex-col items-center justify-center rounded-lg border-[1px] border-neutral-200 bg-neutral-200"
            >
              <p
                style={{
                  color: checkEmailValidation(email) ? "#FFF" : "#000",
                }}
                className="text-vera-black font-pnb text-xl"
              >
                CHANGE PASSWORD
              </p>
            </button>
          </div>
        </>
      )}
      <div className="mb-[16px]" />
      <div>
        <button
          onClick={() => {
            props.setEmail(email);
            props.setType("login");
          }}
          className="flex mt-[6px] h-[50px] w-full flex-col items-center justify-center rounded-lg border-[1px] border-neutral-200"
        >
          <p className="text-vera-black font-pnb text-xl">CANCEL</p>
        </button>
      </div>
    </div>
  );
}
