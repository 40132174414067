import DropDown from "../../components/DropDown";
import React, { Dispatch, useEffect, useState } from "react";
import OnboardWrapper from "./components/OnboardWrapper";
import { useAtom } from "jotai";
import { deviceTypeAtom } from "../../atoms/screenSize";
import { useNavigate } from "react-router-dom";

const Content = ({
  setCanProgress,
}: {
  setCanProgress: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [country, setCountry] = useState("");
  const [language, setLanguage] = useState("");
  const [secondCountry, setSecondCountry] = useState("");
  const [device] = useAtom(deviceTypeAtom);

  useEffect(() => {
    setCanProgress(country !== "" && language !== "" && secondCountry !== "");
  }, [country, language, secondCountry, setCanProgress]);

  return (
    <>
      <p
        className={`${
          device === "mobile" ? "text-h1-m" : "text-h1-d"
        } font-pnb`}
      >
        Almost there
      </p>
      <p
        className={`${
          device === "mobile" ? "text-p-m" : "text-p-l"
        } text-vera-black`}
      >
        {
          "Understanding their background helps Vera find the right music for them"
        }
      </p>
      <div className="mb-[32px]" />
      <p
        className={`${
          device === "mobile" ? "text-h6-m" : "text-h6-d"
        } font-pnb`}
      >
        Where were they born
      </p>
      <div className="mb-[10px]" />
      <DropDown
        content={[{ name: "1" }]}
        placeHolder={"Select country"}
        setValue={setCountry}
      />
      <div className="mb-[32px]" />
      <p
        className={`${
          device === "mobile" ? "text-h6-m" : "text-h6-d"
        } font-pnb`}
      >
        What is their first language?
      </p>
      <div className="mb-[10px]" />
      <DropDown
        content={[{ name: "1" }]}
        placeHolder={"Select language"}
        setValue={setLanguage}
      />
      <div className="mb-[32px]" />
      <p
        className={`${
          device === "mobile" ? "text-h6-m" : "text-h6-d"
        } font-pnb`}
      >
        Where did they live most of the time during the age of 14 - 35?
      </p>
      <div className="mb-[10px]" />
      <DropDown
        content={[{ name: "1" }]}
        placeHolder={"Select country"}
        setValue={setSecondCountry}
      />
    </>
  );
};

export default function LetsGetStart() {
  const [canProgress, setCanProgress] = useState(false);
  const navigate = useNavigate();
  return (
    <OnboardWrapper
      canProgress={canProgress}
      currentProcess={2}
      onNext={() => {
        navigate("/Genres");
      }}
    >
      <Content setCanProgress={setCanProgress} />
    </OnboardWrapper>
  );
}
