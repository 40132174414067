import React, { Dispatch, ReactElement, ReactNode, useState } from 'react';
import Button from '../components/Button';
import { ReactComponent as Angry } from '../svgs/moods/Angry.svg'
import { ReactComponent as Happy } from '../svgs/moods/Happy.svg'
import { ReactComponent as Sad } from '../svgs/moods/Sad.svg'
import { ReactComponent as Calm }from '../svgs/moods/Calm.svg'
import { deviceTypeAtom } from '../atoms/screenSize';
import { useAtom } from 'jotai';

interface RequireType {
  setVisible: Dispatch<React.SetStateAction<boolean>>;
}




const EMOTIONS = {
  'Angry' : {
    component: Angry,
    color: '#FCA5A5',
  },
  'Happy' : {
    component: Happy,
    color: '#FED687',
  },
  'Sad' : {
    component: Sad,
    color: '#C4C3C6',
  },
  'Calm' : {
    component: Calm,
    color: '#73F8D8',
  }
}
type Emotion = keyof typeof EMOTIONS | ''
const EmotionCard = ({emotion, selectedEmotion, setSelectedEmotion} : {emotion: Exclude<Emotion, ''>, selectedEmotion: Emotion, setSelectedEmotion: (s: Emotion) => void}) => {
  const [device] = useAtom(deviceTypeAtom);
  
  const Emotion = EMOTIONS[emotion].component
  return (
    <button onClick={() => setSelectedEmotion(emotion === selectedEmotion ? '' : emotion)} className="flex flex-col border-[0.5px] border-neutral-300 rounded-[20px] items-center p-[20px] m-[6px]" style={{backgroundColor: emotion === selectedEmotion ? EMOTIONS[emotion].color : 'white', width: device === 'mobile' ? 160 : 200, height: device === 'mobile' ? 180 : 200}}>
      <Emotion/>
      <div className="flex-1"/>
      <div className="text-b-l text-vera-black font-pnsb">{emotion}</div>
    </button>
  )
}


export default function Mood({setVisible}: RequireType) {
  const [selectedEmotion, setSelectedEmotion] = useState<Emotion>('')
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === 'mobile';
  return (
    <>
        <div className="flex h-screen w-screen bg-background-dark flex flex-1 flex-col items-center justify-center">
          <div className="flex flex-col bg-white py-[24px] grow-0 items-center" style={{width: device === 'mobile' ? '100%' : 608, height: device === 'mobile' ? '100%' : undefined, borderRadius: device === 'mobile' ? 0 : 12}}>

                <div className="text-vera-black text-h3-m font-pnb text-h3-m text-left w-full" style={{marginLeft: device === 'mobile' ? 50 : 200, marginTop: device === 'mobile' ? 20 : 0}}>{'How is LISTENER\nfeeling today?'}</div>
                <div className="flex flex-row justify-center mt-[24px]">
                <EmotionCard emotion={'Angry'} selectedEmotion={selectedEmotion} setSelectedEmotion={setSelectedEmotion}/>
                <EmotionCard emotion={'Happy'} selectedEmotion={selectedEmotion}  setSelectedEmotion={setSelectedEmotion}/>
                </div>
                <div className="flex flex-row justify-center mb-[24px]">
                <EmotionCard emotion={'Sad'} selectedEmotion={selectedEmotion}  setSelectedEmotion={setSelectedEmotion}/>
                <EmotionCard emotion={'Calm'} selectedEmotion={selectedEmotion}  setSelectedEmotion={setSelectedEmotion}/>
                </div>
                <Button text="Play Music" onPressFn={() => setVisible(false)} type='filled' style={{width : 343}} isReady={selectedEmotion !== ''}/>
                <button onClick={() => setVisible(false)}>
                  <div className="text-purple-500 text-p-l font-pn mt-[10px]">Skip for now</div>
                </button>
            </div>

        </div>
    </>
  );
}
