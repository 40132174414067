import React, { CSSProperties, ReactNode } from 'react';

interface ViewProps {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  [key: string]: any;
}

const View: React.FC<ViewProps> = ({ children, className = '', style, ...restProps }) => {
  // Default styles for the react-native View component
  const defaultStyles = 'flex';

  // Check if className has a flex-direction class
  const hasUserDefinedFlexDirection = className.split(' ').some((cls) =>
    ['flex-row', 'flex-row-reverse', 'flex-col', 'flex-col-reverse'].includes(cls)
  );

  // If classNAme doesn't have a flex-direction class use the default
  const flexDirection = hasUserDefinedFlexDirection ? '' : 'flex-col';

  // Combine default and user-provided styles
  const combinedClassName = `${defaultStyles} ${flexDirection} ${className}`;

  return (
    <div className={combinedClassName} style={style} {...restProps}>
      {children}
    </div>
  );
};

export default View;
