import { REACT_APP_VERADOMAIN, REACT_APP_RECOMMENDERDOMAIN } from "../env";

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const getCountry = async () => {
  await delay(1000);
  return [{ name: "Australia" }, { name: "New Zealand" }];
};

export const getLanguages = async () => {
  await delay(1000);
  return [{ name: "English" }, { name: "Italian" }];
};

const veraDomain = REACT_APP_VERADOMAIN;
const recommenderDomain = REACT_APP_RECOMMENDERDOMAIN;

export const post = async (
  path: string,
  headers: HeadersInit,
  body: BodyInit = "",
  isVeraDomain: boolean
) => {
  const url = (isVeraDomain ? veraDomain : recommenderDomain) + path;

  console.log({ url, body, headers });
  try {
    const response = await fetch(url, {
      method: "POST",
      body: body,
      headers: headers,
      redirect: "follow",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return { error };
  }
};

export const _delete = async (
  path: string,
  headers: HeadersInit,
  body: BodyInit = "",
  isVeraDomain: boolean
) => {
  const url = (isVeraDomain ? veraDomain : recommenderDomain) + path;

  console.log({ url, body, headers });
  try {
    const response = await fetch(url, {
      method: "DELETE",
      body: body,
      headers: headers,
      redirect: "follow",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return { error };
  }
};

export const put = async (
  path: string,
  headers: HeadersInit,
  body: BodyInit = "",
  isVeraDomain: boolean
) => {
  const url = (isVeraDomain ? veraDomain : recommenderDomain) + path;

  try {
    const response = await fetch(url, {
      method: "PUT",
      body: body,
      headers: headers,
      redirect: "follow",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return { error };
  }
};

export const get = async (
  path: string,
  headers: Headers,
  isVeraDomain: boolean
) => {
  const url = (isVeraDomain ? veraDomain : recommenderDomain) + path;
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  console.log({ url });
  try {
    const response = await fetch(url, requestOptions);

    const data = await response.json();
    return data;
  } catch (error) {
    return { error };
  }
};

export const patch = async (
  path: string,
  headers: HeadersInit,
  isVeraDomain: boolean,
  body: BodyInit = ""
) => {
  const url = (isVeraDomain ? veraDomain : recommenderDomain) + path;
  console.log("patchpatch");
  console.log({ url, body, headers });
  try {
    const response = await fetch(url, {
      method: "PATCH",
      body: body,
      headers: headers,
      redirect: "follow",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return { error };
  }
};
