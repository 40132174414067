import { accessTokenAtom, tasteProfileIdAtom, userAtom } from "../atoms/auth";
import DropDown from "../components/DropDown";
import VeraModal from "../components/Modal";
import { ReactComponent as CardPlus } from "../svgs/CardPlus.svg";
import { ReactComponent as CardTick } from "../svgs/CardTick.svg";
import { ReactComponent as Clear } from "../svgs/Clear.svg";
import { ReactComponent as Dislike } from "../svgs/Dislike.svg";
import { ReactComponent as DropDownClose } from "../svgs/DropDownClose.svg";
import { ReactComponent as DropDownOpen } from "../svgs/DropDownOpen.svg";
import { ReactComponent as Refresh } from "../svgs/Refresh.svg";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import React from "react";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useCallback,
} from "react";
import { deviceTypeAtom } from "../atoms/screenSize";
import { get, post, put, _delete } from "../utils/api";
import LongPressButton from "./LongPressButton";

const CARD_WIDTH = 198;
const CARD_MARGIN = 8;
const SUGGESTED_ARTISTS_WIDTH = 775;

const Pill = ({ name, id }: { name: string; id: string }) => {
  const deleteFn = async () => {
    return await _delete(
      "/tasteprofile/" +
        "2" + //listener.tasteprofileId
        "/" +
        "artist" +
        "/" +
        "like" +
        "?" +
        "artist" +
        "_id=" +
        id,
      new Headers(),
      undefined,
      false
    );
  };
  const queryClient = useQueryClient();
  const deleteMutation = useMutation({
    mutationFn: deleteFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getArtists"] });
      queryClient.invalidateQueries({ queryKey: ["getStations"] });
    },
  });
  return (
    <button
      className="mx-[6px] my-[8px] flex flex-row items-center rounded-[100px] bg-purple-50 p-[12px]"
      onClick={() => deleteMutation.mutate()}
    >
      <p className="font-pn text-p-l mr-[10px] text-purple-500">{name}</p>
      <Clear />
    </button>
  );
};
const Card = ({
  name,
  id,
  isLoading,
  onClick,
  onLongPress,
}: {
  name: string;
  id: string;
  isLoading: boolean;
  onClick: () => void;
  onLongPress: () => void;
}) => {
  const [pressed, setPressed] = useState(false);
  const [longPressed, setLongPressed] = useState(false);
  const [user] = useAtom(userAtom);
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";

  return (
    <LongPressButton
      onClick={onClick}
      onLongPress={onLongPress}
      className={`flex h-[48px] flex-row items-center justify-center rounded border ${
        pressed ? "border-purple-500" : "border-neutral-400"
      }  bg-white`}
      style={{
        width: isPhone ? "95%" : CARD_WIDTH,
        margin: CARD_MARGIN,
      }}
    >
      <p
        className={`${
          pressed ? "text-purple-500" : "text-vera-black"
        } font-pn text-p-l m-[10px]`}
      >
        {name}
      </p>
      <div className="flex-1" />
      {/* {!pressed && <CardPlus />}
      {pressed && <CardTick />}
      {longPressed && <Dislike />} */}
      <CardPlus />
      <div className="w-[10px]" />
    </LongPressButton>
  );
};

const SuggestedArtists = ({
  liked,
  removed,
}: {
  liked?: { id: string; name: string }[];
  removed?: { id: string; name: string }[];
}) => {
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";
  const [accessToken] = useAtom(accessTokenAtom);
  const queryClient = useQueryClient();
  const [selectedCards, setSelectedCards] = useState(new Set());
  const [showMore, setShowMore] = useState(false);

  const getArtist = async () => {
    const headers = new Headers();
    headers.append("Authorization", "Bearer " + accessToken);
    headers.append("Content-Type", "application/json");
    const response = await get(
      `/artist/suggest?tasteprofile_id=${2}&num_artists=30`,
      headers,
      false
    );
    return response;
  };

  const putArtists = async () => {
    const headers = new Headers();
    headers.append("Authorization", "Bearer " + accessToken);
    headers.append("Content-Type", "application/json");
    const response = await put(
      `/artist/suggest?tasteprofile_id=${2}&num_artists=30`,
      headers,
      {} as BodyInit,
      false
    );
    return response;
  };

  const putMutation = useMutation({
    mutationFn: () => putArtists(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getSuggestedArtists"] });
    },
  });

  const postFn = async (likeORRemove: "like" | "remove", id: string) => {
    return await post(
      "/tasteprofile/" +
        "2" +
        "/" +
        "artist" +
        "/" +
        likeORRemove +
        "?" +
        "artist" +
        "_id=" +
        id,
      new Headers(),
      {} as BodyInit,
      false
    );
  };

  const postMutation = useMutation({
    mutationFn: (e: { likeOrRemove: "like" | "remove"; id: string }) =>
      postFn(e.likeOrRemove, e.id),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["getArtists"] }),
  });

  const { data, isLoading, isError } = useQuery({
    queryFn: getArtist,
    queryKey: ["getSuggestedArtists"],
  });

  // const likedArtists = useQuery({
  //   queryFn: likedArtists
  // })

  if (isLoading) return <></>;
  if (isError) return <></>;
  console.log({ data });
  console.log({ liked });
  return (
    <div
      style={{
        width: isPhone ? "100%" : SUGGESTED_ARTISTS_WIDTH,
      }}
    >
      <div className="mt-[32px] w-full rounded bg-purple-50 p-[24px]">
        <div className="flex flex-row items-center justify-between w-full">
          <p
            className={`text-vera-black font-pn ${
              isPhone ? "text-p-m" : "text-p-l"
            }`}
          >
            Suggested Artists
          </p>
          <button
            className="flex flex-row items-center mr-[20px]"
            onClick={() => {
              putMutation.mutate();
            }}
          >
            <Refresh />
            <p className="font-pn text-p-l ml-[5px] text-purple-500">Refresh</p>
          </button>
        </div>
        <div className="my-[8px] flex flex-row flex-wrap">
          {data.map((item: any, index: number) => {
            return (
              <div key={index}>
                {selectedCards.has(item.id) ||
                (!showMore && index > 9) ? null : (
                  <Card
                    name={
                      item.name.length > 12
                        ? item.name.substring(0, 12) + "..."
                        : item.name
                    }
                    id={item.id}
                    isLoading={false}
                    onClick={() => {
                      postMutation.mutate({
                        likeOrRemove: "like",
                        id: item.id,
                      });
                      // @ts-ignore
                      setSelectedCards((c) => new Set([...c, item.id]));
                    }}
                    onLongPress={() => {
                      postMutation.mutate({
                        likeOrRemove: "remove",
                        id: item.id,
                      });
                      // @ts-ignore
                      setSelectedCards((c) => new Set([...c, item.id]));
                      alert("Removed");
                    }}
                  />
                )}
              </div>
            );
          })}
          <button
            className="h-[36px] w-full flex flex-row items-center justify-center rounded bg-purple-100 "
            style={{
              width: isPhone ? "95%" : 198 * 3 + CARD_MARGIN * 4,
              margin: CARD_MARGIN,
            }}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? (
              <>
                <p className="text-vera-black font-pn text-p-l mr-[8px]">
                  Show Less
                </p>
                <DropDownOpen />
              </>
            ) : (
              <>
                <p className="text-vera-black font-pn text-p-l mr-[8px]">
                  Show More
                </p>
                <DropDownClose />
              </>
            )}
          </button>
        </div>
      </div>
      <div className="mt-[34px] w-full flex flex-row flex-wrap">
        {liked?.map((l) => (
          <Pill name={l.name} id={l.id} key={l.name} />
        ))}
      </div>
    </div>
  );
};

export default function Suggestion() {
  const [showModal, setShowModal] = useState(false);
  const [tasteProfileId] = useAtom(tasteProfileIdAtom);
  const [user] = useAtom(userAtom);

  const getArtists = async () => {
    if (user) {
      const headers = new Headers();
      const response = await get(
        "/tasteprofile/" + "2" + "/artists",
        headers,
        false
      );
      return response;
    }
    return [];
  };

  const artistsQuery = useQuery({
    queryKey: ["getArtists"],
    queryFn: getArtists,
  });

  return (
    <>
      <SuggestedArtists
        liked={artistsQuery.data.liked}
        removed={artistsQuery.data.removed}
      />
    </>
  );
}
