import InputForm from "../../components/inputForm";
import VeraModal from "../../components/Modal";
import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import LeftPane from "./components/LeftPane";
import { useAtom } from "jotai";
import { deviceTypeAtom, dimensionAtom } from "../../atoms/screenSize";
import Header from "./components/Header";
import LatestActivity from "./components/LatestActivity";
import UserManager from "./components/UserManager";
import {
  accessTokenAtom,
  emailAtom,
  userAtom,
  userTypeAtom,
} from "../../atoms/auth";
import MusicStations from "./components/MusicStations";
import { ReactComponent as WatchVideo } from "../../svgs/WatchVideo.svg";
import { ReactComponent as Home } from "../../svgs/Home.svg";
import { ReactComponent as MusicStation } from "../../svgs/MusicStation.svg";
import { ReactComponent as Settings } from "../../svgs/Settings.svg";
import { ReactComponent as PhoneHelp } from "../../svgs/PhoneHelp.svg";
import { ReactComponent as Right } from "../../svgs/Right.svg";
import VideoModal from "../../components/VideoModal";
import VideoPill from "../../components/VideoPill";
import Player from "./components/Player";
// import { useIntercom } from "react-use-intercom";
import useIsVeraPro from "../../hooks/useIsVeraPro";
import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  useNavigate,
} from "react-router-dom";
import MusicPreference from "./components/MusicPreference";
import { get } from "../../utils/api";
import { useQuery } from "@tanstack/react-query";
import { activeListenerAtom } from "../../atoms/listener";

const getListeners = () => {
  return [
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
    {
      id: "1234",
      name: "John Hund",
      taste_profile_id: "1234",
      last_active: "More than a month",
    },
  ];
};

type ListenerActivity = {
  name: string;
  lastActive: string; // Should be date string
  lastPlayed: string;
  favouriteStation: string;
};

const getLatestActivity = (): ListenerActivity[] => {
  return [
    {
      name: "Stephen J.",
      lastActive: "Today",
      lastPlayed: "Reminisce",
      favouriteStation: "Energize",
    },
    {
      name: "Stephen J.",
      lastActive: "6 days ago",
      lastPlayed: "Reminisce",
      favouriteStation: "Energize",
    },
    {
      name: "Stephen J.",
      lastActive: "A week ago",
      lastPlayed: "Reminisce",
      favouriteStation: "Energize",
    },
    {
      name: "Stephen J.",
      lastActive: "More than a month ago",
      lastPlayed: "Reminisce",
      favouriteStation: "Energize",
    },
    {
      name: "Stephen J.",
      lastActive: "4 days ago",
      lastPlayed: "Reminisce",
      favouriteStation: "Energize",
    },
    {
      name: "Stephen J.",
      lastActive: "3 days ago",
      lastPlayed: "Reminisce",
      favouriteStation: "Energize",
    },
    {
      name: "Stephen J.",
      lastActive: "5 days ago",
      lastPlayed: "Reminisce",
      favouriteStation: "Energize",
    },
    {
      name: "Stephen J.",
      lastActive: "More than a week ago",
      lastPlayed: "Reminisce",
      favouriteStation: "Energize",
    },
  ];
};

function addEmptyListener(list: any[]): any[] {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [dimension] = useAtom(dimensionAtom);
  const width = dimension.width;
  const MODALWIDTH = (width / 8) * 6;
  const PILLSWIDTH = MODALWIDTH - 70 * 2;
  const PILLWIDTH = 280;
  const NUMBEROFCOLUMN = PILLSWIDTH / PILLWIDTH;
  const length = list.length - (list.length % NUMBEROFCOLUMN);
  const result = list;
  for (let i = 0; i < length; i++) {
    result.push({
      id: "",
      name: "",
      taste_profile_id: "",
      last_active: "",
      empty: true,
    });
  }
  return result;
}

const ListenerPill = ({
  name,
  id,
  last_active,
  selected,
  setSelected,
  setVisible,
}: {
  name: string;
  id: string;
  last_active: string;
  selected: any[];
  setSelected: Function;
  setVisible: (b: boolean) => void;
}) => {
  const [, setUserType] = useAtom(userTypeAtom);
  const [, setActiveListener] = useAtom(activeListenerAtom);
  const onPress = () => {
    setVisible(false);
    setUserType("Listener");
    setActiveListener({ name: name });
  };

  return (
    <>
      <button
        onClick={onPress}
        className="mb-[24px] mr-[35px] flex h-[74px] w-[245px] flex-row items-center justify-between"
      >
        <div className="flex h-[74px] w-[74px] flex-row items-center justify-center rounded-[8px] bg-purple-100">
          <p className="font-pnb text-h3-d">{name.substring(0, 1)}</p>
        </div>
        <div className="mr-[10px]" />
        <div className="flex h-full flex-col items-start justify-between">
          <p className="font-pnb text-p-l">{name}</p>
          <span className="font-pn text-p-xs">
            <span className="font-pn text-p-xs">Resident ID:</span>
            {id}
          </span>
          <p className="font-pn text-p-xs text-neutral-600">
            Last active: {last_active}
          </p>
        </div>
      </button>
    </>
  );
};

const SelectListenerModal = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedListeners, setSelectedListeners] = useState([]);

  const [user] = useAtom(userAtom);
  const [accessToken] = useAtom(accessTokenAtom);
  const isVeraPro = useIsVeraPro();

  const getLatestActivity = async () => {
    if (user && isVeraPro) {
      const headers = new Headers();
      headers.append("Authorization", "Bearer " + accessToken);
      const response = await get(
        "/pro/home/listeners?home_id=" + user.home_id,
        headers,
        true
      );
      return response as ListenerActivity[];
    }
    return [];
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ["getActivities"],
    queryFn: getLatestActivity,
  });
  if (data === undefined) return null;
  return (
    <>
      <VeraModal
        visible={visible}
        setVisible={setVisible}
        selectListenerMode={true}
      >
        <div className="mx-[70px]">
          <p className="font-pnb text-h4-d text-vera-black">
            Select the Listener
          </p>
          <div className="mb-[8px]" />
          <p className="font-pn text-p-m">
            Choose a listener you want to play music for
          </p>
          <div className="mb-[24px]" />
          <div className="w-[504px]">
            <InputForm
              type={"searchListener"}
              value={searchText}
              setValue={setSearchText}
            />
          </div>
          <div className="mb-[24px]" />
          <div className="flex w-full flex-row flex-wrap justify-evenly">
            {data
              .filter((e) =>
                e.name.toLowerCase().includes(searchText.toLowerCase())
              )
              .map((listener, index) => {
                return (
                  <div key={index}>
                    {
                      // @ts-ignore
                      listener.empty ? (
                        <div className="h-[74px] w-[280px]" />
                      ) : (
                        <ListenerPill
                          name={listener.name}
                          // @ts-ignore
                          id={listener.id}
                          last_active={listener.lastActive}
                          selected={selectedListeners}
                          setSelected={setSelectedListeners}
                          setVisible={setVisible}
                        />
                      )
                    }
                  </div>
                );
              })}
          </div>
        </div>
      </VeraModal>
    </>
  );
};

const LinearGradientWrapper = ({
  children,
  selected,
}: {
  children: ReactElement | ReactNode;
  selected: boolean;
}) => {
  return (
    <>
      {selected ? (
        <div className="flex flex-1 flex-row items-center justify-center">
          <div className=" bg-purple-100 flex flex-col h-[60px] w-[60px] rounded-[8px]">
            {children}
          </div>
        </div>
      ) : (
        <div className="flex flex-1">{children}</div>
      )}
    </>
  );
};

type PhoneFooterType = "Home" | "Music" | "Settings" | "Help";

const PhoneFooter = ({
  setIsChatOpen,
}: {
  setIsChatOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [selected, setSelected] = useState<PhoneFooterType>("Home");
  const navigate = useNavigate();
  return (
    <>
      <div className="h-[90px] bg-white flex flex-row items-center">
        <LinearGradientWrapper selected={selected === "Home"}>
          <button
            className="flex flex-1 flex-col items-center justify-center"
            onClick={() => {
              setSelected("Home");
              navigate("/Dashboard");
            }}
          >
            <div className="h-[25px]">
              <Home color={selected === "Home" ? "#7A23D3" : "#000"} />
            </div>
            <p
              className="font-pnb text-p-s"
              style={{
                color: selected === "Home" ? "#7A23D3" : "#000",
              }}
            >
              Home
            </p>
          </button>
        </LinearGradientWrapper>
        <LinearGradientWrapper selected={selected === "Music"}>
          <button
            className="flex flex-1 flex-col items-center justify-center"
            onClick={() => {
              setSelected("Music");
            }}
          >
            <div className="h-[25px]">
              <MusicStation />
            </div>
            <p
              className="font-pnb text-p-s"
              style={{
                color: selected === "Music" ? "#7A23D3" : "#000",
              }}
            >
              Music
            </p>
          </button>
        </LinearGradientWrapper>
        <LinearGradientWrapper selected={selected === "Settings"}>
          <button
            className="flex flex-1 flex-col items-center justify-center"
            onClick={() => {
              setSelected("Settings");
              navigate("Settings");
            }}
          >
            <div className="h-[25px]">
              <Settings />
            </div>
            <p
              className="font-pnb text-p-s"
              style={{
                color: selected === "Settings" ? "#7A23D3" : "#000",
              }}
            >
              Settings
            </p>
          </button>
        </LinearGradientWrapper>
        <LinearGradientWrapper selected={selected === "Help"}>
          <button
            className="flex flex-1 flex-col items-center justify-center"
            onClick={() => {
              setSelected("Help");
              setIsChatOpen((prevValue) => !prevValue);
            }}
          >
            <div className="h-[25px]">
              <PhoneHelp />
            </div>
            <p
              className="font-pnb text-p-s"
              style={{
                color: selected === "Help" ? "#7A23D3" : "#000",
              }}
            >
              Help
            </p>
          </button>
        </LinearGradientWrapper>
      </div>
    </>
  );
};

const PhoneHome = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const [dimension] = useAtom(dimensionAtom);
  const [selectedVideo, setSelectVideo] = useState(0);
  return (
    <>
      <div
        className="h-[200px]"
        style={{
          width: dimension.width - 40,
        }}
      >
        <Header name={"Jake"} />
      </div>
      <div className="mb-[16px]" />
      <div
        className="px-[16px]"
        style={{
          width: dimension.width - 40,
        }}
      >
        <p className="font-pnb text-h6-m">How to use Vera</p>
        <div className="mb-[16px]" />
        {!showModal && (
          <div className="h-[262px] flex flex-row items-center overflow-auto whitespace-nowrap">
            <VideoPill
              onPressFn={() => {
                setShowModal(true);
              }}
            />
            <VideoPill
              onPressFn={() => {
                setShowModal(true);
              }}
            />
            <VideoPill
              onPressFn={() => {
                setShowModal(true);
              }}
            />
          </div>
        )}
        <div className="mb-[16px]" />
        <div className="flex flex-row items-center justify-center w-full">
          <div
            className="w-[10px] h-[10px] rounded-[5px] bg-neutral-600"
            style={{
              backgroundColor: selectedVideo === 0 ? "#7A23D3" : "#C4C3C6",
            }}
          />
          <div className="mr-[10px]" />
          <div
            className="w-[10px] h-[10px] rounded-[5px] "
            style={{
              backgroundColor: selectedVideo === 1 ? "#7A23D3" : "#C4C3C6",
            }}
          />
          <div className="mr-[10px]" />
          <div
            className="w-[10px] h-[10px] rounded-[5px]"
            style={{
              backgroundColor: selectedVideo === 2 ? "#7A23D3" : "#C4C3C6",
            }}
          />
        </div>
      </div>
    </>
  );
};

const PhoneSettings = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-[16px] w-full">
      <p className="font-pnb text-h3-m">Settings</p>
      <p className="font-pn text-p-s">
        Manage listener preferences and your account settings
      </p>
      <div className="mb-[30px]" />
      <div
        style={{
          boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
        }}
        className="px-[32px] py-[16px] bg-white rounded-[8px]"
      >
        <p className="font-pnsb text-h4-m">Listener Profile</p>
        <p className="font-pn text-p-s">Manage profile and music preferences</p>
        <div className="mb-[5px]" />
        <div className="h-[1px] w-full border-[1px] border-neutral-400" />
        <div className="flex flex-row justify-between items-center py-[10px]">
          <p className="font-pn text-h6-m">Personal Information</p>
          <Right />
        </div>
        <button
          className="flex flex-row justify-between items-center py-[10px] w-full"
          onClick={() => {
            navigate("/Dashboard/MusicPreference");
          }}
        >
          <p className="font-pn text-h6-m">Music Preferences</p>
          <Right />
        </button>
      </div>
      <div className="mb-[20px]" />
      <div
        style={{
          boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
        }}
        className="px-[32px] py-[16px] bg-white rounded-[8px]"
      >
        <p className="font-pnsb text-h4-m">Account</p>
        <p className="font-pn text-p-s">
          Manage your password and guest admins
        </p>
        <div className="mb-[5px]" />
        <div className="h-[1px] w-full border-[1px] border-neutral-400" />
        <div className="flex flex-row justify-between items-center py-[10px]">
          <p className="font-pn text-h6-m">Membership</p>
          <Right />
        </div>
        <div className="flex flex-row justify-between items-center py-[10px]">
          <p className="font-pn text-h6-m">Password</p>
          <Right />
        </div>
        <div className="flex flex-row justify-between items-center py-[10px]">
          <p className="font-pn text-h6-m">Guest Admin</p>
          <Right />
        </div>
      </div>
      <div className="mb-[30px]" />
      <div className="self-center w-[140px] h-[45px] rounded-[8px] bg-purple-50 border-[1px] border-purple-500 flex flex-row items-center justify-center">
        <p className="font-pn text-p-l text-purple-500">Logout</p>
      </div>
    </div>
  );
};

const PhoneDashBoard = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [dimension] = useAtom(dimensionAtom);

  return (
    <div className="flex flex-1 flex-col bg-white">
      <VideoModal showModal={showModal} setShowModal={setShowModal} />
      <PhoneHome showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

const DashboardHome = () => {
  const [userType] = useAtom(userTypeAtom);
  const [device] = useAtom(deviceTypeAtom);

  if (device === "mobile") {
    // return <Player />;
    return <PhoneDashBoard />;
  }

  if (userType === "Listener") {
    return <MusicStations />;
  } else {
    return <LatestActivity />;
  }
};

export default function DashBoard() {
  const [showModal, setShowModal] = React.useState(false);
  const [dimension] = useAtom(dimensionAtom);
  const [device] = useAtom(deviceTypeAtom);
  const [isChatOpen, setIsChatOpen] = useState(false);
  // const intercomContext = useIntercom();
  const [userEmail] = useAtom(emailAtom);

  // useEffect(() => {
  //   if (isChatOpen) {
  //     intercomContext.boot({ email: userEmail });
  //     intercomContext.show();
  //   } else {
  //     intercomContext.hide();
  //   }
  // }, [intercomContext, isChatOpen, userEmail]);

  return (
    <>
      <SelectListenerModal visible={showModal} setVisible={setShowModal} />
      <div
        className="m-[16px] flex flex-1 flex-row"
        style={{
          height: dimension.height - 32,
        }}
      >
        {device !== "mobile" && (
          <div
            className="flex rounded-[8px] flex-[5_5_0%] bg-white pt-[32px] pl-[16px] mr-[16px]"
            style={{
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
            }}
          >
            <LeftPane setShowListenerModal={setShowModal} />
          </div>
        )}
        <div
          className="flex flex-col flex-[24_24_0%] rounded-[8px]"
          style={{
            width: (dimension.width / 29) * 24 - 48,
          }}
        >
          <div
            className="rounded-[8px] flex flex-[16_16_0%]"
            style={{
              width: device === "mobile" ? dimension.width - 50 : "auto",
            }}
          >
            <Routes>
              <Route path="/" element={<DashboardHome />} />
              <Route
                path="Relax"
                element={<Player/>}
              />
              <Route
                path="Reminisce"
                element={<Player/>}
              />
              <Route
                path="Energize"
                element={<Player/>}
              />
              <Route
                path="Listeners"
                element={<div className="w-[50px] h-[50px] bg-blue-500"></div>}
              />
              <Route
                path="Caregivers"
                element={<div className="w-[50px] h-[50px] bg-red-600"></div>}
              />
              <Route
                path="LearnHub"
                element={<div className="w-[50px] h-[50px] bg-blue-500"></div>}
              />
              <Route path="Settings" element={<PhoneSettings />} />
              <Route
                path="PersonalInformation"
                element={<div className="w-[50px] h-[50px] bg-red-600"></div>}
              />
              <Route path="MusicPreference" element={<MusicPreference />} />
            </Routes>
          </div>
          {device === "mobile" && (
            <>
              <div className="mt-auto" />
              <PhoneFooter setIsChatOpen={setIsChatOpen} />
            </>
          )}
        </div>
      </div>
    </>
  );
}
