import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { deviceTypeAtom } from "../../../atoms/screenSize";
import Button from "../../../components/Button";

export default function Footer({
  canProgress,
  onNext,
}: {
  canProgress: boolean;
  onNext: () => void;
}) {
  const [device] = useAtom(deviceTypeAtom);
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`flex w-full flex-row bg-white ${
          device === "mobile" && "justify-center"
        }`}
        style={{
          boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
          height: device === "mobile" ? 108 : 128,
        }}
      >
        <div
          className={`${device === "mobile" && "flex-1"} ${
            device === "tablet" && "flex-[3_3_0%]"
          } ${device === "web" && "flex-[6_6_0%]"}`}
        />
        <div
          style={{
            flex: device === "tablet" ? 13 : device === "web" ? 12 : 2,
          }}
          className={`flex flex-row items-center`}
        >
          <Button
            type={"outline"}
            text={"Back"}
            onPressFn={() => {
              navigate(-1);
            }}
          />
          <div className="mr-[12px]" />
          <Button
            type={"filled"}
            text={"Next"}
            onPressFn={onNext}
            isReady={canProgress}
          />
        </div>
        <div
          style={{
            flex: device === "tablet" ? 5 : device === "web" ? 12 : 1,
          }}
        />
      </div>
    </>
  );
}
