import {
  accessTokenAtom,
  emailAtom,
  idTokenAtom,
  isVeraProAtom,
  passwordAtom,
  userAtom,
  userTypeAtom,
} from "../../atoms/auth";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { RESET } from "jotai/utils";
import { useEffect, useState } from "react";
import { authenticate, get } from "../../utils/auth";
import useTokenId from "../../hooks/useTokenId";
import Mood from "../../components/Mood";
import Player from "../../components/Player";
import { useQuery } from "@tanstack/react-query";
import useIsVeraPro from "../../hooks/useIsVeraPro";
export default function AfterLogin() {
  const [email, setEmail] = useAtom(emailAtom);
  const [, setPassword] = useAtom(passwordAtom);
  const [resetting, setIsResetting] = useState(false);
  const [accessToken] = useAtom(accessTokenAtom);
  const [showMood, setShowMood] = useState(false);
  const [idToken, setIdToken] = useAtom(idTokenAtom);
  const [, setUserType] = useAtom(userTypeAtom);
  const [user, setUser] = useAtom(userAtom);
  const isVeraPro = useIsVeraPro();
  const idTokenObject = useTokenId();
  const navigate = useNavigate();

  const getUser = async () => {
    if (idTokenObject?.email) {
      const headers = new Headers();
      headers.append("Authorization", "Bearer " + accessToken);
      const data = await get(
        (isVeraPro ? "/pro" : "") +
          "/user/by_email/" +
          encodeURIComponent(idTokenObject?.email),
        headers,
        true
      );
      setUser(data);
      return data;
    }
  };

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["getUser"],
    queryFn: getUser,
  });

  useEffect(() => {
    refetch();
  }, [accessToken, refetch]);

  if (resetting) return null;

  if (isLoading) return null;
  if (isError) return <p>Something went wrong. Please restart app</p>;
  console.log({ data });

  console.log({ idToken });
  if (resetting) return null;
  return (
    <>
      {showMood ? <Mood setVisible={setShowMood} /> : null}
      <div className="flex flex-1 flex-col items-center justify-center">
        <p>Logged in as {idTokenObject?.email ?? email}</p>
        <button
          className="rounded bg-red-200 p-[8px]"
          onClick={async () => {
            setUserType("Listener");
            await navigate("/Genres");
          }}
        >
          <p>Onboarding</p>
        </button>
        <button
          className="rounded bg-red-200 p-[8px]"
          onClick={async () => {
            setUserType("HomeAdmin");
            await navigate("/LetsGetStart");
          }}
        >
          <p>Home Admin</p>
        </button>
        <button
          className="rounded bg-red-200 p-[8px]"
          onClick={async () => {
            setUserType("Caregiver");
            await navigate("/LetsGetStart");
          }}
        >
          <p>Caregiver</p>
        </button>
        <button
          className="rounded bg-red-200 p-[8px]"
          onClick={async () => {
            setShowMood(true);
          }}
        >
          <p>Mood</p>
        </button>
        <button
          className="rounded bg-red-200 p-[8px]"
          onClick={async () => {
            await navigate("/Dashboard");
          }}
        >
          <p>Dashboard</p>
        </button>
        <button
          className="rounded bg-red-200 p-[8px]"
          onClick={async () => {
            await Promise.all([
              setEmail(RESET),
              setPassword(""),
              setIdToken(RESET),
            ]);
            await setIsResetting(true);
            await navigate("/");
          }}
        >
          <p>Back</p>
        </button>
        <Player />
      </div>
    </>
  );
}
