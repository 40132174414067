import { ICognitoUserSessionData } from "amazon-cognito-identity-js";
import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

const storage = <T>() => {
  return {
    ...createJSONStorage<T>(() => localStorage),
    delayInit: true,
  };
};

export const emailAtom = atomWithStorage<string>(
  "email",
  "",
  storage<string>()
);
//export const passwordAtom = atomWithStorage<string>('password', '', storage<string>());
export const passwordAtom = atom("");
export const isVeraProAtom = atomWithStorage<boolean>(
  "isVeraPro",
  true,
  storage<boolean>()
);
export const stayLoggedInAtom = atomWithStorage<boolean>(
  "stayLoggedIn",
  true,
  storage<boolean>()
);

export const idTokenAtom = atomWithStorage<string>(
  "idToken",
  "",
  storage<string>()
);
export const accessTokenAtom = atomWithStorage<string>(
  "accessToken",
  "",
  storage<string>()
);
export const tasteProfileIdAtom = atomWithStorage<number>(
  "tasteProfileId",
  0,
  storage<number>()
);
export const userTypeAtom = atomWithStorage<
  "Listener" | "HomeAdmin" | "Caregiver"
>("userType", "Listener", storage<"Listener" | "HomeAdmin" | "Caregiver">());

// export const authAtom = atomWithStorage<ICognitoUserSessionData | undefined>(
//   'auth',
//   undefined,
//   storage<ICognitoUserSessionData | undefined>()
// );
// export const federatedAuthAtom = atomWithStorage<boolean>('federatedAuth', false, storage<boolean>());

export const userAtom = atomWithStorage<ProUser | undefined>(
  "user",
  undefined,
  storage<ProUser | undefined>()
);
