import { ReactComponent as Down } from "../../../svgs/Down.svg";
import { ReactComponent as Left } from "../../../svgs/Left.svg";
import { ReactComponent as Right } from "../../../svgs/Right.svg";
import { ReactComponent as Up } from "../../../svgs/Up.svg";
import React, { Dispatch, useEffect, useState } from "react";
import { useAtom } from "jotai";
import { deviceTypeAtom, dimensionAtom } from "../../../atoms/screenSize";

type BlockType = {
  year?: string;
}[];

const MARGIN = 32;
const containerMaxWidth = 444;

const Content = ({
  yearArray,
  setValue,
  setSelectContent,
  selectContent,
}: {
  yearArray: BlockType | undefined;
  setValue: Dispatch<React.SetStateAction<string>>;
  setSelectContent: Dispatch<React.SetStateAction<string>>;
  selectContent: string;
}) => {
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";
  const [dimension] = useAtom(dimensionAtom);
  const containerWidth =
    dimension.width - MARGIN > containerMaxWidth ? 350 : dimension.width - 100;
  return (
    <div className="flex flex-1 flex-row flex-wrap items-center justify-center">
      {yearArray?.map((block, index) => {
        return (
          <button
            disabled={!block.year}
            key={index}
            onClick={() => {
              if (block.year) {
                setValue(block.year);
                setSelectContent(block.year);
              }
            }}
          >
            <div
              style={{
                height: isPhone ? 80 : 30,
                width: isPhone ? containerWidth / 3 : containerWidth / 4,
              }}
              className="flex flex-row items-center justify-center rounded-[8px]"
            >
              <div
                style={{
                  height: isPhone ? 45 : 30,
                  width: isPhone ? containerWidth / 4 : containerWidth / 4,
                  backgroundColor:
                    selectContent === block.year ? "#EBDDF9" : "#FFF",
                }}
                className="flex flex-row items-center justify-center rounded-[8px]"
              >
                <p
                  className="font-pn text-p-m text-justify-center"
                  style={{
                    color: selectContent === block.year ? "#7A23D3" : "#000",
                  }}
                >
                  {block.year}
                </p>
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
};

type YearPickerType = {
  setValue: Dispatch<React.SetStateAction<string>>;
  setSelectContent: Dispatch<React.SetStateAction<string>>;
  selectContent: string;
};

export default function YearPicker({
  setValue,
  setSelectContent,
  selectContent,
}: YearPickerType) {
  const [currentBlock, setCurrentBlock] = useState(0);
  const [block, setBlock] = useState<BlockType>();
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";
  const numbersOfItem = isPhone ? 9 : 12;
  const currentYear = new Date().getFullYear();
  const yearArray: BlockType[] = [];
  const startYear = 1900;
  const max = Math.ceil((currentYear - startYear) / numbersOfItem) - 1;

  useEffect(() => {
    setBlock(data[currentBlock]);
  }, [currentBlock]);

  for (let i = startYear; i <= currentYear; i += numbersOfItem) {
    const list = [];
    for (let j = 0; j < numbersOfItem; j++) {
      if (i + j <= currentYear) {
        list.push({ year: `${i + j}` });
      } else {
        list.push({});
      }
    }
    yearArray.push(list);
  }
  const data = yearArray.reverse();

  return (
    <>
      <div
        style={{
          maxWidth: containerMaxWidth,
          height: isPhone ? 300 : 128,
          // shadowColor: 'rgba(0, 0, 0, 0.1)',
          // shadowOpacity: 1,
          // shadowOffset: { width: 0, height: 2 },
          // shadowRadius: 20,
          flexDirection: isPhone ? "column" : "row",
          boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
        }}
        className={
          "flex w-full flex-1 flex-wrap items-center justify-between rounded-[8px] bg-white p-[16px]"
        }
      >
        <button
          onClick={() => {
            if (currentBlock < max) {
              setCurrentBlock(currentBlock + 1);
            }
          }}
        >
          {isPhone ? <Up /> : <Left />}
        </button>
        <div className="flex-1 flex-col items-center">
          <Content
            yearArray={block}
            setValue={setValue}
            setSelectContent={setSelectContent}
            selectContent={selectContent}
          />
        </div>
        <button
          onClick={() => {
            if (currentBlock > 0) {
              setCurrentBlock(currentBlock - 1);
            }
          }}
        >
          {isPhone ? <Down /> : <Right />}
        </button>
      </div>
    </>
  );
}
