import { ReactComponent as Google } from "../../svgs/google.svg";
import { ReactComponent as Apple } from "../../svgs/apple.svg";
import { ReactComponent as Vera } from "../../svgs/vera.svg";
import { useState } from "react";
import { motion } from "framer-motion";
import ChangePassword from "./components/ChangePassword";
import { socialMediaLogin } from "../../utils/auth";
import { useAtom } from "jotai";
import { deviceTypeAtom, dimensionAtom } from "../../atoms/screenSize";
import { useNavigate } from "react-router-dom";
import LinearBackground from "./components/LinearBackground";
import LeftPane from "./components/VeraToggle";
import LoginAndRegisterComponent from "./components/LoginAndRegister";
import VerifyEmail from "./components/VerifyEmail";
import { emailAtom } from "../../atoms/auth";

const RightPane = (props: {
  type: LoginState;
  setType: (s: LoginState) => void;
  isVeraPro: boolean;
  navigate: any;
  dimension: { height: number; width: number };
  device: "mobile" | "tablet" | "web";
}) => {
  const [email, setEmail] = useAtom(emailAtom);
  const content = {
    login: (
      <LoginAndRegisterComponent
        type={props.type}
        setType={props.setType}
        device={props.device}
        isVeraPro={props.isVeraPro}
        dimension={props.dimension}
      />
    ),
    register: (
      <LoginAndRegisterComponent
        type={props.type}
        setType={props.setType}
        device={props.device}
        isVeraPro={props.isVeraPro}
        dimension={props.dimension}
      />
    ),
    select: (
      <LoginAndRegisterComponent
        type={props.type}
        setType={props.setType}
        device={props.device}
        isVeraPro={props.isVeraPro}
        dimension={props.dimension}
      />
    ),
    changePassword: (
      <ChangePassword
        setType={props.setType}
        email={email}
        setEmail={setEmail}
        isVeraPro={props.isVeraPro}
        device={props.device}
      />
    ),
    verifyEmail: (
      <VerifyEmail
        setType={props.setType}
        email={email}
        setEmail={setEmail}
        isVeraPro={props.isVeraPro}
        device={props.device}
      />
    ),
  };

  return (
    <>
      {props.device === "mobile" && <LinearBackground type={props.isVeraPro} />}
      {props.device !== "mobile" &&
        props.type !== "login" &&
        props.type !== "select" &&
        props.type !== "register" && (
          <div className="absolute translate-x-[10px] translate-y-[10px]">
            <div className="flex ml-[20px] flex-row items-center">
              <Vera />
              <div className="mr-[5px]" />
              {props.isVeraPro && (
                <div className="bg-purple-550 rounded-[2px] px-[10px]">
                  <p className="font-pn text-xl text-white">Pro</p>
                </div>
              )}
            </div>
          </div>
        )}
      <div
        className=" flex flex-[16_16_0%] flex-row pr-[48px] justify-center "
        style={{
          height: "100vh",
          justifyContent: props.device !== "mobile" ? "center" : "normal",
        }}
      >
        {props.device !== "mobile" ? (
          <div className="flex flex-[3_3_0%] flex-col" />
        ) : (
          <div className="flex flex-1 flex-col" />
        )}

        {content[props.type]}
        {props.device !== "mobile" ? (
          <div className="flex flex-[2_2_0%] flex-col" />
        ) : null}
      </div>
    </>
  );
};

const MobileSelection = (props: {
  setState: React.Dispatch<React.SetStateAction<LoginState>>;
  setIsVeraPro: (b: boolean) => void;
  isVeraPro: boolean;
  dimension: { height: number; width: number };
  device: "mobile" | "tablet" | "web";
}) => {
  return (
    <>
      {props.device === "mobile" && <LinearBackground type={props.isVeraPro} />}
      <div className="flex flex-1 flex-col">
        <LinearBackground type={props.isVeraPro} />
        <div className="mx-[16px]">
          <p className="text-m text-vera-black font-pn ">
            Select your product:
          </p>
          <div
            style={{ width: props.dimension.width - 30 }}
            className={`flex mt-[8px] h-[53px] flex-row items-center justify-between rounded-lg border-[2px] border-neutral-400`}
          >
            <motion.div
              style={{
                width: props.dimension.width / 2 - 32,
                boxShadow: props.isVeraPro
                  ? "-3px 0px 4px rgba(0,0,0,0.25)"
                  : "3px 0px 4px rgba(0,0,0,0.25)",
              }}
              className="absolute h-[45px] w-[168px] justify-center rounded-lg bg-purple-500 pointer-events-none z-0"
              animate={{
                x: props.isVeraPro ? props.dimension.width / 2 - 5 : 5,
              }}
              transition={{
                type: "tween",
                duration: 0.2,
              }}
            />
            <button
              onClick={() => {
                props.setIsVeraPro(!props.isVeraPro);
              }}
              className="flex flex-col h-[45px] w-[160px] justify-center items-center rounded-lg z-10"
              style={{ width: props.dimension.width / 2 - 20 }}
            >
              <p
                style={{ color: !props.isVeraPro ? "#FFF" : "#66646A" }}
                className="text-m font-pnb text-center text-neutral-700"
              >
                VERA
              </p>
            </button>
            <button
              onClick={() => {
                props.setIsVeraPro(!props.isVeraPro);
              }}
              className="flex flex-col items-center h-[45px] justify-center rounded-lg z-10"
              style={{ width: props.dimension.width / 2 - 20 }}
            >
              <p
                style={{ color: props.isVeraPro ? "#FFF" : "#66646A" }}
                className="text-m font-pnb text-center text-white"
              >
                VERA PRO
              </p>
            </button>
          </div>
          <div className="mb-[62px]" />
          <div className="flex flex-row justify-center">
            <Vera />
          </div>
          <div className="mb-[24px]" />
          <p className="font-pn text-m text-vera-black text-center leading-[28px]">
            Your trusted dementia caregiver tool that uses music to assist you
            with the activities of daily living to help support the person in
            your care with mood, memory and communication
          </p>
          <div className="mb-[52px]" />
          <div
            onClick={() => {
              props.setState("register");
            }}
          >
            <p className="font-pn text-m text-vera-black text-center">
              Not a member yet?
              <p className="underline"> Join Now</p>
            </p>
          </div>
          <div className="mb-[16px]" />
          <button
            onClick={() => {
              props.setState("login");
            }}
            className="flex w-full flex-row items-center justify-center rounded-[8px] border-[1px] border-neutral-500 py-[16px]"
          >
            <p className="font-pnb text-xl">Login</p>
          </button>
          <div className="mb-[24px]" />
          <div className="flex flex-row items-center">
            <div className="h-[1px] flex-1 bg-neutral-400"></div>
            <p className="text-m text-vera-black font-pn mx-[14px]">OR</p>
            <div className="h-[1px] flex-1 bg-neutral-400"></div>
          </div>
          <div className="mb-[24px]" />
          <div className="flex flex-row items-center justify-center">
            <button
              className="flex mx-[6px] h-[56px] w-[105px] items-center justify-center rounded-lg border-2 border-neutral-400"
              onClick={() => {
                console.log("google");
                socialMediaLogin(props.isVeraPro, "Google");
              }}
            >
              <Google />
            </button>
            <button
              className="flex mx-[6px] h-[56px] w-[105px] items-center justify-center rounded-lg border-2 border-neutral-400"
              onClick={() => {
                socialMediaLogin(props.isVeraPro, "Apple");
              }}
            >
              <Apple />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default function LoginAndRegister() {
  const [isVeraPro, setIsVeraPro] = useState(true);
  const [loginState, setLoginState] = useState<LoginState>("select");
  const navigate = useNavigate();
  const [dimension] = useAtom(dimensionAtom);
  const [device] = useAtom(deviceTypeAtom);

  return (
    <>
      {device === "mobile" && <div className="mt-[30px]" />}
      <div className="flex flex-1 flex-row">
        {device === "mobile" && loginState === "select" && (
          <MobileSelection
            dimension={dimension}
            setState={setLoginState}
            setIsVeraPro={setIsVeraPro}
            isVeraPro={isVeraPro}
            device={device}
          />
        )}
        {device !== "mobile" &&
          (loginState === "login" ||
            loginState === "register" ||
            loginState === "select") && (
            <LeftPane
              isVeraPro={isVeraPro}
              setIsVeraPro={setIsVeraPro}
              dimension={dimension}
            />
          )}
        {(device !== "mobile" || loginState !== "select") && (
          <RightPane
            dimension={dimension}
            type={loginState}
            setType={setLoginState}
            isVeraPro={isVeraPro}
            navigate={navigate}
            device={device}
          />
        )}
      </div>
    </>
  );
}
