import { ReactComponent as CardPlus } from "../svgs/CardPlus.svg";
import { ReactComponent as CardTick } from "../svgs/CardTick.svg";
import { ReactComponent as Dislike } from "../svgs/Dislike.svg";
import React, { Dispatch, useRef, useState } from "react";
import { useAtom } from "jotai";
import { userAtom } from "../atoms/auth";
import useIsVeraPro from "../hooks/useIsVeraPro";
import { useQueryClient } from "@tanstack/react-query";
import { deviceTypeAtom } from "../atoms/screenSize";
import { post, _delete } from "../utils/api";

type CardListType = {
  content: { name: string }[];
  type?: "cardPlus" | "singleChoice";
  setValue: Dispatch<React.SetStateAction<string>>;
  liked?: { id: string; name: string }[];
  removed?: { id: string; name: string }[];
};

export default function CardList({
  content,
  type,
  setValue,
  liked,
  removed,
}: CardListType) {
  const likeList = liked?.map((item) => {
    return item.name;
  });

  const indexes = new Set(
    likeList?.map((searchItem) => {
      return content.findIndex((item) => item.name === searchItem);
    })
  );

  const removedList = removed?.map((item) => {
    return item.name;
  });

  const removedListIndexes = new Set(
    removedList?.map((searchItem) => {
      return content.findIndex((item) => item.name === searchItem);
    })
  );

  const [selected, setSelected] = useState(new Set<number>(indexes));
  const [dislikeSelected, setDislikeSelected] = useState(removedListIndexes);
  const [user] = useAtom(userAtom);
  const isVeraPro = useIsVeraPro();
  const queryClient = useQueryClient();
  const [device] = useAtom(deviceTypeAtom);
  const isPhone = device === "mobile";

  const putTasteProfile = async (
    id: string,
    likeOrRemove: "like" | "remove",
    addOrDelete: "add" | "delete"
  ) => {
    if (user && isVeraPro) {
      const headers = new Headers();
      const path =
        "/tasteprofile/" +
        user.id +
        "/genre/" +
        likeOrRemove +
        "?genre_id=" +
        id;

      let response;
      if (addOrDelete === "add") {
        response = await post(path, headers, "", false);
      } else {
        response = await _delete(path, headers, "", false);
      }
      queryClient.invalidateQueries({ queryKey: ["getGenres"] });
      return response;
    }
  };

  function LongPressButton({
    onClick,
    onLongPress,
    children,
  }: {
    onClick: () => void;
    onLongPress: () => void;
    children: any;
  }) {
    const [isPressing, setIsPressing] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout>();

    const handlePressStart = () => {
      setIsPressing(true);
      timeoutRef.current = setTimeout(() => {
        onLongPress();
      }, 500);
    };

    const handlePressEnd = () => {
      setIsPressing(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };

    return (
      <button
        onClick={onClick}
        onMouseDown={handlePressStart}
        onTouchStart={handlePressStart}
        onMouseUp={handlePressEnd}
        onMouseLeave={handlePressEnd}
        onTouchEnd={handlePressEnd}
        className="mr-[24px] mb-[10px]"
      >
        {children}
      </button>
    );
  }

  return (
    <div
      className="flex flex-row flex-wrap"
      style={{ maxWidth: 4 * (152 + 24) }} //4 cards are the max, controlled by the width and margin
    >
      {content.map((card, index) => {
        return (
          <LongPressButton
            key={index}
            onClick={async () => {
              if (type === "singleChoice") {
                setSelected(new Set<number>([index]));
                setValue(card.name);
                return;
              }
              if (dislikeSelected?.has(index)) {
                dislikeSelected.delete(index);
                setDislikeSelected(new Set<number>(dislikeSelected));
                putTasteProfile(card.name, "remove", "delete");
              } else if (selected?.has(index)) {
                const res = await putTasteProfile(
                  card.name.toLowerCase(),
                  "like",
                  "delete"
                );
                console.log(res);
                selected.delete(index);
                setSelected(new Set<number>(selected));
              } else {
                await putTasteProfile(card.name.toLowerCase(), "like", "add");
                setSelected(new Set<number>(selected?.add(index)));
              }
            }}
            onLongPress={() => {
              if (dislikeSelected?.has(index)) {
                dislikeSelected.delete(index);
                setDislikeSelected(new Set<number>(dislikeSelected));
                putTasteProfile(card.name.toLowerCase(), "remove", "delete");
              } else {
                setDislikeSelected(
                  new Set<number>(dislikeSelected?.add(index))
                );
                putTasteProfile(card.name.toLowerCase(), "remove", "add");
              }
            }}
          >
            <div
              style={{
                borderColor: selected?.has(index) ? "#7A23D3" : "#C4C3C6",
                justifyContent: type === "cardPlus" ? "flex-start" : "center",
              }}
              className="my-[5px] flex h-[64px] w-[143px] flex-row items-center rounded-[8px] border-[1px] px-[10px]"
            >
              {type === "cardPlus" ? (
                selected?.has(index) ? (
                  <CardTick />
                ) : dislikeSelected?.has(index) ? (
                  <Dislike />
                ) : (
                  <CardPlus />
                )
              ) : null}
              <p
                className="font-pn text-m mx-[10px] text-center"
                style={{
                  color: selected?.has(index) ? "#7A23D3" : "#3E3B39",
                }}
              >
                {card.name}
              </p>
            </div>
          </LongPressButton>
        );
      })}
    </div>
  );
}
